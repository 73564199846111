import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
})
export class InfoPopupComponent implements OnInit {

  settings: MbscPopupOptions = {
    display: 'center',
    onClose: (event, inst) => {
      this.resolve();
    }
  };

  resolve: any;

  // @ts-ignore
  @ViewChild('popup')
  popup: any;

  message: string;

  constructor() { }

  ngOnInit() {}

  show(message: string) {
    this.message = message;
    this.popup.instance.show();
    const that = this;
    return new Promise(function(resolve) {
      that.resolve = resolve;
    });
  }

}
