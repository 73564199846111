import { ShellModel } from '../shell/data-store';
import { ProviderModel } from '../models/provider.model';
import { CustomerModel } from '../models/customer.model';

export class ProfileModel extends ShellModel {
  customer: CustomerModel;

  constructor() {
    super();
  }
}
