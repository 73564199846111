import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-connection-problem',
  templateUrl: './connection-problem.component.html',
  styleUrls: ['./connection-problem.component.scss'],
})
export class ConnectionProblemComponent implements OnInit {
  @Output() retry: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  emitRetry() {
    this.retry.emit();
  }

  openSystemStatusPage() {
    window.open('https://sportsnowgmbh.statuspage.io', '_system', 'location=yes');
  }
}
