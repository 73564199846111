import { ShellModel } from '../../../shell/data-store';
import { ServiceSessionModel } from '../../../models/service-session.model';

export class ServiceSessionInstancesModel extends ShellModel {
  serviceSession: ServiceSessionModel;
  instances: Array<ServiceSessionModel>;
  isCustomer: boolean;

  constructor() {
    super();
  }
}
