import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment, SERVER_URL } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../http/http.service';

@Injectable()
export class TwintService {
  constructor(private http: HttpClient) { }

  public cancelTwintCheckout(pairingUuid: string, ticketId: number, invoiceId: number, amount: number, providerId: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{ token: string, pairingUuid: string }>('./assets/sample-data/empty.json');
    }

    const body = {}
    if (pairingUuid != undefined) {
      body['pairingUuid'] = pairingUuid;
    }
    if (ticketId != undefined) {
      body['ticketId'] = ticketId;
    }
    if (invoiceId != undefined) {
      body['invoiceId'] = invoiceId;
    }
    if (amount != undefined) {
      body['amount'] = amount;
    }
    if (providerId != undefined) {
      body['providerId'] = providerId;
    }

    return this.http.post<{}>(SERVER_URL + '/v1/twint/cancel_checkout', body).pipe(
      catchError(HttpService.handleError)
    );
  }

  public createTwintCheckout(ticketId: number, couponId: number, invoiceId: number, amount: number, providerId: string, serviceSessionId: number, bookingDate: string): Observable<{ token: string, pairingUuid: string, qrCode: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ token: string, pairingUuid: string, qrCode: string }>('./assets/sample-data/twint/checkout.json');
    }

    const body = {}
    if (ticketId != undefined) {
      body['ticketId'] = ticketId;
    }
    if (couponId != undefined) {
      body['couponId'] = couponId;
    }
    if (invoiceId != undefined) {
      body['invoiceId'] = invoiceId;
    }
    if (amount != undefined) {
      body['amount'] = amount;
    }
    if (providerId != undefined) {
      body['providerId'] = providerId;
    }
    if (serviceSessionId != undefined) {
      body['serviceSessionId'] = serviceSessionId;
    }
    if (bookingDate != undefined) {
      body['bookingDate'] = bookingDate;
    }

    return this.http.post<{ token: string, pairingUuid: string, qrCode: string }>(SERVER_URL + '/v1/twint/create_checkout', body).pipe(
      catchError(HttpService.handleError)
    );
  }

  public confirmTwintCheckout(pairingUuid: string, invoiceId: number): Observable<{ status: string, orderUuid: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, orderUuid: string }>('./assets/sample-data/twint/confirm-checkout-success.json');
    }

    const body = {}
    body['pairingUuid'] = pairingUuid;
    if (invoiceId != undefined) {
      body['invoiceId'] = invoiceId;
    }

    return this.http.post<{ status: string, orderUuid: string }>(SERVER_URL + '/v1/twint/confirm_checkout', body);
  }

}
