import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { DataStore } from '../../shell/data-store';
import { MyMembersIndexModel } from './index/my-members-index.model';
import {
  MyMembersShowAccountTransactionsModel,
  MyMembersShowBookingsModel,
  MyMembersShowCheckInsModel,
  MyMembersShowFilesModel,
  MyMembersShowMemberTicketsModel,
  MyMembersShowModel,
  MyMembersShowSalesModel,
  MyMembersShowVideosModel
} from './show/my-members-show.model';
import { PersonalInformationModel } from '../../components/personal-information-form/personal-information.model';
import { HealthQuestionnaireModel } from '../../components/health-questionnaire/health-questionnaire.model';
import { CouponModel } from '../../models/coupon.model';
import { MemberTicketModel } from '../../models/member-ticket.model';
import { ServiceSessionModel } from '../../models/service-session.model';
import { BookableDate } from './create-bookings/create-bookings.model';
import { environment, SERVER_URL } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../http/http.service';
import { TicketModel } from '../../models/ticket.model';
import { PaymentMethodModel } from '../../models/payment-method.model';
import { BookingModel } from '../../models/booking.model';
import { MemberTicketBookingsModel } from './member-ticket-bookings/member-ticket-bookings.model';
import { ProviderGroupsModel } from '../../models/provider-groups.model';
import { MemberHealthQuestionnaireModel } from '../../models/member-health-questionnaire.model';

@Injectable()
export class MyMembersService {
  constructor(private http: HttpClient) { }

  private indexDataStore: DataStore<MyMembersIndexModel>;
  private showDataStore: DataStore<MyMembersShowModel>;
  private memberTicketBookingsDataStore: DataStore<MemberTicketBookingsModel>;

  public createMemberTicket(providerId: string, customerId: number, ticketId: number, validFromDate: string, paymentMethod: string): Observable<{ status: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/empty.json');
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/create_member_ticket', {
      ticketId: ticketId,
      validFromDate: validFromDate,
      paymentMethod: paymentMethod
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public createMember(providerId: string, personalInformation: PersonalInformationModel, selectedProviderIds: Array<{ id: number }>): Observable<{ status: string, customerId: number }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, customerId: number }>('./assets/sample-data/admin-area/my-members/create-member-success.json');
      // return this.http.get<{status: string}>('./assets/sample-data/admin-area/my-members/create-member-email-already-in-use.json');
    }

    return this.http.post<{ status: string, customerId: number }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members', { personalInformation: personalInformation, selectedProviderIds: selectedProviderIds }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getIndexDataSource(providerId: string, page: number): Observable<MyMembersIndexModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersIndexModel>('./assets/sample-data/admin-area/my-members/my-members-index.json');
      // return this.http.get<MyMembersIndexModel>('./assets/sample-data/admin-area/my-members/my-members-index-empty.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersIndexModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getIndexDataStore(dataSource: Observable<MyMembersIndexModel>): DataStore<MyMembersIndexModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyMembersIndexModel = new MyMembersIndexModel();
    this.indexDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.indexDataStore.load(dataSource);
    return this.indexDataStore;
  }

  public getShowDataSource(providerId: string, customerId: number): Observable<MyMembersShowModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowModel>('./assets/sample-data/admin-area/my-members/my-members-show.json');
    }

    return this.http.get<MyMembersShowModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getProviderGroups (providerId: string) : Observable<{providerGroups : Array<ProviderGroupsModel>}> {
    return this.http.get<{providerGroups: Array<ProviderGroupsModel>}>(SERVER_URL + '/v1/admin_area/' + providerId + '/provider_groups').pipe(
      catchError(HttpService.handleError)
    )
  }

  public getShowDataStore(dataSource: Observable<MyMembersShowModel>): DataStore<MyMembersShowModel> {
    // Use cache if available
    if (!this.showDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: MyMembersShowModel = new MyMembersShowModel();
      this.showDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.showDataStore.load(dataSource);
    }
    return this.showDataStore;
  }

  // Customer actions
  public getPersonalInformation(providerId: string, customerId: number): Observable<{ personalInformation: PersonalInformationModel }> {
    if (environment.useSampleData) {
      return this.http.get<{ personalInformation: PersonalInformationModel }>('./assets/sample-data/admin-area/my-members/personal-information.json');
    }

    return this.http.get<{ personalInformation: PersonalInformationModel }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/personal_information').pipe(
      catchError(HttpService.handleError)
    );
  }

  public savePersonalInformation(providerId: string, customerId: number, personalInformation: PersonalInformationModel): Observable<{ status: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/admin-area/my-members/save-personal-information-success.json');
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/save_personal_information', { personalInformation: personalInformation }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public saveNotes(providerId: string, customerId: number, notes: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/save_notes', { notes: notes }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getHealthQuestionnaire(providerId: string, customerId: number, providerHealthQuestionnaireId: number, memberHealthQuestionnaireId: number, bookingId:number): Observable<{
    healthQuestionnaire: HealthQuestionnaireModel
  }> {
    if (environment.useSampleData) {
      return this.http.get<{
        healthQuestionnaire: HealthQuestionnaireModel
      }>('./assets/sample-data/admin-area/my-members/health-questionnaire-not-filled-out.json');
      // return this.http.get<{ healthQuestionnaire: HealthQuestionnaireModel }>('./assets/sample-data/admin-area/my-members/health-questionnaire-completed.json');
    }

    return this.http.get<{
      healthQuestionnaire: HealthQuestionnaireModel
    }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/health_questionnaire', {
      params: {
        provider_health_questionnaire_id: providerHealthQuestionnaireId,
        member_health_questionnaire_id: memberHealthQuestionnaireId != undefined ? memberHealthQuestionnaireId : -1,
        booking_id: bookingId != undefined ? bookingId : -1,
      }
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public saveHealthQuestionnaire(providerId: string, customerId: number, healthQuestionnaire: MemberHealthQuestionnaireModel): Observable<{savedChangedQuestionnaire: boolean}> {
    if (environment.useSampleData) {
      return this.http.get<{savedChangedQuestionnaire: boolean}>('./assets/sample-data/admin-area/my-members/has_been_saved.json');
    }

    return this.http.post<{savedChangedQuestionnaire: boolean}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/save_health_questionnaire', { healthQuestionnaire: healthQuestionnaire }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public addCovidCertificate(providerId: string, customerId: number, validUntil, certificateType: string): Observable<{ status: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/ok.json');
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/add_covid_certificate', {
      validUntil: validUntil,
      certificateType: certificateType
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getDiscounts(providerId: string, customerId: number): Observable<{ discounts: Array<CouponModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ discounts: Array<CouponModel> }>('./assets/sample-data/admin-area/my-members/discounts.json');
      // return this.http.get<{ discounts: Array<CouponModel> }>('./assets/sample-data/admin-area/my-members/discounts-empty.json');
    }

    return this.http.get<{ discounts: Array<CouponModel> }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/discounts').pipe(
      catchError(HttpService.handleError)
    );
  }

  public saveDiscount(providerId: string, customerId: number, couponId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/save_discount', { couponId: couponId }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public topUpCredit(providerId: string, customerId: number, amount: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/top_up_credit', { amount: amount }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public unloadCredit(providerId: string, customerId: number, amount: number): Observable<{ status: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/admin-area/my-members/unload-credit-ok.json');
      // return this.http.get<{status: string}>('./assets/sample-data/admin-area/my-members/unload-credit-negative.json');
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/unload_credit', { amount: amount }).pipe(
      catchError(HttpService.handleError)
    );
  }


  public activateAccount(providerId: string, customerId: number): Observable<MyMembersShowModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowModel>('./assets/sample-data/admin-area/my-members/my-members-show.json');
    }

    return this.http.post<MyMembersShowModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/activate', {}).pipe(
      catchError(HttpService.handleError)
    );
  }

  public deactivateAccount(providerId: string, customerId: number): Observable<MyMembersShowModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowModel>('./assets/sample-data/admin-area/my-members/my-members-show-deactivated.json');
    }

    return this.http.post<MyMembersShowModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/deactivate', {}).pipe(
      catchError(HttpService.handleError)
    );
  }

  public deleteCustomer(providerId: string, customerId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/delete').pipe(
      catchError(HttpService.handleError)
    );
  }

  public deleteCovidCertificate(providerId: string, customerId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/delete_covid_certificate').pipe(
      catchError(HttpService.handleError)
    );
  }

  public deleteProfilePicture(providerId: string, customerId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/delete_profile_picture').pipe(
      catchError(HttpService.handleError)
    );
  }

  public saveProfilePicture(providerId: string, customerId: number, profilePicture: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/save_profile_picture', { profilePicture: profilePicture }).pipe(
      catchError(HttpService.handleError)
    );
  }

  // Member tickets
  public getCurrentMemberTicketsDataSource(providerId: string, customerId: number, page: number): Observable<MyMembersShowMemberTicketsModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowMemberTicketsModel>('./assets/sample-data/admin-area/my-members/current-member-tickets.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersShowMemberTicketsModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/current_member_tickets', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getPastMemberTicketsDataSource(providerId: string, customerId: number, page: number): Observable<MyMembersShowMemberTicketsModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowMemberTicketsModel>('./assets/sample-data/admin-area/my-members/past-member-tickets.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersShowMemberTicketsModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/past_member_tickets', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public editMemberTicket(providerId: string, customerId: number, memberTicketId: number, validFromDate: string, validUntilDate: string, credits: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/edit_member_ticket', {
      memberTicketId: memberTicketId,
      validFromDate: validFromDate,
      validUntilDate: validUntilDate,
      credits: credits
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public createMemberTicketPause(providerId: string, customerId: number, memberTicketId: number, description: string, pauseFromDate: string, pauseUntilDate: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/create_member_ticket_pause', {
      memberTicketId: memberTicketId,
      description: description,
      pauseFromDate: pauseFromDate,
      pauseUntilDate: pauseUntilDate
    }).pipe(
      catchError(HttpService.handleError)
    );
  }
  public removeFromWaitingList(bookingId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{ status: string }>(SERVER_URL + '/v1/profile/waiting_list/' + bookingId).pipe(
      catchError(HttpService.handleError)
    );
  }

  public deleteMemberTicketPause(providerId: string, customerId: number, memberTicketId: number, memberTicketPauseId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    let params = new HttpParams()
      .set('memberTicketId', String(memberTicketId))
      .set('memberTicketPauseId', String(memberTicketPauseId));

    return this.http.delete<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/delete_member_ticket_pause', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public cancelMemberTicketRenewal(providerId: string, customerId: number, memberTicketId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/cancel_member_ticket_renewal', { memberTicketId: memberTicketId }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public deleteMemberTicket(providerId: string, customerId: number, memberTicketId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    let params = new HttpParams()
      .set('memberTicketId', String(memberTicketId));

    return this.http.delete<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/delete_member_ticket', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }


  // Bookings
  public getUpcomingBookingsDataSource(providerId: string, customerId: number, page: number): Observable<MyMembersShowBookingsModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowBookingsModel>('./assets/sample-data/admin-area/my-members/upcoming-bookings.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersShowBookingsModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/upcoming_bookings', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getPastBookingsDataSource(providerId: string, customerId: number, page: number): Observable<MyMembersShowBookingsModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowBookingsModel>('./assets/sample-data/admin-area/my-members/past-bookings.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersShowBookingsModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/past_bookings', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMemberTicketBookingsDataStore(dataSource: Observable<MemberTicketBookingsModel>): DataStore<MemberTicketBookingsModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MemberTicketBookingsModel = new MemberTicketBookingsModel();
    this.memberTicketBookingsDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.memberTicketBookingsDataStore.load(dataSource);
    return this.memberTicketBookingsDataStore;
  }

  public getMemberTicketBookingsDataSource(providerId: string, customerId: number, memberTicketId: number, page: number): Observable<MemberTicketBookingsModel> {
    if (environment.useSampleData) {
      return this.http.get<MemberTicketBookingsModel>('./assets/sample-data/admin-area/my-members/past-bookings.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MemberTicketBookingsModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/member_ticket_bookings/' + memberTicketId, { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public cancelBooking(providerId: string, customerId: number, booking: BookingModel, shouldRefundCredit: boolean): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    let params = new HttpParams()
      .set('bookingId', String(booking.id))
      .set('shouldRefundCredit', String(shouldRefundCredit));

    return this.http.delete<{}>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/cancel_booking', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public createBookings(providerId: string, customerId: number, memberTicketId: number, serviceSessionId: number, isLivestreamBooking: boolean, bookableDates: Array<BookableDate>): Observable<{ status: string }> {
    // return this.http.get<{status: string}>('./assets/sample-data/admin-area/my-members/create-bookings-full.json');
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/admin-area/my-members/create-bookings-success.json');
    }

    let dates = [];
    for (let bookableDate of bookableDates) {
      if (bookableDate.isChecked) {
        dates.push(bookableDate.date);
      }
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/create_bookings', {
      memberTicketId: memberTicketId,
      serviceSessionId: serviceSessionId,
      isLivestreamBooking: isLivestreamBooking,
      dates: dates
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMemberTickets(providerId: string, customerId: number): Observable<{ memberTickets: Array<MemberTicketModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ memberTickets: Array<MemberTicketModel> }>('./assets/sample-data/admin-area/my-members/member-tickets.json');
    }

    return this.http.get<{ memberTickets: Array<MemberTicketModel> }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/member_tickets').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getBookableServiceSessions(providerId: string, customerId: number, memberTicketId: number): Observable<{ serviceSessions: Array<ServiceSessionModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ serviceSessions: Array<ServiceSessionModel> }>('./assets/sample-data/admin-area/my-members/bookable-service-sessions.json');
    }

    let params = new HttpParams()
      .set('memberTicketId', String(memberTicketId));

    return this.http.get<{ serviceSessions: Array<ServiceSessionModel> }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/bookable_service_sessions', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getBookableDates(providerId: string, customerId: number, memberTicketId: number, serviceSessionId: number, isLivestreamBooking: boolean): Observable<{ bookableDates: Array<BookableDate> }> {
    if (environment.useSampleData) {
      return this.http.get<{ bookableDates: Array<BookableDate> }>('./assets/sample-data/admin-area/my-members/bookable-dates.json');
    }

    let params = new HttpParams()
      .set('memberTicketId', String(memberTicketId))
      .set('serviceSessionId', String(serviceSessionId))
      .set('isLivestreamBooking', String(isLivestreamBooking));

    return this.http.get<{ bookableDates: Array<BookableDate> }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/bookable_service_session_dates', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getServiceSessionTickets(providerId: string, customerId: number, serviceSessionId: number, date: string, isLivestreamBooking: boolean): Observable<{ tickets: Array<TicketModel>, memberTickets: Array<MemberTicketModel>, paymentMethods: Array<PaymentMethodModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ tickets: Array<TicketModel>, memberTickets: Array<MemberTicketModel>, paymentMethods: Array<PaymentMethodModel> }>('./assets/sample-data/admin-area/participants-lists/add-participant-customer-tickets.json');
      // return this.http.get<{ tickets: Array<TicketModel>, memberTickets: Array<MemberTicketModel>, paymentMethods: Array<PaymentMethodModel> }>('./assets/sample-data/admin-area/add-participant-customer-no-member-tickets.json');
    }

    let params = new HttpParams()
      .set('customerId', String(customerId))
      .set('serviceSessionId', String(serviceSessionId))
      .set('date', String(date))
      .set('isLivestreamBooking', String(isLivestreamBooking));

    return this.http.get<{ tickets: Array<TicketModel>, memberTickets: Array<MemberTicketModel>, paymentMethods: Array<PaymentMethodModel> }>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/service_session_tickets', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }


  // Check-ins
  public getCheckInsDataSource(providerId: string, customerId: number, page: number): Observable<MyMembersShowCheckInsModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowCheckInsModel>('./assets/sample-data/admin-area/my-members/check-ins.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersShowCheckInsModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/check_ins', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  // Videos
  public getVideosDataSource(providerId: string, customerId: number, page: number, searchQuery: string): Observable<MyMembersShowVideosModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowVideosModel>('./assets/sample-data/admin-area/my-members/videos.json');
    }

    let params = new HttpParams()
      .set('page', String(page))
      .set('query', String(searchQuery));

    return this.http.get<MyMembersShowVideosModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/videos', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  // Sales
  public getSalesDataSource(providerId: string, customerId: number, page: number): Observable<MyMembersShowSalesModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowSalesModel>('./assets/sample-data/admin-area/my-members/sales.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersShowSalesModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/point_of_sale_transactions', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  // Account transactions
  public getAccountTransactionsDataSource(providerId: string, customerId: number, page: number): Observable<MyMembersShowAccountTransactionsModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowAccountTransactionsModel>('./assets/sample-data/admin-area/my-members/account-transactions.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyMembersShowAccountTransactionsModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/account_transactions', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  // Files
  public getFilesDataSource(providerId: string, customerId: number, page: number, searchQuery: string): Observable<MyMembersShowFilesModel> {
    if (environment.useSampleData) {
      return this.http.get<MyMembersShowFilesModel>('./assets/sample-data/admin-area/my-members/files.json');
    }

    let params = new HttpParams()
      .set('query', String(searchQuery))
      .set('page', String(page));

    return this.http.get<MyMembersShowFilesModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/my_members/' + customerId + '/files', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }
}
