import { ShellModel } from '../../../shell/data-store';
import { PersonalInformationModel } from '../../../components/personal-information-form/personal-information.model';
import { TicketModel } from '../../../models/ticket.model';
import { PaymentMethodModel } from '../../../models/payment-method.model';
import { ProviderModel } from '../../../models/provider.model';
import { HealthQuestionnaireModel } from '../../../components/health-questionnaire/health-questionnaire.model';

export class TicketPurchaseInformationModel extends ShellModel {
  ticket: TicketModel;
  provider: ProviderModel;
  paymentMethods = Array<PaymentMethodModel>();
  healthQuestionnaires: Array<HealthQuestionnaireModel>;
  personalInformation: PersonalInformationModel;
  isCouponActive: boolean;
  purchaseNotPossible: boolean;
  purchaseNotPossibleMessage: string;

  constructor() {
    super();
  }
}
