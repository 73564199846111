import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShortHealthQuestionnaireModel } from '../../../models/short-health-questionnaire.model';

@Component({
  selector: 'app-select-health-questionnaire-modal',
  templateUrl: './select-health-questionnaire.modal.html',
  styleUrls: ['./select-health-questionnaire.modal.scss'],
})
export class SelectHealthQuestionnaireModal implements OnInit {
  @Input() providerId: string;
  @Input() customerId: number;
  @Input() bookingId: number;
  @Input() shortHealthQuestionnaires: Array<ShortHealthQuestionnaireModel>;
  @Input() showIcons: boolean = true;
  @Input() presentEditModalDirectly: boolean = false;

  hasAHealthQuestionnaireChanged: boolean = false;

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss({ hasAHealthQuestionnaireChanged: this.hasAHealthQuestionnaireChanged });
  }

  healthQuestionnaireHasChanged(){
    this.hasAHealthQuestionnaireChanged = true;

    this.dismissModal();
  }
}
