import { ShellModel } from '../../shell/data-store';
import { BookingModel } from '../../models/booking.model';

export class MyBookingsModel extends ShellModel {
  bookings: Array<BookingModel>;

  constructor() {
    super();
  }
}
