import { Injectable } from '@angular/core';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RateUsService {

  constructor(
    private launchReview: LaunchReview,
    private platform: Platform
  ) { }
  
  public showRateAppDialog(delayInMilliseconds: number) {
    console.log('showRateAppDialog() trying to show dialog.');
    // Ask for app review one second after the success popup shows.
    setTimeout(() => {
      if (this.platform.is('ios') && this.launchReview.isRatingSupported()) {
        /***
         * Note: The rate us prompt on iOS does not show in TestFlight and only a limited amount of times in Production.
         * https://stackoverflow.com/questions/45057452/why-the-skstorereviewcontroller-does-not-let-me-submit-a-review/45061556#45061556
         */
        this.launchReview.rating().subscribe((value) => {
            console.log('launchReview.rating() value: ', value);
          },
          (error) => {
            console.log('launchReview.rating() failed', error);
          }
        );
      }
      else if (this.platform.is('android')) {
        /***
         * Note: There is no guarantee that the dialog shows.
         * https://stackoverflow.com/questions/63286540/play-core-in-app-review-api-not-showing-the-review-activity
         */
        // FIXME: Find replacement for the RateApp plugin.
        // RateApp.requestReview();
      }
      else {
        console.log('showRateAppDialog() dialog cannot be shown.');
      }
    }, delayInMilliseconds);
  }
}
