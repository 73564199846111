<form>
  <div
    *ngFor="let healthQuestion of healthQuestionnaire.providerHealthQuestionnaire.healthQuestions; index as questionIndex">
    <ion-item-divider>
      <ion-label class="question-label">
        {{translateMultilingualField(healthQuestion.question, currentLanguage, healthQuestionnaire.providerLanguage)}}
      </ion-label>
    </ion-item-divider>
    <div *ngIf="healthQuestion.type == QuestionType.YesNo">
      <ion-list class="radio-list" lines="full">
        <ion-radio-group class="radio-group"
                         [(ngModel)]="getAnswer(questionIndex)[0]"
                         [name]="questionIndex.toString()" (ionChange)="answerChanged()">
          <ion-item class="radio-item">
            <ion-label class="radio-label">{{ 'app.yes' | translate }}</ion-label>
            <ion-radio slot="start" color="secondary" value="true"></ion-radio>
          </ion-item>
          <ion-item class="radio-item">
            <ion-label class="radio-label">{{ 'app.no' | translate }}</ion-label>
            <ion-radio slot="start" color="secondary" value="false"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>

    <div *ngIf="healthQuestion.type == QuestionType.Freetext">
      <ion-list class="input-list">
        <ion-item class="input-item">
          <ion-textarea [(ngModel)]="getAnswer(questionIndex)[0]"
                        [name]="questionIndex.toString()" (ionChange)="answerChanged()"></ion-textarea>
        </ion-item>
      </ion-list>
    </div>

    <div *ngIf="healthQuestion.type == QuestionType.MultipleChoiceSingleAnswer">
      <ion-list class="radio-list" lines="full">
        <ion-radio-group class="radio-group"
                         [(ngModel)]="getAnswer(questionIndex)[0]"
                         [name]="questionIndex.toString()"
                          (ionChange)="answerChanged()">
          <ion-item class="radio-item" *ngFor="let answerOptions of healthQuestion.answers; index as answerIndex">
            <ion-label
              class="radio-label">{{ translateMultilingualField(answerOptions, currentLanguage, healthQuestionnaire.providerLanguage) }}</ion-label>
            <ion-radio slot="start" color="secondary" [value]="answerIndex.toString()"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>

    <div *ngIf="healthQuestion.type == QuestionType.MultipleChoiceMultipleAnswers">
      <ion-list class="checkbox-list" lines="full">
        <ion-item class="checkbox-item" *ngFor="let answerOptions of healthQuestion.answers; index as answerIndex">
          <ion-checkbox slot="start" color="secondary" [value]="answerIndex"
                        [checked]="isChecked(questionIndex, answerIndex)"
                        (ionChange)="onCheckboxChange($event, questionIndex, answerIndex)"></ion-checkbox>&nbsp;
          <ion-label
            class="checkbox-label">{{ translateMultilingualField(answerOptions, currentLanguage, healthQuestionnaire.providerLanguage) }}</ion-label>
        </ion-item>
      </ion-list>
    </div>
  </div>

</form>
