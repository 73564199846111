import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent implements OnInit {

  settings: MbscPopupOptions = {
    display: 'center',
    onClose: (event, inst) => {
      this.resolve();
    }
  };

  // @ts-ignore
  @ViewChild('popup')
  popup: any;
  title: string;
  message: string;
  resolve: any;

  constructor() { }

  ngOnInit() {}

  show(title: string, message: string) {
    this.title = title;
    this.message = message;
    this.popup.instance.show();
    const that = this;
    return new Promise(function(resolve) {
      that.resolve = resolve;
    });
  }

}
