import { ProviderModel } from './provider.model';

export class MemberTicketModel {
    id: number;
    canPayOnline: boolean;
    class: string;
    credits: number;
    hasUnlimitedCredits: boolean;
    invoiceId: number;
    invoiceDownloadUrl: string;
    isCancellable: boolean;
    isForLivestreamBookingOnly: boolean;
    isOverdue: boolean;
    isPaid: boolean;
    localizedCredits: string;
    localizedPaymentMethod: string;
    memberName: string;
    name: string;
    openInvoiceAmount: string;
    pauses: Array<MemberTicketPause>;
    payableUntil: string;
    paymentInformation: Array<{label: string, value: string}>;
    paymentMethod: string;
    paymentStatus: string;
    provider: ProviderModel;
    purchaseDate: string;
    status: string;
    validFrom: string;
    validity: string;
    validUntil: string;
}

export class MemberTicketPause {
    id: number;
    description: string;
    pauseFrom: string;
    pauseUntil: string;
}
