import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../profile/profile.service';
import { AlertController, Platform } from '@ionic/angular';
import { LaunchReview } from '@ionic-native/launch-review/ngx';

@Component({
  selector: 'app-rate-us-home-card',
  templateUrl: './rate-us-home-card.component.html',
  styleUrls: ['./rate-us-home-card.component.scss'],
})
export class RateUsHomeCardComponent implements OnInit {
  @Input() salutation: string;
  @Input() profileService: ProfileService;
  @Output() onComplete: EventEmitter<any> = new EventEmitter();

  // @ts-ignore
  @ViewChild('rateUsPopup')
  rateUsPopup: any;

  // @ts-ignore
  @ViewChild('sendFeedbackPopup')
  sendFeedbackPopup: any;

  popupSettings: MbscPopupOptions = {
    lang: this.translate.currentLang,
    theme: 'mobiscroll',
    themeVariant: 'light',
    display: 'center',
    buttons: []
  };

  feedback: string;

  constructor(
    private alertController: AlertController,
    public translate: TranslateService,
    private launchReview: LaunchReview,
    public platform: Platform
  ) { }

  ngOnInit() {}

  showRateUsPopup() {
    this.rateUsPopup.instance.show();

    // Android: Try to proactive show the in-app review dialog since there is no guarantee it shows.
    // If it shows then chances are high the user rates our app directly. Otherwise, the user can choose to open
    // the Play Store via the rate us pop-up.
    if (this.platform.is('android')) {
      // FIXME: Find replacement for the RateApp plugin.
      // RateApp.requestReview();
    }
  }

  openAppStoreReview() {
    const that = this;
    // Only use the native in-app rating dialog for iOS as it seems to fail silently on Android.
    if (this.platform.is('ios') && this.launchReview.isRatingSupported()) {
      /***
       * Note: The rate us prompt on iOS does not show in TestFlight and only a limited amount of times in Production.
       * https://stackoverflow.com/questions/45057452/why-the-skstorereviewcontroller-does-not-let-me-submit-a-review/45061556#45061556
       */

      let hasRatingViewShown = false;
      this.launchReview.rating().subscribe((value) => {
          console.log('launchReview.rating() value: ', value);
          if (value === 'shown') {
            hasRatingViewShown = true;
            that.profileService.acceptRateUs().subscribe();
          }
        },
        (error) => {
          console.log('launchReview.rating() failed', error);
        }
      );

      // Fallback to open the App Store if the in-app dialog fails to show.
      setTimeout(() => {
        if (!hasRatingViewShown) {
          this.launchReview.launch()
            .then(() => {
              console.log('launchReview.launch() successful');
              that.profileService.acceptRateUs().subscribe();
            })
            .catch((reason) => {
              console.log('launchReview.launch() failed', reason);
            });
        }
      }, 2000);
    }
    else {
      this.launchReview.launch()
        .then(() => {
          console.log('launchReview.launch() successful');
          that.profileService.acceptRateUs().subscribe();
        })
        .catch((reason) => {
          console.log('launchReview.launch() failed', reason);
        });
    }

    this.rateUsPopup.instance.hide();
  }

  async sendFeedback() {
    this.profileService.sendAppFeedback(this.feedback).subscribe();

    const alert = await this.alertController.create({
      message: this.translate.instant('rate_us.thanks_for_feedback'),
      buttons: ['👍']
    });

    this.onComplete.emit();
    this.sendFeedbackPopup.instance.hide();
    await alert.present();
  }

  rateUsLater() {
    this.profileService.rateUsLater().subscribe();
    this.sendFeedbackPopup.instance.hide();
    this.rateUsPopup.instance.hide();
  }

  stopRateUs() {
    this.profileService.stopRateUs().subscribe();
    this.onComplete.emit();
    this.sendFeedbackPopup.instance.hide();
    this.rateUsPopup.instance.hide();
  }

  showFeedbackPopup() {
    this.sendFeedbackPopup.instance.show();
  }
}
