<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'pricing.purchase' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<app-info-popup #infoPopupComponent></app-info-popup>
<app-success-popup #successPopupComponent></app-success-popup>
<app-twint-payment [ticketId]="model.ticket.id" [couponId]="activatedCoupon?.id"
                   #twintPaymentComponent></app-twint-payment>

<ion-content class="purchase-content">
  <section class="ticket-details-section">
    <h5 class="ticket-name">{{ model.ticket.name }}</h5>
    <p class="ticket-details">{{ model.ticket.description }}</p>
    <p class="ticket-details">{{ model.ticket.validity }}</p>
    <p class="ticket-details">{{ model.ticket.credit }}</p>
    <p class="ticket-price">{{ model.ticket.localizedPrice }}</p>
  </section>

  <mbsc-accordion>
    <!-- Validity -->
    <mbsc-card collapsible #validityCard="mobiscroll">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="checkmark-circle-outline"></ion-icon>
          {{ validityCardTitle }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="validityForm">
          <ion-list class="inputs-list" lines="none">
            <ion-item class="input-item">
              <mbsc-calendar #datePicker="mobiscroll" [options]="datePickerSettings" (click)="enableDatePicker()"
                             formControlName="valid_from">{{ 'pricing.valid_from' | translate }}</mbsc-calendar>
            </ion-item>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Select payment method -->
    <mbsc-card collapsible #paymentMethodCard [hidden]="model.ticket.price === 0">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="checkmark-circle-outline" *ngIf="selectedPaymentMethod"></ion-icon>
          {{ paymentMethodCardTitle }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="paymentMethodsForm">
          <ion-list class="ticket-list" lines="full">
            <ion-radio-group class="radio-group" formControlName="selected_payment_method">
              <div *ngFor="let paymentMethod of model.paymentMethods">
                <ion-item class="radio-item" (click)="selectPaymentMethod(paymentMethod)"
                          [hidden]="isPaymentMethodExcludedFromSelectedTicket(paymentMethod) || paymentMethod?.id === 'invoice_payment_method_customer_credit' && paymentMethod?.accountBalance < model.ticket.price">
                  <ion-label class="radio-label">
                    <p class="ticket-title">{{ paymentMethod.name }}</p>
                    <p class="ticket-description">{{ paymentMethod.description }}</p>
                    <p class="payment-note" *ngIf="paymentMethod.cashPaymentNote != undefined && paymentMethod.cashPaymentNote !== ''"><ion-icon name="information-circle-outline"></ion-icon> {{ paymentMethod.cashPaymentNote }}</p>
                    <p class="payment-note" *ngIf="paymentMethod?.id === 'invoice_payment_method_credit_card'"><ion-icon name="information-circle-outline"></ion-icon> {{ 'booking.credit_card_fees_may_apply' | translate }}</p>
                  </ion-label>
                  <ion-radio slot="start" color="secondary" value="{{ paymentMethod.id }}"></ion-radio>
                </ion-item>
              </div>
            </ion-radio-group>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Credit card input -->
    <mbsc-card collapsible #creditCardInputCard
               [hidden]="selectedPaymentMethod == undefined || selectedPaymentMethod.id !== 'invoice_payment_method_credit_card'">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="card-outline"></ion-icon>
          {{ 'app.credit_card_information' | translate }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <app-stripe-payment [stripeAccount]="model.provider.stripeUserId"
                            (onFormSubmit)="onCreditCardInputFormSubmit()" [hidden]="isConfirmationInProgress" #stripePaymentComponent></app-stripe-payment>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Personal information -->
    <mbsc-card collapsible #personalInformationCard [hidden]="selectedPaymentMethod == undefined && model.ticket.price > 0">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="checkmark-circle-outline" *ngIf="isPersonalInformationComplete"></ion-icon>
          {{ personalInformationCardTitle }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <app-personal-information-form [personalInformation]="model.personalInformation"
                                       #personalInformationFormComponent></app-personal-information-form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Health questionnaire -->
    <app-edit-health-questionnaires-card  #editHealthQuestionnairesCardComponent [healthQuestionnaires]="model.healthQuestionnaires" [externalHideCondition]="selectedPaymentMethod == undefined && model.ticket.price > 0 || !isPersonalInformationComplete"></app-edit-health-questionnaires-card>

    <!-- Coupon -->
    <mbsc-card collapsible #couponCard [hidden]="!model.isCouponActive || model.ticket.price === 0 || selectedPaymentMethod == undefined">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="help-circle-outline" *ngIf="!activatedCoupon"></ion-icon>
          <ion-icon name="checkmark-circle-outline" *ngIf="activatedCoupon"></ion-icon>
          {{ couponCardTitle }}
        </mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="couponForm" (ngSubmit)="onSubmitCouponForm(couponForm.value)">
          <ion-list class="inputs-list" lines="full">
            <ion-item class="input-item">
              <ion-label color="secondary" position="floating">{{ 'booking.enter_coupon_code' | translate }}</ion-label>
              <ion-input type="text" formControlName="coupon_id" clearInput></ion-input>
            </ion-item>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>
  </mbsc-accordion>

  <ion-list class="inputs-list" lines="full" [hidden]="model.ticket == undefined">
    <ion-item>
      <ion-label>{{ 'app.net' | translate }}</ion-label>
      <ion-note slot="end">{{ originalTicket.netPrice }}</ion-note>
    </ion-item>
    <ion-item [hidden]="activatedCoupon == undefined">
      <ion-label>{{ 'booking.discount' | translate }}</ion-label>
      <ion-note slot="end">{{ activatedCoupon?.total }}</ion-note>
    </ion-item>
    <ion-item [hidden]="model.ticket.vat == undefined">
      <ion-label>{{ 'app.vat' | translate }}</ion-label>
      <ion-note slot="end">{{ model.ticket.vat }}</ion-note>
    </ion-item>
    <ion-item [hidden]="model.ticket.fees == undefined || selectedPaymentMethod == undefined || selectedPaymentMethod.id !== 'invoice_payment_method_credit_card'">
      <ion-label>{{ 'app.fees' | translate }}</ion-label>
      <ion-note slot="end">{{ model.ticket.fees }}</ion-note>
    </ion-item>
    <ion-item [hidden]="selectedPaymentMethod?.id === 'invoice_payment_method_credit_card'">
      <ion-label class="total-label">{{ 'app.total' | translate }}</ion-label>
      <ion-note slot="end" class="total-label">{{ model.ticket.total }}</ion-note>
    </ion-item>
    <ion-item [hidden]="selectedPaymentMethod == undefined || selectedPaymentMethod.id !== 'invoice_payment_method_credit_card'">
      <ion-label class="total-label">{{ 'app.total' | translate }}</ion-label>
      <ion-note slot="end" class="total-label">{{ model.ticket.totalWithFees }}</ion-note>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-row class="form-actions-wrapper">
    <ion-col>
      <a target="_blank" href="{{model.provider.termsAndConditionsUrl}}" class="terms-and-conditions-link">
        <p class="terms-and-conditions-info">
          <span>{{ 'booking.accept_terms' | translate: {name: model.provider.name} }}</span>
          <ion-icon name="open-outline"></ion-icon>
        </p>
      </a>

      <ion-button class="submit-btn" expand="block" color="secondary" type="submit" fill="solid" (click)="validatePurchase()">{{ 'pricing.purchase_now' | translate }}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
