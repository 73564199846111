import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProviderModel } from '../../models/provider.model';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-activate-premium',
  templateUrl: './activate-premium.modal.html',
  styleUrls: ['./activate-premium.modal.scss'],
})
export class ActivatePremiumModal implements OnInit {
  @Input() provider: ProviderModel;

  constructor(
    private modalController: ModalController,
    private storage: Storage
  ) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  openMyAccount() {
    this.storage.get('defaultProvider').then((value) => {
      const provider = value;
      window.open(provider.myAccountUrl,'_system', 'location=yes');
    });
  }
}
