import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MbscCalendar, MbscCalendarOptions } from '@mobiscroll/angular';
import { ActivatedRoute } from '@angular/router';
import { TicketPurchaseInformationModel } from './ticket-purchase-information.model';
import { TranslateService } from '@ngx-translate/core';
import {
  PersonalInformationFormComponent
} from '../../../components/personal-information-form/personal-information-form.component';
import * as dayjs from 'dayjs';
import { ScheduleService } from '../../schedule/schedule.service';
import { PricingService } from '../pricing.service';
import { CouponModel } from '../../../models/coupon.model';
import { PaymentMethodModel } from '../../../models/payment-method.model';
import { StripePaymentComponent } from '../../../components/stripe-payment/stripe-payment.component';
import { TicketModel } from '../../../models/ticket.model';
import { StripeService } from '../../../components/stripe-payment/stripe.service';
import { EventsService } from '../../../utils/events.service';
import { Keyboard } from '@ionic-native/keyboard';
import {
  EditHealthQuestionnairesCardComponent
} from '../../../components/edit-health-questionnaires-card/edit-health-questionnaires-card.component';
import { RekaService } from '../../../components/reka-payment/reka.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.modal.html',
  styleUrls: ['./styles/purchase.modal.scss'],
})
export class PurchaseModal implements OnInit, AfterViewChecked {
  @Input() route: ActivatedRoute;
  @Input() model: TicketPurchaseInformationModel;

  // Cards
  // @ts-ignore
  @ViewChild('validityCard')
  validityCard: any;
  validityCardTitle: String;

  // @ts-ignore
  @ViewChild('paymentMethodCard')
  paymentMethodCard: any;
  paymentMethodCardTitle: String;

  // @ts-ignore
  @ViewChild('creditCardInputCard')
  creditCardInputCard: any;

  // @ts-ignore
  @ViewChild('personalInformationCard')
  personalInformationCard: any;
  personalInformationCardTitle: string;
  isPersonalInformationComplete: boolean;

  // @ts-ignore
  @ViewChild('couponCard')
  couponCard: any;
  couponCardTitle: String;

  // Components
  // @ts-ignore
  @ViewChild('personalInformationFormComponent')
  personalInformationFormComponent: PersonalInformationFormComponent;

  @ViewChild('editHealthQuestionnairesCardComponent')
  editHealthQuestionnairesCardComponent: EditHealthQuestionnairesCardComponent;

  // @ts-ignore
  @ViewChild('infoPopupComponent')
  infoPopupComponent: any;

  // @ts-ignore
  @ViewChild('successPopupComponent')
  successPopupComponent: any;

  // @ts-ignore
  @ViewChild('stripePaymentComponent')
  stripePaymentComponent: StripePaymentComponent;

  // @ts-ignore
  @ViewChild('twintPaymentComponent')
  twintPaymentComponent: any;


  // Form states
  validityForm: FormGroup;
  paymentMethodsForm: FormGroup;
  couponForm: FormGroup;
  validFrom = new Date();
  selectedPaymentMethod: PaymentMethodModel;
  activatedCoupon: CouponModel;
  stripePaymentIntentClientSecret: string;
  stripeCheckoutSuccessToken: string;
  originalTicket: TicketModel; // Used for coupon handling and restoring.
  isConfirmationInProgress: boolean;
  rekaOrderId: string;


  // @ts-ignore
  @ViewChild('datePicker')
  datePicker: MbscCalendar;

  datePickerSettings: MbscCalendarOptions = {
    lang: this.translate.currentLang,
    theme: 'mobiscroll',
    themeVariant: 'light',
    display: 'center',
    inputStyle: 'box',
    onDayChange: (event) => {
      this.validFrom = event.date;
      this.validityCardTitle = this.translate.instant('pricing.valid_from') + ': ' + dayjs(this.validFrom).format('DD.MM.YYYY') as string;
    },
    onClose: () => {
      this.validityCardTitle = this.translate.instant('pricing.valid_from') + ': ' + dayjs(this.validFrom).format('DD.MM.YYYY') as string;
      this.datePicker.disabled = true; // The date picker needs to be disabled because the personal information component contains a date picker too. Two enabled date pickers seem to trigger each other.
      this.paymentMethodCard.instance.show();
    }
  };

  enableDatePicker() {
    this.datePicker.disabled = false;
  }

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    public loadingController: LoadingController,
    private scheduleService: ScheduleService,
    private alertController: AlertController,
    private changeDetector: ChangeDetectorRef,
    private stripeService: StripeService,
    private eventsService: EventsService,
    private pricingService: PricingService,
    private rekaService: RekaService
  ) { }

  ngOnInit() {
    this.originalTicket = { ...this.model.ticket };

    this.initValidityCard();
    this.initPaymentMethodCard();
    this.initPersonalInformationCard();
    this.initCouponCard();
  }

  // Needs to be implemented to prevent 'ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.' caused by the healthQuestionnaireComponent.isValid() check in the HTML.
  // https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  initValidityCard() {
    this.validityCardTitle = this.translate.instant('pricing.valid_from') + ': ' + dayjs(this.validFrom).format('DD.MM.YYYY') as string;

    this.validityForm = new FormGroup({
      'valid_from': new FormControl(dayjs(this.validFrom).format('YYYY-MM-DD') as string, Validators.required)
    });
  }

  initPaymentMethodCard() {
    this.selectedPaymentMethod = undefined;

    this.paymentMethodCardTitle = this.translate.instant('booking.choose_payment_method');

    this.paymentMethodsForm = new FormGroup({
      selected_payment_method: new FormControl('')
    });

    if (this.model.ticket.price > 0) {
      const that = this;
      setTimeout(function () {
        that.paymentMethodCard.instance.show();
      }, 850);
    }
  }

  initPersonalInformationCard() {
    // Show the personal address as the form group title if the personal information is complete.
    if (this.model.personalInformation.address != undefined && this.model.personalInformation.address !== '') {
      this.personalInformationCardTitle = this.model.personalInformation.firstName + ' ' + this.model.personalInformation.lastName + ', ' + this.model.personalInformation.address + ', ' + this.model.personalInformation.country + '-' + this.model.personalInformation.zipCode + ' ' + this.model.personalInformation.city;
      this.isPersonalInformationComplete = true;
    }
    else {
      this.personalInformationCardTitle = this.translate.instant('app.personal_information');
      this.isPersonalInformationComplete = false;

      if (this.model.ticket.price === 0) {
        const that = this;
        setTimeout(function () {
          that.personalInformationCard.instance.show();
        }, 850);
      }
    }
  }

  initCouponCard() {
    this.couponCardTitle = this.translate.instant('booking.coupon');

    // Coupon form
    this.couponForm = new FormGroup({
      'coupon_id': new FormControl('')
    });
  }

  ngAfterViewInit() {
    this.personalInformationFormComponent.personalInformationForm.get('first_name').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('last_name').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('address').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('zip_code').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('city').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('country').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
  }

  // Sets the personal information as the form group title if the personal information is complete. Otherwise, sets the personal information label as the title.
  updatePersonalInformationCardTitle() {
    const firstName = this.personalInformationFormComponent.personalInformationForm.get('first_name').value;
    const lastName = this.personalInformationFormComponent.personalInformationForm.get('last_name').value;
    const address = this.personalInformationFormComponent.personalInformationForm.get('address').value;
    const zipCode = this.personalInformationFormComponent.personalInformationForm.get('zip_code').value;
    const city = this.personalInformationFormComponent.personalInformationForm.get('city').value;
    const country = this.personalInformationFormComponent.personalInformationForm.get('country').value;
    if (firstName !== '' && lastName !== '' && address !== '' && zipCode !== '' && city !== '' && country !== '') {
      this.personalInformationCardTitle = firstName + ' ' + lastName + ', ' + address + ', ' + country + '-' + zipCode + ' ' + city;
      this.isPersonalInformationComplete = true;
    }
    else {
      this.personalInformationCardTitle = this.translate.instant('app.personal_information');
      this.isPersonalInformationComplete = false;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }


  // Select handlers
  selectPaymentMethod(selectedPaymentMethod: PaymentMethodModel) {
    this.initPersonalInformationCard();
    this.initCouponCard();

    this.selectedPaymentMethod = selectedPaymentMethod;
    this.paymentMethodCardTitle = selectedPaymentMethod.name;
    this.datePicker.disabled = true; // The date picker needs to be disabled because the personal information component contains a date picker too. Two enabled date pickers seem to trigger each other.

    // Workaround: The timeout is needed to toggle the form groups correctly.
    const that = this;
    setTimeout(function () {
      that.paymentMethodCard.instance.hide();

      if (that.selectedPaymentMethod.id === 'invoice_payment_method_credit_card') {
        that.getStripePaymentIntentClientSecret(true);
        return;
      }

      if (that.model.personalInformation.address == undefined || that.model.personalInformation.address === '') {
        that.personalInformationCard.instance.show();
        return;
      }

      if (that.editHealthQuestionnairesCardComponent != undefined) {
        if (that.editHealthQuestionnairesCardComponent.allHealthQuestionnairesCompleted) {
          that.editHealthQuestionnairesCardComponent.hide();
        }
        else {
          that.editHealthQuestionnairesCardComponent.show();
        }
        return;
      }
    }, 100);
  }

  async getStripePaymentIntentClientSecret(shouldShowCreditCardInput: boolean) {
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.stripeService.checkout(this.model.ticket, this.activatedCoupon, null, 'manual').subscribe({
        async next(data) {
          (await loading).dismiss();
          that.stripePaymentIntentClientSecret = data.paymentIntentClientSecret;
          that.stripeCheckoutSuccessToken = data.stripeCheckoutSuccessToken;

          if (shouldShowCreditCardInput) {
            that.creditCardInputCard.instance.show();
          }
        },
        async error(message) {
          (await loading).dismiss();
          that.initPaymentMethodCard();
          setTimeout(function () {
            that.paymentMethodCard.instance.show();
          }, 100);
        }
      }
    );
  }

  onCreditCardInputFormSubmit() {
    // Workaround: The timeout is needed to toggle the form groups correctly.
    const that = this;
    setTimeout(function () {
      that.creditCardInputCard.instance.hide();

      if (that.model.personalInformation.address == undefined || that.model.personalInformation.address === '') {
        that.personalInformationCard.instance.show();
        return;
      }

      if (that.editHealthQuestionnairesCardComponent != undefined) {
        if (that.editHealthQuestionnairesCardComponent.allHealthQuestionnairesCompleted) {
          that.editHealthQuestionnairesCardComponent.hide();
        }
        else {
          that.editHealthQuestionnairesCardComponent.show();
        }
        return;
      }
    }, 100);
  }

  validatePurchase() {
    this.datePicker.instance.disable();
    if (this.selectedPaymentMethod == undefined && this.model.ticket.price > 0) {
      this.infoPopupComponent.show(this.translate.instant('booking.please_choose_payment_method')).then(() => {
        this.datePicker.instance.enable();
      });
      return;
    }
    if (!this.personalInformationFormComponent.isFormValid()) {
      this.infoPopupComponent.show(this.translate.instant('components.personal_information.please_fill_out')).then(() => {
        this.datePicker.instance.enable();
      });
      return;
    }
    if (this.editHealthQuestionnairesCardComponent.areAllHealthQuestionnairesUsable() && !this.editHealthQuestionnairesCardComponent.areAllHealthQuestionnairesValid() && (this.selectedPaymentMethod != undefined || this.model.ticket != undefined && this.model.ticket.price === 0)) {
      this.infoPopupComponent.show(this.translate.instant('components.health_questionnaire.please_fill_out')).then(() => {
        this.datePicker.instance.enable();
      });
      return;
    }
    if (this.activatedCoupon == undefined && this.couponForm.get('coupon_id').value !== '') {
      this.onSubmitCouponForm(this.couponForm.value);
      return;
    }

    Keyboard.hide();

    if (this.selectedPaymentMethod != undefined && this.selectedPaymentMethod.id === 'invoice_payment_method_credit_card') {
      this.stripePaymentComponent.confirmPayment(this.stripePaymentIntentClientSecret, this.personalInformationFormComponent.personalInformationForm.get('first_name').value + ' ' + this.personalInformationFormComponent.personalInformationForm.get('last_name').value).then(() => {
        this.confirmPurchase();
      }).catch(() => {
        this.getStripePaymentIntentClientSecret(false);
      });
    }
    else if (this.selectedPaymentMethod != undefined && this.selectedPaymentMethod.id === 'invoice_payment_method_twint') {
      this.twintPaymentComponent.start().then(() => {
        this.confirmPurchase();
      });
    }
    else if (this.selectedPaymentMethod != undefined && this.selectedPaymentMethod.id === 'invoice_payment_method_reka') {
      const that = this;
      this.rekaService.createRekaCheckout(this.model.ticket.id, this.activatedCoupon?.id, null, null, null, null, dayjs(this.validFrom).format('DD.MM.YYYY') as string).subscribe({
        async next(data) {
          that.rekaOrderId = data.orderId;
          if (that.rekaOrderId != undefined && that.rekaOrderId !== '') {
            that.confirmPurchase();
          }
        }
      });
    }
    else {
      this.confirmPurchase();
    }
  }

  async confirmPurchase() {
    this.isConfirmationInProgress = true;
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();

    this.pricingService.confirmPurchase(this.model.ticket, dayjs(this.validFrom).format('DD.MM.YYYY') as string, this.model.provider, this.selectedPaymentMethod, this.stripeCheckoutSuccessToken, this.twintPaymentComponent.orderUuid, this.activatedCoupon, this.personalInformationFormComponent.getPersonalInformationFromForm(), this.editHealthQuestionnairesCardComponent.getAnsweredHealthQuestionnaires(), this.rekaOrderId).subscribe({
        async next(response) {
          (await loading).dismiss();
          switch (response.status) {
            case 'booking_status_success':
              that.successPopupComponent.show(that.translate.instant('pricing.purchase_successful'), that.translate.instant('booking.have_fun')).then(() => {
                that.modalController.dismiss({});
                that.eventsService.publish('memberTicketsChanged', {});
              });
              break;
            case 'bookings_status_ticket_already_purchased':
              this.infoPopupComponent.show(this.translate.instant('booking.already_purchased_identical_ticket'));
              break;
            case 'bookings_status_twint_confirm_order_error':
              that.twintPaymentComponent.resume().then(() => {
                that.confirmPurchase();
              });
              break;
            default:
              that.datePicker.instance.enable();
          }
        },
        async error(message) {
          (await loading).dismiss();
          that.datePicker.instance.enable();
          that.isConfirmationInProgress = false;
        }
      }
    );
  }

  isPaymentMethodExcludedFromSelectedTicket(paymentMethod: PaymentMethodModel) {
    return this.model.ticket != undefined && paymentMethod != undefined && this.model.ticket.excludedPaymentMethods.some(excludedPaymentMethod => excludedPaymentMethod === paymentMethod.id);
  }

  async onSubmitCouponForm(form: any) {
    // Remove coupon if the input field has been cleared.
    if (form.coupon_id === '') {
      this.activatedCoupon = undefined;
      this.couponCardTitle = this.translate.instant('booking.coupon');
      this.model.ticket = this.originalTicket;
      return;
    }

    // Validate coupon if entered.
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.scheduleService.validateCoupon(form.coupon_id, this.model.ticket.id).subscribe({
        async next(response) {
          (await loading).dismiss();
          that.activatedCoupon = response.coupon;

          if (response.coupon.isValid) {
            that.model.ticket = response.ticket;
            that.couponCardTitle = response.coupon.description;
            that.couponCard.instance.hide();
          }
          else {
            const alert = await that.alertController.create({
              header: that.translate.instant('booking.invalid_coupon'),
              message: that.translate.instant('booking.invalid_coupon_instructions'),
              buttons: ['OK']
            });

            await alert.present();
            that.couponForm.get('coupon_id').setValue('');
          }
        },
        async error(message) {
          (await loading).dismiss();
        }
      }
    );
  }

}
