<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'my_members.add_covid_certificate' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<!--  Popups -->
<app-info-popup #infoPopupComponent></app-info-popup>
<app-success-popup #successPopupComponent></app-success-popup>

<ion-content class="add-covid-certificate-modal-content">
  <p class="instructions">{{ 'my_members.add_covid_certificate_instructions' | translate }}</p>

  <form [formGroup]="covidCertificateForm">
    <ion-list class="inputs-list" lines="full">
      <ion-item class="input-item">
        <ion-label position="floating"
                   class="certificate-type-label">{{ 'my_members.certificate_type' | translate }}</ion-label>
        <ion-select formControlName="certificate_type" cancelText="{{ 'app.abort' | translate }}" okText="OK">
          <ion-select-option *ngFor="let covidCertificateType of covidCertificateTypes"
                             [value]="covidCertificateType.value">{{ covidCertificateType.name }}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item class="input-item">
        <ion-label position="floating">{{ 'my_members.valid_until' | translate }}</ion-label>
        <ion-input mbsc-calendar [mbsc-options]="datePickerSettings" formControlName="valid_until" required></ion-input>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-row class="form-actions-wrapper">
    <ion-col>
      <ion-button class="submit-btn" expand="block" color="secondary" fill="solid"
                  [disabled]="!covidCertificateForm.valid"
                  (click)="addCovidCertificate()">{{ 'app.save' | translate }}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
