<form [formGroup]="personalInformationForm">
  <ion-list class="inputs-list" lines="full">
    <ion-item class="input-item" [hidden]="!showLanguage">
      <ion-label color="secondary" position="floating">{{ 'profile.language' | translate }} *</ion-label>
      <ion-select formControlName="language" cancelText="{{ 'app.abort' | translate }}" okText="OK">
        <ion-select-option *ngFor="let language of languages" [value]="language.value">{{ language.name }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.salutation' | translate }} *</ion-label>
      <ion-select formControlName="gender" cancelText="{{ 'app.abort' | translate }}" okText="OK">
        <ion-select-option *ngFor="let gender of genders" [value]="gender.value">{{ gender.name }}</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.company' | translate }}</ion-label>
      <ion-input type="text" formControlName="company" required></ion-input>
    </ion-item>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'auth.first_name' | translate }} *</ion-label>
      <ion-input type="text" formControlName="first_name" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('first_name').hasError('required') && (personalInformationForm.get('first_name').dirty || personalInformationForm.get('first_name').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'auth.last_name' | translate }} *</ion-label>
      <ion-input type="text" formControlName="last_name" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('last_name').hasError('required') && (personalInformationForm.get('last_name').dirty || personalInformationForm.get('last_name').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.address' | translate }} *</ion-label>
      <ion-input type="text" formControlName="address" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('address').hasError('required') && (personalInformationForm.get('address').dirty || personalInformationForm.get('address').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.zip_code' | translate }} *</ion-label>
      <ion-input type="text" formControlName="zip_code" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('zip_code').hasError('required') && (personalInformationForm.get('zip_code').dirty || personalInformationForm.get('zip_code').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.city' | translate }} *</ion-label>
      <ion-input type="text" formControlName="city" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('city').hasError('required') && (personalInformationForm.get('city').dirty || personalInformationForm.get('city').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.country' | translate }} *</ion-label>
      <ion-select formControlName="country" cancelText="{{ 'app.abort' | translate }}" okText="OK">
        <ion-select-option *ngFor="let country of countries" [value]="country.code">{{ 'countries.' + country.code | translate }}</ion-select-option>
      </ion-select>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('country').hasError('required') && (personalInformationForm.get('country').dirty || personalInformationForm.get('country').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.mobile' | translate }} *</ion-label>
      <ion-input type="tel" formControlName="phone_mobile" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('phone_mobile').hasError('required') && (personalInformationForm.get('phone_mobile').dirty || personalInformationForm.get('phone_mobile').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.private_phone' | translate }}</ion-label>
      <ion-input type="tel" formControlName="phone_private" required></ion-input>
    </ion-item>
    <ion-item class="input-item">
      <ion-label color="secondary" position="floating">{{ 'profile.work_phone' | translate }}</ion-label>
      <ion-input type="tel" formControlName="phone_work" required></ion-input>
    </ion-item>
    <ion-item>
      <ion-label color="secondary" position="floating">{{ 'profile.birthday' | translate }} *</ion-label>
      <ion-input mbsc-calendar [mbsc-options]="datePickerSettings" formControlName="birthday" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('birthday').hasError('required') && (personalInformationForm.get('birthday').dirty || personalInformationForm.get('birthday').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item" [hidden]="!showSocialSecurityNumber">
      <ion-label color="secondary" position="floating">{{ 'my_members.social_security_number' | translate }}</ion-label>
      <ion-input type="text" formControlName="social_security_number"></ion-input>
    </ion-item>
    <ion-item class="input-item" [hidden]="!showInsurance">
      <ion-label color="secondary" position="floating">{{ 'my_members.insurance' | translate }}</ion-label>
      <ion-input type="text" formControlName="insurance"></ion-input>
    </ion-item>
    <ion-item class="input-item separate-billing-address-item">
      <ion-label color="secondary">{{ 'profile.separate_billing_address' | translate }}</ion-label>
      <ion-toggle color="secondary" formControlName="separate_billing_address"></ion-toggle>
    </ion-item>
    <ion-item class="input-item" *ngIf="personalInformationForm.controls['separate_billing_address'].value">
      <ion-label color="secondary" position="floating">{{ 'profile.company' | translate }}</ion-label>
      <ion-input type="text" formControlName="billing_company" required></ion-input>
    </ion-item>
    <ion-item class="input-item" *ngIf="personalInformationForm.controls['separate_billing_address'].value">
      <ion-label color="secondary" position="floating">{{ 'auth.first_name' | translate }} *</ion-label>
      <ion-input type="text" formControlName="billing_first_name" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.controls['separate_billing_address'].value && personalInformationForm.get('billing_first_name').hasError('required') && (personalInformationForm.get('billing_first_name').dirty || personalInformationForm.get('billing_first_name').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item" *ngIf="personalInformationForm.controls['separate_billing_address'].value">
      <ion-label color="secondary" position="floating">{{ 'auth.last_name' | translate }} *</ion-label>
      <ion-input type="text" formControlName="billing_last_name" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.controls['separate_billing_address'].value && personalInformationForm.get('billing_last_name').hasError('required') && (personalInformationForm.get('billing_last_name').dirty || personalInformationForm.get('billing_last_name').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item" *ngIf="personalInformationForm.controls['separate_billing_address'].value">
      <ion-label color="secondary" position="floating">{{ 'profile.address' | translate }} *</ion-label>
      <ion-input type="text" formControlName="billing_address" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.controls['separate_billing_address'].value && personalInformationForm.get('billing_address').hasError('required') && (personalInformationForm.get('billing_address').dirty || personalInformationForm.get('billing_address').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item" *ngIf="personalInformationForm.controls['separate_billing_address'].value">
      <ion-label color="secondary" position="floating">{{ 'profile.zip_code' | translate }} *</ion-label>
      <ion-input type="text" formControlName="billing_zip_code" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.controls['separate_billing_address'].value && personalInformationForm.get('billing_zip_code').hasError('required') && (personalInformationForm.get('billing_zip_code').dirty || personalInformationForm.get('billing_zip_code').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item" *ngIf="personalInformationForm.controls['separate_billing_address'].value">
      <ion-label color="secondary" position="floating">{{ 'profile.city' | translate }} *</ion-label>
      <ion-input type="text" formControlName="billing_city" required></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.controls['separate_billing_address'].value && personalInformationForm.get('billing_city').hasError('required') && (personalInformationForm.get('billing_city').dirty || personalInformationForm.get('billing_city').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item" *ngIf="personalInformationForm.controls['separate_billing_address'].value">
      <ion-label color="secondary" position="floating">{{ 'profile.country' | translate }} *</ion-label>
      <ion-select formControlName="billing_country" cancelText="{{ 'app.abort' | translate }}" okText="OK">
        <ion-select-option *ngFor="let country of countries" [value]="country.code">{{ 'countries.' + country.code | translate }}</ion-select-option>
      </ion-select>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.controls['separate_billing_address'].value && personalInformationForm.get('billing_country').hasError('required') && (personalInformationForm.get('billing_country').dirty || personalInformationForm.get('billing_country').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'app.field_must_be_filled' | translate }}</span>
      </div>
    </div>
    <ion-item class="input-item" [hidden]="!showEmail">
      <ion-label color="secondary" position="floating">{{ 'auth.email' | translate }}</ion-label>
      <ion-input type="email" formControlName="email" clearInput autocapitalize="off" inputmode="email"></ion-input>
    </ion-item>
    <div class="error-container">
      <div class="error-message" *ngIf="personalInformationForm.get('email').hasError('pattern') && (personalInformationForm.get('email').dirty || personalInformationForm.get('email').touched)">
        <ion-icon name="information-circle-outline"></ion-icon>
        <span>{{ 'auth.validate_email' | translate }}</span>
      </div>
    </div>
  </ion-list>
</form>
