import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { HealthQuestionnaireModel } from '../health-questionnaire/health-questionnaire.model';
import { HealthQuestionnaireComponent } from '../health-questionnaire/health-questionnaire.component';
import { MemberHealthQuestionnaireModel } from '../../models/member-health-questionnaire.model';

@Component({
  selector: 'app-edit-health-questionnaires-card',
  templateUrl: './edit-health-questionnaires-card.component.html',
  styleUrls: ['./edit-health-questionnaires-card.component.scss'],
})
export class EditHealthQuestionnairesCardComponent implements OnInit {
  @Input() healthQuestionnaires: Array<HealthQuestionnaireModel>;
  @Input() externalHideCondition: boolean;

  // Tracks which health questionnaires are (already) completed.
  completedHealthQuestionnaires: Set<number> = new Set<number>();
  // Toggle that is set after all health questionnaires have been completed.
  allHealthQuestionnairesCompleted = false;

  // Timestamp in milliseconds that is set after the last health questionnaire has been filled out.
  lastHealthQuestionnaireChange: number;
  private healthQuestionnaireChangeCheckerId: any;
  // Time in milliseconds that has to be elapsed after the last edit.
  private healthQuestionnaireEditThresholdTime = 3000;

  @ViewChild('healthQuestionnaireCard') healthQuestionnaireCard: any;
  @ViewChildren('healthQuestionnaireComponents') healthQuestionnaireComponents: QueryList<HealthQuestionnaireComponent>;

  constructor() { }

  ngOnInit() {
    // Register a callback that checks if the last change to any completed health questionnaire is older than the
    // specified threshold. If the last change is older than healthQuestionnaireEditThresholdTime, the mobiscroll card
    // will be collapsed/hidden. This is done to prevent immediate collapse, if all health questionnaires have been
    // filled out but the user still wants to edit them.
    // Assume the last question of the last uncompleted questionnaire is a multiple choice question with multiple answers,
    // and the user wants to select multiple answers. If they select the first answer allHealthQuestionnairesCompleted
    // will be set to true. If this.hide() is called immediately after that, the whole card would be collapsed while the
    // user still is not finished with filling it out. To solve this, register a callback that closes the card only after
    // all health questionnaires have been filled out and a certain time period has elapsed.
    this.healthQuestionnaireChangeCheckerId = setInterval(() => {
      this.hideCardIfLastChangeIsOlderThanThreshold();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.healthQuestionnaireChangeCheckerId) {
      clearInterval(this.healthQuestionnaireChangeCheckerId);
    }
  }

  ngAfterViewInit() {
    this.healthQuestionnaireComponents.filter((healthQuestionnaireComponent) => healthQuestionnaireComponent.isValid())
      .map((healthQuestionnaireComponent) => healthQuestionnaireComponent.healthQuestionnaire.providerHealthQuestionnaire.id)
      .forEach((providerHealthQuestionnaireId) => this.completedHealthQuestionnaires.add(providerHealthQuestionnaireId));

    this.allHealthQuestionnairesCompleted = this.healthQuestionnaires == undefined || this.healthQuestionnaires.length === 0 || this.completedHealthQuestionnaires.size === this.healthQuestionnaires.length;

    if (this.healthQuestionnaireChangeCheckerId && this.allHealthQuestionnairesCompleted) {
      clearInterval(this.healthQuestionnaireChangeCheckerId);
    }
  }

  show() {
    this.healthQuestionnaireCard.instance.show();
  }

  hide() {
    this.healthQuestionnaireCard.instance.hide();
  }

  onHealthQuestionnaireComplete(providerHealthQuestionnaireId: number) {
    if (this.completedHealthQuestionnaires.has(providerHealthQuestionnaireId) && this.completedHealthQuestionnaires.size === this.healthQuestionnaires.length) {
      this.allHealthQuestionnairesCompleted = true;
      this.lastHealthQuestionnaireChange = Date.now();
    }
    if (!this.completedHealthQuestionnaires.has(providerHealthQuestionnaireId)) {
      this.completedHealthQuestionnaires.add(providerHealthQuestionnaireId);
    }
  }

  areAllHealthQuestionnairesValid() {
    for (let healthQuestionnaireComponent of this.healthQuestionnaireComponents) {
      if (!healthQuestionnaireComponent.isValid()) {
        return false;
      }
    }

    return true;
  }

  // Checks if all health questionnaires can be used for booking form. If not all health questionnaires can be used, the card will not be displayed.
  areAllHealthQuestionnairesUsable() {
    if (this.healthQuestionnaires != undefined && this.healthQuestionnaires.length > 0) {
      for (let healthQuestionnaire of this.healthQuestionnaires) {
        if (healthQuestionnaire.providerLanguage == undefined || healthQuestionnaire.providerHealthQuestionnaire.healthQuestions == undefined || healthQuestionnaire.providerHealthQuestionnaire.healthQuestions.length <= 0) {
          return false;
        }
      }
    }

    return true;
  }

  getAnsweredHealthQuestionnaires(): Array<MemberHealthQuestionnaireModel> {
    return this.healthQuestionnaireComponents.map((healthQuestionnaireComponent) => healthQuestionnaireComponent.getAnsweredHealthQuestionnaire());
  }

  hideCardIfLastChangeIsOlderThanThreshold() {
    if (this.allHealthQuestionnairesCompleted && Date.now() >= this.lastHealthQuestionnaireChange + this.healthQuestionnaireEditThresholdTime) {
      this.hide();

      // Deregister the callback to prevent periodic collapsing of card if the user opens the card after all health
      // questionnaires have been filled out.
      if (this.healthQuestionnaireChangeCheckerId) {
        clearInterval(this.healthQuestionnaireChangeCheckerId);
      }
    }
  }
}
