import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HealthQuestionnaireModel } from './health-questionnaire.model';
import { TranslateService } from '@ngx-translate/core';
import { QuestionType, translateMultilingualField } from '../../models/provider-health-questionnaire.model';
import { MemberHealthQuestionnaireModel } from '../../models/member-health-questionnaire.model';

@Component({
  selector: 'app-health-questionnaire',
  templateUrl: './health-questionnaire.component.html',
  styleUrls: ['./health-questionnaire.component.scss'],
})
export class HealthQuestionnaireComponent implements OnInit {
  @Input() healthQuestionnaire: HealthQuestionnaireModel;
  @Output() onComplete: EventEmitter<any> = new EventEmitter();

  currentLanguage: string;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;

    if (this.healthQuestionnaire.memberHealthQuestionnaire == undefined) {
      this.healthQuestionnaire.memberHealthQuestionnaire = new MemberHealthQuestionnaireModel();
      this.healthQuestionnaire.memberHealthQuestionnaire.providerHealthQuestionnaireId = this.healthQuestionnaire.providerHealthQuestionnaire.id;
    }

    if (this.healthQuestionnaire.memberHealthQuestionnaire.answers == undefined) {
      this.healthQuestionnaire.memberHealthQuestionnaire.answers = new Map<string, Array<string>>();

      this.healthQuestionnaire.providerHealthQuestionnaire.healthQuestions.forEach((_, questionIndex) => {
        this.healthQuestionnaire.memberHealthQuestionnaire.answers.set(questionIndex.toString(), ['']);
      });
    }
  }

  ngOnChanges() {
    this.currentLanguage = this.translate.currentLang
  }

  protected readonly QuestionType = QuestionType;
  protected readonly translateMultilingualField = translateMultilingualField;

  isValid() {
    if (this.healthQuestionnaire == undefined || this.healthQuestionnaire.memberHealthQuestionnaire == undefined || this.healthQuestionnaire.memberHealthQuestionnaire.answers == undefined) {
      return false;
    }

    for (let key in this.healthQuestionnaire.memberHealthQuestionnaire.answers) {
      let values = this.healthQuestionnaire.memberHealthQuestionnaire.answers[key];

      if (values.filter((value) => value !== '').length <= 0) {
        return false;
      }
    }

    return true;
  }

  answerChanged() {
    if (this.isValid()) {
      this.onComplete.emit();
    }
  }

  getAnsweredHealthQuestionnaire(): MemberHealthQuestionnaireModel {
    if (!this.isValid()) {
      return undefined;
    }

    let answersAsStringArrayMap: Map<string, Array<string>> = new Map<string, Array<string>>();
    // Since the input elements not necessarily respect the [String] type of the answers enforce it here.
    // The textarea and radio-buttons (in case of the multiple choice questions) can convert the value to either a
    // String or list of numbers. This would result in a malformed JSON in the database and will be rejected by the
    // save_health_questionnaire endpoint.
    for (let key in this.healthQuestionnaire.memberHealthQuestionnaire.answers) {
      let value = this.healthQuestionnaire.memberHealthQuestionnaire.answers[key];

      if (Array.isArray(value)) {
        answersAsStringArrayMap[key] = value.map(String);
      }
      else {
        answersAsStringArrayMap[key] = [String(value)];
      }
    }

    this.healthQuestionnaire.memberHealthQuestionnaire.answers = answersAsStringArrayMap;

    return this.healthQuestionnaire.memberHealthQuestionnaire;
  }

  isChecked(questionIndex: number, answerIndex: number) {
    const answers = this.healthQuestionnaire.memberHealthQuestionnaire.answers[questionIndex.toString()];

    return answers ? answers.includes(answerIndex.toString()) : false;
  }

  onCheckboxChange(event: any, questionIndex: number, answerIndex: number) {
    let answers = this.healthQuestionnaire.memberHealthQuestionnaire.answers[questionIndex.toString()] || [];

    if (event.detail.checked) {
      if (!answers.includes(answerIndex.toString())) {
        answers.push(answerIndex.toString());
      }
    }
    else {
      answers = answers.filter(index => index !== answerIndex.toString());
    }

    this.healthQuestionnaire.memberHealthQuestionnaire.answers[questionIndex.toString()] = answers;

    this.answerChanged();
  }

  getAnswer(questionIndex: number) {
    if (this.healthQuestionnaire.memberHealthQuestionnaire.answers[questionIndex.toString()] == undefined) {
      this.healthQuestionnaire.memberHealthQuestionnaire.answers[questionIndex.toString()] = [""];
    }

    return this.healthQuestionnaire.memberHealthQuestionnaire.answers[questionIndex.toString()];
  }
}
