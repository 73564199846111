import { ShellModel } from '../../shell/data-store';

export class NotificationSettingModel {
  key: string;
  name: string;
  value: boolean
}

export class NotificationsModel extends ShellModel {
  notifications: Array<{category: string, settings: Array<NotificationSettingModel>}> = [];

  constructor() {
    super();
  }
}
