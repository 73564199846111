import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../shell/shell.module';

import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { PersonalInformationFormComponent } from './personal-information-form/personal-information-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import { SuccessPopupComponent } from './success-popup/success-popup.component';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { WarningPopupComponent } from './warning-popup/warning-popup.component';
import { HealthQuestionnaireComponent } from './health-questionnaire/health-questionnaire.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { TwintPaymentComponent } from './twint-payment/twint-payment.component';
import { InvalidInputPopupComponent } from './invalid-input-popup/invalid-input-popup.component';
import { FullyBookedPopupComponent } from './fully-booked-popup/fully-booked-popup.component';
import { MbscCalendarModule, MbscCardModule, MbscFormsModule, MbscPopupModule } from '@mobiscroll/angular';
import {
  PushNotificationsDeactivatedComponent
} from './push-notifications-deactivated/push-notifications-deactivated.component';
import { ConnectionProblemComponent } from './connection-problem/connection-problem.component';
import { MemberTicketCardContentComponent } from './member-ticket-card-content/member-ticket-card-content.component';
import { WatchListItemComponent } from './watch-list-item/watch-list-item.component';
import { ServiceSessionStatusComponent } from './service-session-status/service-session-status.component';
import { AlreadyBookedPopupComponent } from './already-booked-popup/already-booked-popup.component';
import { RouterModule } from '@angular/router';
import { NoBookingRequiredPopupComponent } from './no-booking-required-popup/no-booking-required-popup.component';
import { RateUsHomeCardComponent } from './rate-us-home-card/rate-us-home-card.component';
import { RateUsPopupComponent } from './rate-us-popup/rate-us-popup.component';
import { OnTheWaitingListPopupComponent } from "./on-the-waiting-list-popup/on-the-waiting-list-popup.component";
import { HealthQuestionnaireInfoComponent } from './health-questionnaire-info/health-questionnaire-info.component';
import {
  SelectHealthQuestionnaireComponent
} from './select-health-questionnaire/select-health-questionnaire.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ShellModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    MbscPopupModule,
    MbscCalendarModule,
    MbscCardModule,
    RouterModule,
    MbscFormsModule
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    PersonalInformationFormComponent,
    InfoPopupComponent,
    ErrorPopupComponent,
    WarningPopupComponent,
    FullyBookedPopupComponent,
    SuccessPopupComponent,
    HealthQuestionnaireComponent,
    StripePaymentComponent,
    TwintPaymentComponent,
    InvalidInputPopupComponent,
    PushNotificationsDeactivatedComponent,
    ConnectionProblemComponent,
    MemberTicketCardContentComponent,
    WatchListItemComponent,
    ServiceSessionStatusComponent,
    AlreadyBookedPopupComponent,
    OnTheWaitingListPopupComponent,
    NoBookingRequiredPopupComponent,
    RateUsHomeCardComponent,
    RateUsPopupComponent,
    HealthQuestionnaireInfoComponent,
    SelectHealthQuestionnaireComponent
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    PersonalInformationFormComponent,
    InfoPopupComponent,
    ErrorPopupComponent,
    WarningPopupComponent,
    FullyBookedPopupComponent,
    SuccessPopupComponent,
    HealthQuestionnaireComponent,
    StripePaymentComponent,
    TwintPaymentComponent,
    InvalidInputPopupComponent,
    PushNotificationsDeactivatedComponent,
    ConnectionProblemComponent,
    MemberTicketCardContentComponent,
    WatchListItemComponent,
    ServiceSessionStatusComponent,
    AlreadyBookedPopupComponent,
    OnTheWaitingListPopupComponent,
    NoBookingRequiredPopupComponent,
    RateUsHomeCardComponent,
    RateUsPopupComponent,
    HealthQuestionnaireInfoComponent,
    SelectHealthQuestionnaireComponent
  ]
})
export class ComponentsModule {}
