import { Injectable } from '@angular/core';
import { ServiceSessionModel } from '../../../models/service-session.model';
import { BookingModal } from './booking.modal';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ScheduleService } from '../schedule.service';
import { BookingInformationModel } from './booking-information.model';
import { TranslateService } from '@ngx-translate/core';
import { DisclaimerModal } from '../disclaimer/disclaimer.modal';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  bookingInformationModel: BookingInformationModel;
  resolve: any;

  constructor(
    private loadingController: LoadingController,
    private scheduleService: ScheduleService,
    private alertController: AlertController,
    private modalController: ModalController,
    private translate: TranslateService
  ) { }

  openBookingModal(serviceSession: ServiceSessionModel) {
    const that = this;
    return new Promise(async function (resolve) {
      that.resolve = resolve;

      const loading = that.loadingController.create({
        cssClass: 'custom-loading',
        spinner: 'crescent'
      });
      (await loading).present();
      that.scheduleService.getBookingInformationDataSource(serviceSession).subscribe({
          async next(bookingInformationModel) {
            // Remember already entered and validated access code.
            const accessCode = that.bookingInformationModel != undefined && that.bookingInformationModel.accessCode != undefined ? that.bookingInformationModel.accessCode : undefined;
            const hasAcceptedDisclaimer = that.bookingInformationModel != undefined && that.bookingInformationModel.hasAcceptedDisclaimer;
            that.bookingInformationModel = bookingInformationModel;
            that.bookingInformationModel.accessCode = accessCode;
            that.bookingInformationModel.hasAcceptedDisclaimer = hasAcceptedDisclaimer;

            (await loading).dismiss();
            if (bookingInformationModel.isRestricted) {
              await that.presentRestrictedAlert();
              return;
            }

            if (bookingInformationModel.hasDisclaimer && !hasAcceptedDisclaimer) {
              await that.presentDisclaimerModal(serviceSession);
              return;
            }

            if (bookingInformationModel.isAccessCodeRequired && that.bookingInformationModel.accessCode == undefined) {
              await that.presentAccessCodeAlert(serviceSession);
              return;
            }

            if (bookingInformationModel.showMinimumRequirements) {
              await that.presentMinimumRequirementsAlert();
              return;
            }

            await that.presentBookingModal();
          },
          async error(message) {
            (await loading).dismiss();
            console.log(message);
          }
        }
      );
    });
  }

  async presentMinimumRequirementsAlert() {
      const alert = await this.alertController.create({
        header: this.translate.instant('booking.class_requires_experience'),
        subHeader: this.bookingInformationModel.serviceSession.name,
        message: this.translate.instant('booking.required_experience_instructions'),
        buttons: [
          {
            text: this.translate.instant('app.abort'),
            role: 'cancel'
          },
          {
            text: this.translate.instant('booking.please_contact_me'),
            handler: () => {
              console.log('Please contact me');
              this.presentContactMeAlert();
            }
          },
          {
            text: this.translate.instant('booking.book_now'),
            role: 'destructive',
            handler: () => {
              this.presentBookingModal();
            }
          }
        ]
      });
      await alert.present();
  }

  async presentContactMeAlert() {
    const contactMeAlert = await this.alertController.create({
      header: this.translate.instant('booking.please_contact_me'),
      subHeader: this.bookingInformationModel.customer.name + ', ' + this.bookingInformationModel.customer.email,
      inputs: [
        {
          name: 'phone_number',
          type: 'tel',
          value: this.bookingInformationModel.customer.phoneMobile,
          placeholder: this.translate.instant('profile.phone_number')
        }
      ],
      buttons: [
        {
          text: this.translate.instant('app.abort'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('app.confirm'),
          role: 'destructive',
          handler: async (data) => {
            console.log('Phone number: ' + data.phone_number);
            await this.contactMe(data.phone_number);
          }
        }
      ]
    });
    await contactMeAlert.present();
  }

  async contactMe(phoneNumber: string) {
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.scheduleService.contactMe(this.bookingInformationModel.provider.id, this.bookingInformationModel.serviceSession.id, phoneNumber).subscribe({
        async next() {
          (await loading).dismiss();
          const alert = await that.alertController.create({
            header: that.translate.instant('app.alright'),
            message: that.translate.instant('booking.request_sent'),
            buttons: ['OK']
          });

          await alert.present();
        },
        async error() {
          (await loading).dismiss();
        }
      }
    );
  }

  async presentAccessCodeAlert(serviceSession: ServiceSessionModel) {
    const that = this;
    const alert = await this.alertController.create({
      header: this.translate.instant('booking.access_code_required'),
      message: this.translate.instant('booking.access_code_instructions'),
      inputs: [{
        name: 'access_code',
        type: 'text',
        placeholder: this.translate.instant('booking.access_code')
      }],
      buttons: [
        {
          text: this.translate.instant('app.abort'),
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: this.translate.instant('app.confirm'),
          role: 'destructive',
          cssClass: 'confirm-cancel-button',
          handler: async (data) => {
            console.log(data);
            if (data.access_code == undefined || data.access_code === '') {
              that.presentInvalidAccessCodeAlert();
              return;
            }

            const loading = that.loadingController.create({
              cssClass: 'custom-loading',
              spinner: 'crescent'
            });
            (await loading).present();
            that.scheduleService.validateAccessCode(this.bookingInformationModel.provider.id, data.access_code).subscribe({
                async next(response) {
                  (await loading).dismiss();
                  if (response.isAccessCodeValid) {
                    that.bookingInformationModel.accessCode = data.access_code;
                    await that.openBookingModal(serviceSession);
                  }
                  else {
                    that.presentInvalidAccessCodeAlert();
                  }
                },
                async error(message) {
                  (await loading).dismiss();
                  console.log(message);
                }
              }
            );
          }
        }
      ]
    });
    await alert.present();
  }

  async presentInvalidAccessCodeAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('booking.invalid_access_code'),
      message: this.translate.instant('booking.invalid_access_code_instructions'),
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentRestrictedAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('booking.not_possible'),
      message: this.translate.instant('booking.restricted_for_groups'),
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentDisclaimerModal(serviceSession: ServiceSessionModel) {
    const modal = await this.modalController.create({
      component: DisclaimerModal,
      componentProps: {
        'disclaimer': this.bookingInformationModel.disclaimer
      }
    });

    modal.onDidDismiss().then(data => {
      if (data.data != undefined) {
        this.bookingInformationModel.hasAcceptedDisclaimer = true;
        this.openBookingModal(serviceSession);
      }
    });

    await modal.present();
  }

  async presentBookingModal() {
    const modal = await this.modalController.create({
      component: BookingModal,
      componentProps: {
        'model': this.bookingInformationModel
      }
    });

    modal.onDidDismiss().then(() => {
      this.resolve();
    });

    await modal.present();
  }
}
