import { ShellModel } from '../../../shell/data-store';
import { PersonalInformationModel } from '../../../components/personal-information-form/personal-information.model';
import { MemberTicketModel } from '../../../models/member-ticket.model';
import { TicketModel } from '../../../models/ticket.model';
import { PaymentMethodModel } from '../../../models/payment-method.model';
import { ProviderModel } from '../../../models/provider.model';
import { ServiceSessionModel } from '../../../models/service-session.model';
import { HealthQuestionnaireModel } from '../../../components/health-questionnaire/health-questionnaire.model';
import { StaffModel } from '../../../models/staff.model';
import { CustomerModel } from '../../../models/customer.model';

export class BookingInformationModel extends ShellModel {
  customer: CustomerModel;
  serviceSession: ServiceSessionModel;
  staff: StaffModel;
  provider: ProviderModel;
  memberTickets = Array<MemberTicketModel>();
  outOfRangeMemberTickets = Array<MemberTicketModel>();
  tickets = Array<TicketModel>();
  paymentMethods = Array<PaymentMethodModel>();
  healthQuestionnaires: Array<HealthQuestionnaireModel>;
  personalInformation: PersonalInformationModel;
  isCouponActive: boolean;
  moreBookableDates: Array<{ memberTicketId: number, credits: number, bookableDates: Array<ServiceSessionModel> }>
  isAccessCodeRequired: boolean;
  accessCode: string;
  isRestricted: boolean;
  hasDisclaimer: boolean;
  hasAcceptedDisclaimer: boolean;
  disclaimer: string;
  showMinimumRequirements: boolean;

  constructor() {
    super();
  }
}
