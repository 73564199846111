import { ShellModel } from '../../shell/data-store';
import { FileModel } from '../../models/file.model';

export class MyFilesModel extends ShellModel {
  files: Array<FileModel>;

  constructor() {
    super();
  }
}
