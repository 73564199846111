import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { TwintService } from './twint.service';
import { LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import 'twint-plugin';
import { EventsService } from '../../utils/events.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Twint } from 'twint-plugin';
import { Haptics } from '@capacitor/haptics';

@Component({
  selector: 'app-twint-payment',
  templateUrl: './twint-payment.component.html',
  styleUrls: ['./twint-payment.component.scss'],
})
export class TwintPaymentComponent implements OnInit {
  @Input() ticketId: number;
  @Input() couponId: number;
  @Input() invoiceId: number;
  @Input() providerId: string;
  @Input() amount: number;
  @Input() serviceSessionId: number;
  @Input() bookingDate: string;

  token: string;
  pairingUuid: string;
  orderUuid: string;
  hasPaymentFailed: boolean;
  isConfirmationInProgress: boolean;
  instructions: string;
  twintAppConfigurations: Array<TwintAppConfiguration>;
  subscription: Subscription; // Time subscription used to check the payment status every 10 second.
  qrCodeSrc: SafeUrl;

  settings: MbscPopupOptions = {
    display: 'center',
    closeOnOverlayTap: false,
    buttons: []
  };

  qrCodeSettings: MbscPopupOptions = {
    display: 'center',
    closeOnOverlayTap: false,
    buttons: [],
    onClose: () => {
      this.reject();
    }
  };

  // @ts-ignore
  @ViewChild('popup')
  popup: any;

  // @ts-ignore
  @ViewChild('qrCodePopup')
  qrCodePopup: any;

  resolve: any;
  reject: any;

  constructor(
    private twintService: TwintService,
    private loadingController: LoadingController,
    private translate: TranslateService,
    private eventsService: EventsService,
    private platform: Platform,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {}

  start() {
    this.checkout();
    const that = this;
    return new Promise(function (resolve) {
      that.resolve = resolve;
    });
  }

  resume() {
    const that = this;
    that.isConfirmationInProgress = false;
    that.popup.instance.show();
    return new Promise(function (resolve) {
      that.resolve = resolve;
    });
  }

  async checkout() {
    this.hasPaymentFailed = false;
    const that = this;
    that.popup.instance.hide();
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.twintService.createTwintCheckout(this.ticketId, this.couponId, this.invoiceId, this.amount, this.providerId, this.serviceSessionId, this.bookingDate).subscribe({
      async next(data) {
        (await loading).dismiss();
        that.token = data.token;
        that.pairingUuid = data.pairingUuid;
        that.instructions = that.translate.instant('components.twint_payment.confirm_payment');

        if (that.platform.is('android')) {
          // Add a dummy config by default to show the 'open Twint app' button.
          that.twintAppConfigurations = new Array<TwintAppConfiguration>();
          that.twintAppConfigurations.push({ appDisplayName: 'Dummy TWINT', appUrlScheme: 'Dummy scheme' });

          that.payWithTwintApp(null);
        }
        else if (that.platform.is('ios')) {
          that.twintAppConfigurations = (await Twint.fetchInstalledAppConfigurations()).result
          if (that.twintAppConfigurations.length === 1) {
            that.payWithTwintApp(that.twintAppConfigurations[0]);
          }
        }
        else {
          that.twintAppConfigurations = (await Twint.fetchInstalledAppConfigurations()).result
        }

        that.popup.instance.show();

        // Check the payment status every 5 seconds.
        const source = interval(5000);
        that.subscription = source.subscribe(val => that.confirmCheckout());
      },
      async error(message) {
        (await loading).dismiss();
        that.hasPaymentFailed = true;
      }
    });
  }

  startQrCode() {
    this.checkoutWithQrCode();
    const that = this;
    return new Promise(function (resolve, reject) {
      that.resolve = resolve;
      that.reject = reject;
    });
  }

  async checkoutWithQrCode() {
    this.hasPaymentFailed = false;
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.twintService.createTwintCheckout(this.ticketId, this.couponId, this.invoiceId, this.amount, this.providerId, null, null).subscribe({
      async next(data) {
        (await loading).dismiss();
        that.token = data.token;
        that.pairingUuid = data.pairingUuid;
        that.qrCodeSrc = that.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + data.qrCode);
        that.qrCodePopup.instance.show();

        // Check the payment status every 5 seconds.
        const source = interval(5000);
        that.subscription = source.subscribe(val => that.confirmCheckout());
      },
      async error(message) {
        (await loading).dismiss();
        that.hasPaymentFailed = true;
      }
    });
  }

  async payWithTwintApp(appConfiguration: TwintAppConfiguration) {
    let status = (await Twint.payWithCode({ code: this.token, appConfiguration: appConfiguration })).result;
    console.log('payWithTwintApp.status:' + status);
    switch (status) {
      case 'TW_B_SUCCESS':
        this.isConfirmationInProgress = true;

        // Workaround to prevent TWINT order confirmation failed.
        const that = this;
        setTimeout(function () {
          that.confirmCheckout();
        }, 1500);
        break;
      case 'TW_B_ERROR':
        if (!this.isConfirmationInProgress) {
          this.hasPaymentFailed = true;
          this.instructions = this.translate.instant('components.twint_payment.payment_failed');
          this.subscription.unsubscribe();
        }
        break;
      case 'TW_B_APP_NOT_INSTALLED':
        // Clear the list of Twint app configurations (from Android dummies)
        this.twintAppConfigurations = new Array<TwintAppConfiguration>();
        break;
      default:
      // Failed opening TWINT app. Do nothing and let user open the app manually.
    }
  }

  async confirmCheckout() {
    const that = this;
    this.twintService.confirmTwintCheckout(this.pairingUuid, this.invoiceId).subscribe({
      async next(data) {
        console.log('confirmTwintCheckout data.status: ', data.status);

        switch (data.status) {
          case 'success':
            that.eventsService.publish('memberTicketsChanged', {});
            that.orderUuid = data.orderUuid;
            that.resolve();
            that.popup.instance.hide();
            that.qrCodePopup.instance.hide();
            that.subscription.unsubscribe();
            Haptics.vibrate();
            break;
          case 'error':
            that.isConfirmationInProgress = false;
            that.hasPaymentFailed = true;
            that.subscription.unsubscribe();
            that.instructions = that.translate.instant('components.twint_payment.payment_failed');
            break;
          default:
            that.isConfirmationInProgress = false;
          // Waiting for user to confirm the payment.
        }
      },
      async error(message) {
        // Ignore unknown error 0 due to http failures during app switch.
        if (message != undefined && message.status !== 0) {
          console.log('confirmCheckout() failed with message:', message);
          that.isConfirmationInProgress = false;
        }
      }
    });
  }

  cancel() {
    this.twintService.cancelTwintCheckout(this.pairingUuid, this.ticketId, this.invoiceId, this.amount, this.providerId).subscribe({
      async next() {},
      async error() {}
    });
    this.subscription.unsubscribe();
    this.popup.instance.hide();
    this.qrCodePopup.instance.hide();
  }
}

export class TwintAppConfiguration {
  appDisplayName: string;
  appUrlScheme: string
}
