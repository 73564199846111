<ion-toolbar class="filters-toolbar">
  <ion-row class="searchbar-row" align-items-center>
    <ion-col>
      <ion-searchbar class="items-searchbar" animated [(ngModel)]="searchQuery"
                     (ionChange)="searchHealthQuestionnaire()"
                     placeholder="{{ 'app.search_health_questionnaire' | translate }}"></ion-searchbar>
    </ion-col>
  </ion-row>
</ion-toolbar>

<div class="select-health-questionnaire-content">
  <ion-row
    *ngIf="filteredShortProviderHealthQuestionnaires == undefined || filteredShortProviderHealthQuestionnaires.length == 0">
    <ion-col class="ion-text-center">
      <h5>{{ 'app.no_health_questionnaires_found' | translate }}</h5>
      <p>{{ 'app.not_found_instructions' | translate }}</p>
    </ion-col>
  </ion-row>
  <ion-list class="items-list"
            *ngIf="filteredShortProviderHealthQuestionnaires != undefined && filteredShortProviderHealthQuestionnaires.length > 0">
    <ion-item class="list-item" *ngFor="let providerHealthQuestionnaire of filteredShortProviderHealthQuestionnaires"
              (click)="presentModal(providerHealthQuestionnaire)">
      {{providerHealthQuestionnaire.providerHealthQuestionnaireName}}
      <ion-icon class="health-problem" name="warning-outline" slot="end"
                *ngIf="providerHealthQuestionnaire.hasHealthProblems && showIcons"></ion-icon>
      <ion-icon class="health-questionnaire-not-filled-out" name="help-outline" slot="end"
                *ngIf="providerHealthQuestionnaire.memberHealthQuestionnaireId == undefined && showIcons"></ion-icon>
    </ion-item>
  </ion-list>
</div>
