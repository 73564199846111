import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss'],
})
export class WarningPopupComponent implements OnInit {

  settings: MbscPopupOptions = {
    display: 'center'
  };

  // @ts-ignore
  @ViewChild('popup')
  popup: any;

  title: string;
  message: string;

  constructor() { }

  ngOnInit() {}

  show(title: string, message: string) {
    this.title = title;
    this.message = message;
    this.popup.instance.show();
  }
}
