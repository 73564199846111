<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'booking.disclaimer' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <p class="disclaimer">{{ disclaimer }}</p>
</ion-content>

<ion-footer>
  <ion-item lines="none" class="checkbox-item">
    <ion-label class="checkbox-label">{{ 'booking.read_accept_disclaimer' | translate }}</ion-label>
    <ion-checkbox [(ngModel)]="hasAccepted"></ion-checkbox>
  </ion-item>

  <ion-button class="submit-btn" expand="block" color="secondary" fill="solid" (click)="acceptDisclaimer()" [disabled]="!hasAccepted">{{ 'app.confirm' | translate }}</ion-button>
</ion-footer>