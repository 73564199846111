import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { FormControl, FormGroup } from '@angular/forms';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { BookingInformationModel } from './booking-information.model';
import { TranslateService } from '@ngx-translate/core';
import {
  PersonalInformationFormComponent
} from '../../../components/personal-information-form/personal-information-form.component';
import { ScheduleService } from '../schedule.service';
import { CouponModel } from '../../../models/coupon.model';
import { MemberTicketModel } from '../../../models/member-ticket.model';
import { TicketModel } from '../../../models/ticket.model';
import { PaymentMethodModel } from '../../../models/payment-method.model';
import { StripePaymentComponent } from '../../../components/stripe-payment/stripe-payment.component';
import { FullyBookedPopupComponent } from '../../../components/fully-booked-popup/fully-booked-popup.component';
import { BookingModel } from '../../../models/booking.model';
import { ProfileService } from '../../../profile/profile.service';
import { HealthQuestionnaireComponent } from '../../../components/health-questionnaire/health-questionnaire.component';
import { StripeService } from '../../../components/stripe-payment/stripe.service';
import { EventsService } from '../../../utils/events.service';
import { ServiceSessionModel } from '../../../models/service-session.model';
import { Haptics } from '@capacitor/haptics';
import { Keyboard } from '@ionic-native/keyboard';
import { RateUsService } from '../../../components/rate-us-home-card/rate-us.service';
import { RekaService } from '../../../components/reka-payment/reka.service';
import { QuestionType } from '../../../models/provider-health-questionnaire.model';
import {
  EditHealthQuestionnairesCardComponent
} from '../../../components/edit-health-questionnaires-card/edit-health-questionnaires-card.component';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.modal.html',
  styleUrls: ['./styles/booking.modal.scss']
})
export class BookingModal implements OnInit, AfterViewChecked {
  @Input() model: BookingInformationModel;
  confirmedBooking: BookingModel;
  showMoreDescription = false;

  // Popups
  // @ts-ignore
  @ViewChild('successPopup')
  successPopup: any;

  // @ts-ignore
  @ViewChild('limitReachedPopup')
  limitReachedPopup: any;

  successPopupSettings: MbscPopupOptions = {
    display: 'center',
    onClose: () => {
      Keyboard.hide();
      // Dismiss modal when the popup is being closed.
      this.dismissModal();
      this.eventsService.publish('bookingsChanged', {});
    }
  };

  limitReachedPopupSettings: MbscPopupOptions = {
    display: 'center',
    onClose: () => {
      Keyboard.hide();
      // Dismiss modal when the popup is being closed.
      this.dismissModal();
    }
  };


  // Components
  // @ts-ignore
  @ViewChild('infoPopupComponent')
  infoPopupComponent: any;


  // Popups
  // @ts-ignore
  @ViewChild('fullyBookedPopupComponent')
  fullyBookedPopupComponent: FullyBookedPopupComponent;

  // @ts-ignore
  @ViewChild('successPopupComponent')
  successPopupComponent: any;

  // @ts-ignore
  @ViewChild('personalInformationFormComponent')
  personalInformationFormComponent: PersonalInformationFormComponent;

  @ViewChild('editHealthQuestionnairesCardComponent')
  editHealthQuestionnairesCardComponent: EditHealthQuestionnairesCardComponent;

  // @ts-ignore
  @ViewChild('stripePaymentComponent')
  stripePaymentComponent: StripePaymentComponent;

  // @ts-ignore
  @ViewChild('twintPaymentComponent')
  twintPaymentComponent: any;

  // Cards
  // @ts-ignore
  @ViewChild('onSiteOrLivestreamCard')
  onSiteOrLivestreamCard: any;
  onSiteOrLivestreamCardTitle: string;

  // @ts-ignore
  @ViewChild('ticketCard')
  ticketCard: any;
  ticketCardTitle: string;

  // @ts-ignore
  @ViewChild('paymentMethodCard')
  paymentMethodCard: any;
  paymentMethodCardTitle: string;

  // @ts-ignore
  @ViewChild('creditCardInputCard')
  creditCardInputCard: any;

  // @ts-ignore
  @ViewChild('personalInformationCard')
  personalInformationCard: any;
  personalInformationCardTitle: string;
  isPersonalInformationComplete: boolean;

  // @ts-ignore
  @ViewChild('bookMoreDatesCard')
  bookMoreDatesCard: any;

  // @ts-ignore
  @ViewChild('couponCard')
  couponCard: any;
  couponCardTitle: string;

  // @ts-ignore
  @ViewChild('messageCard')
  messageCard: any;


  // Form groups
  onSiteOrLivestreamForm: FormGroup;
  memberTicketForm: FormGroup;
  ticketForm: FormGroup;
  paymentMethodsForm: FormGroup;
  couponForm: FormGroup;
  messageForm: FormGroup;
  bookMoreDatesForm: FormGroup;


  // States
  selectedMemberTicket: MemberTicketModel;
  selectedTicket: TicketModel;
  originallySelectedTicket: TicketModel; // Used for coupon handling and restoring.
  selectedPaymentMethod: PaymentMethodModel;
  activatedCoupon: CouponModel;
  moreBookableDates: Array<ServiceSessionModel>;
  selectedBookingDates: Array<string>;
  remainingCredits: number;
  isLivestreamBooking: boolean;
  stripePaymentIntentClientSecret: string;
  stripeCheckoutSuccessToken: string;
  isConfirmationInProgress: boolean;
  rekaOrderId: string;

  // Event handling
  // Used to prevent infinite event handling if a value has been changed inside a ionChange handler.
  isEventHandled: boolean;
  isCalendarSynchronisationActive: boolean;


  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private loadingController: LoadingController,
    public profileService: ProfileService,
    private alertController: AlertController,
    private changeDetector: ChangeDetectorRef,
    private stripeService: StripeService,
    private eventsService: EventsService,
    private scheduleService: ScheduleService,
    private rateUsService: RateUsService,
    private rekaService: RekaService,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.initOnSiteOrLivestreamFormGroup();
    this.initTicketFormGroup();
    this.initMemberTicketFormGroup();
    this.initPaymentMethodFormGroup();
    this.initPersonalInformationFormGroup();
    this.initCouponFormGroup();
    this.initMessageFormGroup();
    this.initBookMoreDatesFormGroup();

    const that = this;
    if (this.model.serviceSession.isLivestreamBookingEnabled) {
      this.isLivestreamBooking = undefined;
      setTimeout(function () {
        that.onSiteOrLivestreamCard.instance.show();
      }, 850);
    }
    else {
      if (this.model.serviceSession.isFreeOfCharge) {
        setTimeout(function () {
          that.personalInformationCard.instance.show();
        }, 850);
      }
      else {
        // Pre-select member ticket if there is only one.
        if (that.model.memberTickets.length === 1) {
          that.selectMemberTicket(that.model.memberTickets[0]);
        }
        else {
          setTimeout(function () {
            that.ticketCard.instance.show();
          }, 850);
        }
      }
    }
  }

  // Needs to be implemented to prevent 'ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.' caused by the healthQuestionnaireComponent.isValid() check in the HTML.
  // https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  initOnSiteOrLivestreamFormGroup() {
    if (this.model.serviceSession.isLivestreamBookingEnabled) {
      this.isLivestreamBooking = undefined;
    }
    else {
      this.isLivestreamBooking = false;
    }

    this.onSiteOrLivestreamForm = new FormGroup({
      on_site_or_livestream: new FormControl('')
    });

    this.onSiteOrLivestreamCardTitle = this.translate.instant('participants_lists.on_site_or_livestream');
  }

  initTicketFormGroup() {
    this.selectedTicket = undefined;

    this.ticketCardTitle = this.translate.instant('booking.choose_ticket');

    this.ticketForm = new FormGroup({
      selected_ticket: new FormControl('')
    });
  }

  initMemberTicketFormGroup() {
    this.selectedMemberTicket = undefined;

    this.ticketCardTitle = this.translate.instant('booking.choose_ticket');

    // Pre-select member ticket if there is only one.
    this.memberTicketForm = new FormGroup({
      selected_member_ticket: new FormControl(this.model.memberTickets.length === 1 && this.isLivestreamBooking != undefined && (this.isLivestreamBooking || !this.model.memberTickets[0].isForLivestreamBookingOnly) && this.selectedTicket == undefined ? String(this.model.memberTickets[0].id) : '')
    });
  }

  initPaymentMethodFormGroup() {
    this.selectedPaymentMethod = undefined;

    this.paymentMethodCardTitle = this.translate.instant('booking.choose_payment_method');

    this.paymentMethodsForm = new FormGroup({
      selected_payment_method: new FormControl('')
    });
  }

  initPersonalInformationFormGroup() {
    // Show the personal address as the form group title if the personal information is complete.
    if (this.model.personalInformation.address != undefined && this.model.personalInformation.address !== '') {
      this.personalInformationCardTitle = this.model.personalInformation.firstName + ' ' + this.model.personalInformation.lastName + ', ' + this.model.personalInformation.address + ', ' + this.model.personalInformation.country + '-' + this.model.personalInformation.zipCode + ' ' + this.model.personalInformation.city;
      this.isPersonalInformationComplete = true;
    }
    else {
      this.personalInformationCardTitle = this.translate.instant('app.personal_information');
      this.isPersonalInformationComplete = false;
    }
  }

  initCouponFormGroup() {
    this.couponCardTitle = this.translate.instant('booking.coupon');

    // Coupon form
    this.couponForm = new FormGroup({
      'coupon_id': new FormControl('')
    });

    this.activatedCoupon = undefined;
  }

  initMessageFormGroup() {
    // Message form
    this.messageForm = new FormGroup({
      'message': new FormControl('')
    });
  }

  initBookMoreDatesFormGroup() {
    // Show more bookable dates of the selected member ticket.
    this.moreBookableDates = undefined;
    this.selectedBookingDates = new Array<string>();
    this.remainingCredits = undefined;

    // Uncheck any previously checkboxes to prevent errors with the remaining credits.
    if (this.bookMoreDatesForm != undefined) {
      Object.keys(this.bookMoreDatesForm.controls).forEach(key => {
        this.bookMoreDatesForm.controls[key].setValue(false);
      });
    }

    this.bookMoreDatesForm = undefined;
    let bookMoreDatesControls = {};
    this.bookMoreDatesForm = new FormGroup({});

    if (this.selectedMemberTicket != undefined) {
      for (let i = 0; i < this.model.moreBookableDates.length; ++i) {
        if (this.model.moreBookableDates[i].memberTicketId === this.selectedMemberTicket.id) {

          for (const serviceSession of this.model.moreBookableDates[i].bookableDates) {
            bookMoreDatesControls[serviceSession.date] = new FormControl({
              value: false,
              disabled: !serviceSession.isLivestreamBookingEnabled && serviceSession.isFullyBooked || serviceSession.isAlreadyBooked || serviceSession.isClosed || serviceSession.isNotOpenYet || serviceSession.isCancelled
            });
          }

          this.bookMoreDatesForm = new FormGroup(bookMoreDatesControls);
          this.moreBookableDates = this.model.moreBookableDates[i].bookableDates;
          this.remainingCredits = this.model.moreBookableDates[i].credits != undefined ? this.model.moreBookableDates[i].credits : 999;
        }
      }
    }
  }

  ngAfterViewInit() {
    this.personalInformationFormComponent.personalInformationForm.get('first_name').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('last_name').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('address').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('zip_code').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('city').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
    this.personalInformationFormComponent.personalInformationForm.get('country').valueChanges.subscribe(() => {
      this.updatePersonalInformationCardTitle();
    });
  }

  // Sets the personal information as the form group title if the personal information is complete. Otherwise, sets the personal information label as the title.
  updatePersonalInformationCardTitle() {
    const firstName = this.personalInformationFormComponent.personalInformationForm.get('first_name').value;
    const lastName = this.personalInformationFormComponent.personalInformationForm.get('last_name').value;
    const address = this.personalInformationFormComponent.personalInformationForm.get('address').value;
    const zipCode = this.personalInformationFormComponent.personalInformationForm.get('zip_code').value;
    const city = this.personalInformationFormComponent.personalInformationForm.get('city').value;
    const country = this.personalInformationFormComponent.personalInformationForm.get('country').value;
    if (firstName !== '' && lastName !== '' && address !== '' && zipCode !== '' && city !== '' && country !== '') {
      this.personalInformationCardTitle = firstName + ' ' + lastName + ', ' + address + ', ' + country + '-' + zipCode + ' ' + city;
      this.isPersonalInformationComplete = true;
    }
    else {
      this.personalInformationCardTitle = this.translate.instant('app.personal_information');
      this.isPersonalInformationComplete = false;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }


  // Select handlers
  selectOnSiteOrLivestream(isLivestreamBooking: boolean) {
    this.isLivestreamBooking = isLivestreamBooking;

    if (isLivestreamBooking) {
      this.onSiteOrLivestreamCardTitle = this.translate.instant('participants_lists.livestream_booking');
    }
    else {
      this.onSiteOrLivestreamCardTitle = this.translate.instant('participants_lists.on_site_booking');
    }

    this.initTicketFormGroup();
    this.initMemberTicketFormGroup();
    this.initPaymentMethodFormGroup();
    this.initPersonalInformationFormGroup();
    this.initCouponFormGroup();
    this.initBookMoreDatesFormGroup();

    const that = this;
    if (this.model.serviceSession.isFreeOfCharge) {
      setTimeout(function () {
        if (that.model.personalInformation.address == undefined || that.model.personalInformation.address === '') {
          that.personalInformationCard.instance.show();
        }
      }, 100);
    }
    else {
      // Workaround: The timeout is needed to toggle the form groups correctly.
      setTimeout(function () {
        that.ticketCard.instance.show();

        // Pre-select member ticket if there is only one.
        if (that.model.memberTickets.length === 1 && (isLivestreamBooking || !that.model.memberTickets[0].isForLivestreamBookingOnly)) {
          that.selectMemberTicket(that.model.memberTickets[0]);
        }
      }, 100);
    }
  }

  selectMemberTicket(selectedMemberTicket: MemberTicketModel) {
    this.initTicketFormGroup();

    this.ticketCardTitle = selectedMemberTicket.name;
    this.selectedMemberTicket = selectedMemberTicket;

    this.initPaymentMethodFormGroup();
    this.initPersonalInformationFormGroup();
    this.initCouponFormGroup();
    this.initBookMoreDatesFormGroup();

    // Workaround: The timeout is needed to toggle the form groups correctly.
    const that = this;
    setTimeout(function () {
      if (that.model.personalInformation.address == undefined || that.model.personalInformation.address === '') {
        that.personalInformationCard.instance.show();
      }
      else {
        that.ticketCard.instance.hide();
      }
    }, 100);
  }

  selectTicket(selectedTicket: TicketModel) {
    this.selectedTicket = selectedTicket;
    this.originallySelectedTicket = { ...selectedTicket };

    this.initMemberTicketFormGroup();
    this.initPaymentMethodFormGroup();
    this.initPersonalInformationFormGroup();
    this.initCouponFormGroup();

    this.ticketCardTitle = selectedTicket.name;

    // Workaround: The timeout is needed to toggle the form groups correctly.
    const that = this;
    setTimeout(function () {
      if (selectedTicket.price > 0) {
        that.paymentMethodCard.instance.show();
      }
      else {
        if (that.model.personalInformation.address == undefined || that.model.personalInformation.address === '') {
          that.personalInformationCard.instance.show();
        }
        else {
          that.ticketCard.instance.hide();
        }
      }
    }, 100);
  }

  selectPaymentMethod(selectedPaymentMethod: PaymentMethodModel) {
    this.initPersonalInformationFormGroup();
    this.initCouponFormGroup();

    this.selectedPaymentMethod = selectedPaymentMethod;
    this.paymentMethodCardTitle = selectedPaymentMethod.name;

    // Workaround: The timeout is needed to toggle the form groups correctly.
    const that = this;
    setTimeout(function () {
      that.paymentMethodCard.instance.hide();

      if (that.selectedPaymentMethod.id === 'invoice_payment_method_credit_card') {
        that.getStripePaymentIntentClientSecret(true);
        return;
      }

      if (that.model.personalInformation.address == undefined || that.model.personalInformation.address === '') {
        that.personalInformationCard.instance.show();
        return;
      }

      if (that.editHealthQuestionnairesCardComponent != undefined) {
        if (that.editHealthQuestionnairesCardComponent.allHealthQuestionnairesCompleted) {
          that.editHealthQuestionnairesCardComponent.hide();
        }
        else {
          that.editHealthQuestionnairesCardComponent.show();
        }
        return;
      }
    }, 100);
  }

  async getStripePaymentIntentClientSecret(shouldShowCreditCardInput: boolean) {
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.stripeService.checkout(this.selectedTicket, this.activatedCoupon, null, 'manual').subscribe({
        async next(data) {
          (await loading).dismiss();
          that.stripePaymentIntentClientSecret = data.paymentIntentClientSecret;
          that.stripeCheckoutSuccessToken = data.stripeCheckoutSuccessToken;

          if (shouldShowCreditCardInput) {
            that.creditCardInputCard.instance.show();
          }
        },
        async error() {
          (await loading).dismiss();
          that.initPaymentMethodFormGroup();
          setTimeout(function () {
            that.paymentMethodCard.instance.show();
          }, 100);
        }
      }
    );
  }

  onCreditCardInputFormSubmit() {
    // Workaround: The timeout is needed to toggle the form groups correctly.
    const that = this;
    setTimeout(function () {
      that.creditCardInputCard.instance.hide();

      if (that.model.personalInformation.address == undefined || that.model.personalInformation.address === '') {
        that.personalInformationCard.instance.show();
        return;
      }

      if (that.editHealthQuestionnairesCardComponent != undefined) {
        if (that.editHealthQuestionnairesCardComponent.allHealthQuestionnairesCompleted) {
          that.editHealthQuestionnairesCardComponent.hide();
        }
        else {
          that.editHealthQuestionnairesCardComponent.show();
        }
        return;
      }
    }, 100);
  }

  validateBooking() {
    // Input validation
    if (this.model.serviceSession.isLivestreamBookingEnabled && this.isLivestreamBooking == undefined) {
      this.infoPopupComponent.show(this.translate.instant('booking.please_choose_on_site_or_livestream'));
      return;
    }

    if (this.selectedMemberTicket == undefined && this.selectedTicket == undefined && !this.model.serviceSession.isFreeOfCharge) {
      this.infoPopupComponent.show(this.translate.instant('booking.please_choose_ticket'));
      return;
    }

    if (this.selectedTicket && this.selectedPaymentMethod == undefined && !this.model.serviceSession.isFreeOfCharge && this.selectedTicket.price > 0) {
      this.infoPopupComponent.show(this.translate.instant('booking.please_choose_payment_method'));
      return;
    }

    if (!this.personalInformationFormComponent.isFormValid()) {
      this.infoPopupComponent.show(this.translate.instant('components.personal_information.please_fill_out'));
      return;
    }

    if (this.editHealthQuestionnairesCardComponent.areAllHealthQuestionnairesUsable() && !this.editHealthQuestionnairesCardComponent.areAllHealthQuestionnairesValid() && (this.model.serviceSession.isFreeOfCharge || this.selectedPaymentMethod != undefined || this.selectedTicket != undefined && this.selectedTicket.price === 0)) {
      this.infoPopupComponent.show(this.translate.instant('components.health_questionnaire.please_fill_out'));
      return;
    }

    if (this.activatedCoupon == undefined && this.couponForm.get('coupon_id').value !== '') {
      this.onSubmitCouponForm(this.couponForm.value);
      return;
    }

    Keyboard.hide();

    if (this.selectedPaymentMethod != undefined && this.selectedPaymentMethod.id === 'invoice_payment_method_credit_card') {
      this.stripePaymentComponent.confirmPayment(this.stripePaymentIntentClientSecret, this.personalInformationFormComponent.personalInformationForm.get('first_name').value + ' ' + this.personalInformationFormComponent.personalInformationForm.get('last_name').value).then(() => {
        this.confirmBooking();
      }).catch(() => {
        this.getStripePaymentIntentClientSecret(false);
      });
    }
    else if (this.selectedPaymentMethod != undefined && this.selectedPaymentMethod.id === 'invoice_payment_method_twint') {
      this.twintPaymentComponent.start().then(() => {
        this.confirmBooking();
      });
    }
    else if (this.selectedPaymentMethod != undefined && this.selectedPaymentMethod.id === 'invoice_payment_method_reka') {
      const that = this;
      this.rekaService.createRekaCheckout(this.selectedTicket?.id, this.activatedCoupon?.id, null, null, null, this.model.serviceSession.id, this.model.serviceSession.date).subscribe({
        async next(data) {
          that.rekaOrderId = data.orderId;
          if (that.rekaOrderId != undefined && that.rekaOrderId !== '') {
            that.confirmBooking();
          }
        }
      });
    }
    else {
      this.confirmBooking();
    }
  }

  async confirmBooking() {
    this.isConfirmationInProgress = true;
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.scheduleService.confirmBooking(this.model.serviceSession, this.model.provider, this.model.staff, this.isLivestreamBooking, this.selectedMemberTicket, this.selectedTicket, this.selectedPaymentMethod, this.stripeCheckoutSuccessToken, this.twintPaymentComponent.orderUuid, this.activatedCoupon, this.selectedBookingDates, this.personalInformationFormComponent.getPersonalInformationFromForm(), this.editHealthQuestionnairesCardComponent.getAnsweredHealthQuestionnaires(), this.model.accessCode, this.messageForm.get('message').value, this.rekaOrderId).subscribe({
        async next(response) {
          (await loading).dismiss();

          // Handle booking confirmation status.
          switch (response.status) {
            case 'booking_status_success':
            case 'booking_status_already_booked':
              that.confirmedBooking = response.booking;
              Haptics.vibrate();
              that.successPopup.instance.show();

              // Ask for app review one second after the success popup shows.
              if (response.shouldPromptRateAppDialog) {
                that.rateUsService.showRateAppDialog(1000);
              }

              // Automatically add booking to calendar if synchronization is enabled.
              that.storage.get('defaultCalendar').then((calendar) => {
                if (calendar != undefined) {
                  that.profileService.addBookingToCalendar(response.booking, false, calendar);
                  that.isCalendarSynchronisationActive = true
                }
              });
              break;
            case 'booking_status_full':
              that.fullyBookedPopupComponent.show(that.model.serviceSession).then(() => {
                that.modalController.dismiss({});
              });
              break;
            case 'booking_status_booking_limit_reached':
              that.limitReachedPopup.instance.show();
              break;
            case 'bookings_status_twint_confirm_order_error':
              that.twintPaymentComponent.resume().then(() => {
                that.confirmBooking();
              });
              break;
          }
        },
        async error() {
          (await loading).dismiss();
          if (that.selectedPaymentMethod != undefined && that.selectedPaymentMethod.id === 'invoice_payment_method_credit_card') {
            // Create new Stripe payment intent because the price have been changed by the coupon.
            that.getStripePaymentIntentClientSecret(false);
            that.isConfirmationInProgress = false;
          }
        }
      }
    );
  }

  isPaymentMethodExcludedFromSelectedTicket(paymentMethod: PaymentMethodModel) {
    return this.selectedTicket != undefined && paymentMethod != undefined && this.selectedTicket.excludedPaymentMethods.some(excludedPaymentMethod => excludedPaymentMethod === paymentMethod.id);
  }

  async onSubmitMessageForm() {
    Keyboard.hide();
    this.messageCard.instance.hide();
  }

  async onSubmitCouponForm(form: any) {
    // Remove coupon if the input field has been cleared.
    if (form.coupon_id === '') {
      this.activatedCoupon = undefined;
      this.couponCardTitle = this.translate.instant('booking.coupon');
      this.selectedTicket = this.originallySelectedTicket;
      return;
    }

    // Validate coupon if entered.
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.scheduleService.validateCoupon(form.coupon_id, this.selectedTicket.id).subscribe({
        async next(response) {
          (await loading).dismiss();

          if (response.coupon.isValid) {
            that.activatedCoupon = response.coupon;
            that.selectedTicket = response.ticket;
            that.couponCardTitle = response.coupon.description;
            that.couponCard.instance.hide();

            if (that.selectedPaymentMethod != undefined && that.selectedPaymentMethod.id === 'invoice_payment_method_credit_card') {
              // Create new Stripe payment intent because the price have been changed by the coupon.
              that.getStripePaymentIntentClientSecret(false);
            }
          }
          else {
            const alert = await that.alertController.create({
              header: that.translate.instant('booking.invalid_coupon'),
              message: that.translate.instant('booking.invalid_coupon_instructions'),
              buttons: ['OK']
            });

            await alert.present();
            that.initCouponFormGroup();
          }
        },
        async error() {
          (await loading).dismiss();
        }
      }
    );
  }

  moreDateChecked($event: CustomEvent) {
    if (this.isEventHandled) {
      this.isEventHandled = false;
      return;
    }

    // Prevent checking more dates than remaining credits.
    if (this.remainingCredits === 0 && $event.detail.checked) {
      this.isEventHandled = true;
      this.bookMoreDatesForm.controls[$event.detail.value].setValue(false);
      return;
    }

    if ($event.detail.checked) {
      this.selectedBookingDates.push($event.detail.value);
      this.remainingCredits--;
    }
    else {
      const index = this.selectedBookingDates.indexOf($event.detail.value, 0);
      if (index > -1) {
        this.selectedBookingDates.splice(index, 1);
      }
      this.remainingCredits++;
    }
  }

  toggleShowMoreDescription() {
    this.showMoreDescription = !this.showMoreDescription;
  }
}

