import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { ServiceSessionModel } from '../../models/service-session.model';
import * as dayjs from 'dayjs';
import { Calendar } from '@ionic-native/calendar/ngx';

@Component({
  selector: 'app-no-booking-required-popup',
  templateUrl: './no-booking-required-popup.component.html',
  styleUrls: ['./no-booking-required-popup.component.scss'],
})
export class NoBookingRequiredPopupComponent implements OnInit {
  serviceSession: ServiceSessionModel;

  // @ts-ignore
  @ViewChild('noBookingRequiredPopup')
  noBookingRequiredPopup: any;

  popupSettings: MbscPopupOptions = {
    display: 'center',
    closeOnOverlayTap: false,
    onSet: (event, inst) => {
      this.resolve();
    }
  };

  resolve: any;

  constructor(private calendar: Calendar) { }

  ngOnInit() {}

  show(serviceSession: ServiceSessionModel) {
    this.serviceSession = serviceSession;
    this.noBookingRequiredPopup.instance.show();
    const that = this;
    return new Promise(function (resolve) {
      that.resolve = resolve;
    });
  }

  addToCalendar() {
    let customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)
    // @ts-ignore
    let dateTimeBegin = dayjs(this.serviceSession.date + ' ' + this.serviceSession.timeBegin, 'YYYY-MM-DD HH:mm').toDate();
    // @ts-ignore
    let dateTimeEnd = dayjs(this.serviceSession.date + ' ' + this.serviceSession.timeEnd, 'YYYY-MM-DD HH:mm').toDate();

    this.calendar.createEventInteractively(this.serviceSession.name, this.serviceSession.provider.fullAddress, '', dateTimeBegin, dateTimeEnd).then(
      (msg) => {
        console.log(msg);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
