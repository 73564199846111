import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { environment, SERVER_URL } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AppleSignInResponse } from '@ionic-native/sign-in-with-apple/ngx';

@Injectable()
export class LoginService {

  constructor(
    private http: HttpClient
  ) {}

  public register(email: string, password: string, gender: string, firstName: string, lastName: string): Observable<{status: string, authenticationToken: string, memberId: number}> {
    if (environment.useSampleData) {
      return this.http.get<{status: string, authenticationToken: string, memberId: number}>('./assets/sample-data/login/register.json');
      // return this.http.get<{status: string, authenticationToken: string}>('./assets/sample-data/login/register-no-confirmation.json');
    }

    return this.http.post<{status: string, authenticationToken: string, memberId: number}>(SERVER_URL + '/register',
      {
        user: {
          email: email,
          email_confirmation: email,
          password: password,
          password_confirmation: password,
          gender: gender,
          first_name: firstName,
          last_name: lastName
        }
      }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public login(email: string, password: string): Observable<{status: string, authenticationToken: string, memberId: number}> {
    if (environment.useSampleData) {
      // return this.http.get<{status: string, authenticationToken: string}>('./assets/sample-data/login/invalid-login.json');
      return this.http.get<{status: string, authenticationToken: string, memberId: number}>('./assets/sample-data/login/login.json');
    }

    return this.http.post<{status: string, authenticationToken: string, memberId: number}>(SERVER_URL + '/login', { email: email, password: password }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public googleLogin(googleResponse): Observable<{status: string, authenticationToken: string, memberId: number}> {
    if (environment.useSampleData) {
      return this.http.get<{status: string, authenticationToken: string, memberId: number}>('./assets/sample-data/login/login.json');
    }

    return this.http.post<{status: string, authenticationToken: string, memberId: number}>(SERVER_URL + '/google_login', {
      googleResponse: googleResponse
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public appleLogin(appleSignInResponse: AppleSignInResponse): Observable<{status: string, authenticationToken: string, memberId: number}> {
    if (environment.useSampleData) {
      return this.http.get<{status: string, authenticationToken: string, memberId: number}>('./assets/sample-data/login/login.json');
    }

    return this.http.post<{status: string, authenticationToken: string, memberId: number}>(SERVER_URL + '/apple_login', {
      appleSignInResponse: appleSignInResponse
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public facebookLogin(uid: string, email: string, firstName: string, lastName: string, gender: string): Observable<{status: string, authenticationToken: string, memberId: number}> {
    if (environment.useSampleData) {
      return this.http.get<{status: string, authenticationToken: string, memberId: number}>('./assets/sample-data/login/login.json');
    }

    return this.http.post<{status: string, authenticationToken: string, memberId: number}>(SERVER_URL + '/facebook_login', {
      user: {
        uid: uid,
        email: email,
        first_name: firstName,
        last_name: lastName,
        gender: gender,
      }
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public forgotPassword(email: string): Observable<{status: string}> {
    if (environment.useSampleData) {
      return this.http.get<{status: string, authenticationToken: string}>('./assets/sample-data/login/forgot-password.json');
      // return this.http.get<{status: string, authenticationToken: string}>('./assets/sample-data/login/forgot-password-not-found.json');
    }

    return this.http.post<{status: string}>(SERVER_URL + '/forgot_password', { email: email }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public confirmEmail(confirmationToken: string): Observable<{status: string, authenticationToken: string, memberId: number}> {
    if (environment.useSampleData) {
      return this.http.get<{status: string, authenticationToken: string, memberId: number}>('./assets/sample-data/login/login.json');
    }

    return this.http.post<{status: string, authenticationToken: string, memberId: number}>(SERVER_URL + '/confirm_email', { confirmation_token: confirmationToken }).pipe(
      catchError(HttpService.handleError)
    );
  }
}
