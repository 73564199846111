import { Component, Input, OnInit } from '@angular/core';
import {
  MultilingualFieldModel,
  ProviderHealthQuestionnaireModel, QuestionType,
  translateMultilingualField
} from '../../models/provider-health-questionnaire.model';
import { MemberHealthQuestionnaireModel } from '../../models/member-health-questionnaire.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-health-questionnaire-info',
  templateUrl: './health-questionnaire-info.component.html',
  styleUrls: ['./health-questionnaire-info.component.scss'],
})
export class HealthQuestionnaireInfoComponent implements OnInit {
  @Input() providerHealthQuestionnaire: ProviderHealthQuestionnaireModel;
  @Input() memberHealthQuestionnaire: MemberHealthQuestionnaireModel;
  @Input() providerLanguage: string;

  currentLanguage: string;

  protected readonly translateMultilingualField = translateMultilingualField;
  protected readonly QuestionType = QuestionType;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang
  }

  ngOnChanges() {
    this.currentLanguage = this.translate.currentLang
  }


  translateMultipleChoiceQuestion(answers: Array<MultilingualFieldModel>, answerIndex: number) {
    if (answers == undefined || answerIndex == undefined || answers[answerIndex] == undefined) {
      return ''
    }

    return translateMultilingualField(answers[answerIndex], this.currentLanguage, this.providerLanguage);
  }
}
