import { ShellModel } from '../../shell/data-store';
import { VideoModel } from '../../models/video.model';

export class MyVideosModel extends ShellModel {
  videos: Array<VideoModel> = [];

  constructor() {
    super();
  }
}
