import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataStore } from '../../shell/data-store';
import { AppointmentListModel, ScheduleModel, WeekOverviewModel } from './schedule.model';
import { BookingInformationModel } from './booking/booking-information.model';
import { CalendarModel } from './calendar/calendar.model';
import { CouponModel } from '../../models/coupon.model';
import { ServiceSessionModel } from '../../models/service-session.model';
import { ServiceSessionInstancesModel } from './instances/service-session-instances.model';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel } from '../../models/booking.model';
import { environment, SERVER_URL } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../http/http.service';
import { StaffModel } from '../../models/staff.model';
import { MemberTicketModel } from '../../models/member-ticket.model';
import { TicketModel } from '../../models/ticket.model';
import { PaymentMethodModel } from '../../models/payment-method.model';
import { PersonalInformationModel } from '../../components/personal-information-form/personal-information.model';
import { HealthQuestionnaireModel } from '../../components/health-questionnaire/health-questionnaire.model';
import { ProviderModel } from '../../models/provider.model';
import { ScheduleDetailsModel } from './details/schedule-details.model';
import { MemberHealthQuestionnaireModel } from '../../models/member-health-questionnaire.model';

@Injectable()
export class ScheduleService {

  private scheduleDataStore: DataStore<ScheduleModel>;
  private scheduleDetailsDataStore: DataStore<ScheduleDetailsModel>;
  private serviceSessionInstancesDataStore: DataStore<ServiceSessionInstancesModel>;
  private calendarAppointmentsDataStore: DataStore<CalendarModel>;

  constructor(
    private http: HttpClient,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private translate: TranslateService
  ) { }

  // Booking
  public getBookingInformationDataSource(serviceSession: ServiceSessionModel): Observable<BookingInformationModel> {
    if (environment.useSampleData) {
      // return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-with-member-tickets-without-personal-information.json');
      return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-with-member-tickets.json');
      // return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-restricted.json');
      // return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-show-minimum-requirements.json');
      // return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-with-disclaimer.json');
      // return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-with-access-code.json');
      // return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-without-member-tickets.json');
      // return this.http.get<BookingInformationModel>('./assets/sample-data/provider/booking-information-free-of-charge.json');
    }

    let params = new HttpParams()
      .set('date', serviceSession.date)
      .set('timeBegin', serviceSession.timeBegin)
      .set('serviceSessionId', String(serviceSession.id));

    if (serviceSession.staffs != undefined && serviceSession.staffs.length > 0) {
      params = params.set('staffId', String(serviceSession.staffs[0].id));
    }

    return this.http.get<BookingInformationModel>(SERVER_URL + '/v1/bookings/booking_information', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public validateAccessCode(providerId: string, accessCode: string): Observable<{ isAccessCodeValid: boolean }> {
    if (environment.useSampleData) {
      return this.http.get<{ isAccessCodeValid: boolean }>('./assets/sample-data/provider/access-code-valid.json');
      // return this.http.get<{ isAccessCodeValid: boolean }>('./assets/sample-data/provider/access-code-invalid.json');
    }

    const body = {}
    body['accessCode'] = accessCode;

    return this.http.post<{ isAccessCodeValid: boolean }>(SERVER_URL + '/v1/providers/' + providerId + '/validate_access_code', body).pipe(
      catchError(HttpService.handleError)
    );
  }

  public confirmBooking(serviceSession: ServiceSessionModel, provider: ProviderModel, staff: StaffModel, isLivestreamBooking: boolean, memberTicket: MemberTicketModel, ticket: TicketModel, paymentMethod: PaymentMethodModel, stripeCheckoutSuccessToken: string, twintOrderUuid: string, coupon: CouponModel, bookingDates: Array<string>, personalInformation: PersonalInformationModel, healthQuestionnaires: Array<MemberHealthQuestionnaireModel>, accessCode: string, customerNote: string, rekaOrderId: string): Observable<{ status: string, booking: BookingModel, shouldPromptRateAppDialog: boolean }> {
    if (environment.useSampleData) {
      // return this.http.get<{status: string, booking: BookingModel}>('./assets/sample-data/provider/booking-confirmation-error.json');
      // return this.http.get<{status: string, booking: BookingModel}>('./assets/sample-data/provider/booking-confirmation-limit-reached.json');
      // return this.http.get<{status: string, booking: BookingModel}>('./assets/sample-data/provider/booking-confirmation-fully-booked.json');
      return this.http.get<{ status: string, booking: BookingModel, shouldPromptRateAppDialog: boolean }>('./assets/sample-data/provider/booking-confirmation-success.json');
    }

    const body = {}
    body['serviceSessionId'] = serviceSession.id;
    body['providerId'] = provider.id;
    body['date'] = serviceSession.date;
    body['timeBegin'] = serviceSession.timeBegin;
    body['isLivestreamBooking'] = isLivestreamBooking;

    if (staff != undefined) {
      body['staffId'] = staff.id;
    }
    if (memberTicket != undefined) {
      body['memberTicketId'] = memberTicket.id;
    }
    if (ticket != undefined) {
      body['ticketId'] = ticket.id;
    }
    if (paymentMethod != undefined) {
      body['paymentMethod'] = paymentMethod.id;
    }
    if (stripeCheckoutSuccessToken != undefined) {
      body['stripeCheckoutSuccessToken'] = stripeCheckoutSuccessToken;
    }
    if (twintOrderUuid != undefined) {
      body['twintOrderUuid'] = twintOrderUuid;
      body['cashRegisterId'] = ticket.name;
    }
    if (coupon != undefined) {
      body['couponId'] = coupon.id;
    }
    if (bookingDates != undefined) {
      body['bookingDates'] = bookingDates;
    }
    if (personalInformation != undefined) {
      body['personalInformation'] = personalInformation;
    }
    if (healthQuestionnaires != undefined) {
      body['healthQuestionnaires'] = healthQuestionnaires;
    }
    if (accessCode != undefined) {
      body['accessCode'] = accessCode;
    }
    if (customerNote != undefined) {
      body['customerNote'] = customerNote;
    }
    if (rekaOrderId != undefined) {
      body['rekaOrderId'] = rekaOrderId;
    }

    return this.http.post<{ status: string, booking: BookingModel, shouldPromptRateAppDialog: boolean }>(SERVER_URL + '/v1/bookings/confirm_booking', body).pipe(
      catchError(HttpService.handleError)
    );
  }

  public validateCoupon(couponId: string, ticketId: number): Observable<{ coupon: CouponModel, ticket: TicketModel }> {
    if (environment.useSampleData) {
      return this.http.get<{ coupon: CouponModel, ticket: TicketModel }>('./assets/sample-data/provider/valid-coupon.json');
    }

    const body = {}
    body['couponId'] = couponId;
    body['ticketId'] = ticketId;

    return this.http.post<{ coupon: CouponModel, ticket: TicketModel }>(SERVER_URL + '/v1/bookings/validate_coupon', body).pipe(
      catchError(HttpService.handleError)
    );
  }

  public contactMe(providerId: string, serviceSessionId: number, phone: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    const body = {}
    body['serviceSessionId'] = serviceSessionId;
    body['phone'] = phone;

    return this.http.post<{ coupon: CouponModel, ticket: TicketModel }>(SERVER_URL + '/v1/providers/' + providerId + '/contact_me', body).pipe(
      catchError(HttpService.handleError)
    );
  }

  addToWatchList(serviceSession: ServiceSessionModel) {
    const that = this;
    return new Promise<void>(async function (resolve, reject) {
      const loading = that.loadingController.create({
        cssClass: 'custom-loading',
        spinner: 'crescent'
      });
      (await loading).present();

      let datasource;
      if (environment.useSampleData) {
        datasource = that.http.get<{}>('./assets/sample-data/empty.json');
      }
      else {
        const body = {}
        body['serviceSessionId'] = serviceSession.id;
        body['date'] = serviceSession.date;
        body['timeBegin'] = serviceSession.timeBegin;

        if (serviceSession.staffs != undefined && serviceSession.staffs.length > 0) {
          body['staffId'] = String(serviceSession.staffs[0].id);
        }

        datasource = that.http.post<{}>(SERVER_URL + '/v1/bookings/add_to_watch_list', body).pipe(
          catchError(HttpService.handleError)
        );
      }

      datasource.subscribe({
          async next() {
            (await loading).dismiss();
            const toast = await that.toastController.create({
              message: that.translate.instant('my_watch_list.add_to_watch_list_successful'),
              color: 'dark',
              duration: 2000
            });
            toast.present();
            resolve();
          },
          async error() {
            (await loading).dismiss();
            reject();
          }
        }
      );
    });
  }

  removeFromWatchList(serviceSession: ServiceSessionModel) {
    const that = this;
    return new Promise<void>(async function (resolve, reject) {
      const loading = that.loadingController.create({
        cssClass: 'custom-loading',
        spinner: 'crescent'
      });
      (await loading).present();

      let datasource;
      if (environment.useSampleData) {
        datasource = that.http.get<{}>('./assets/sample-data/empty.json');
      }
      else {
        const body = {}
        body['serviceSessionId'] = serviceSession.id;
        body['date'] = serviceSession.date;
        body['timeBegin'] = serviceSession.timeBegin;

        datasource = that.http.post<{}>(SERVER_URL + '/v1/bookings/remove_from_watch_list', body).pipe(
          catchError(HttpService.handleError)
        );
      }

      datasource.subscribe({
          async next() {
            (await loading).dismiss();
            const toast = await that.toastController.create({
              message: that.translate.instant('my_watch_list.remove_from_watch_list_successful'),
              color: 'dark',
              duration: 2000
            });
            toast.present();
            resolve();
          },
          async error() {
            (await loading).dismiss();
            reject();
          }
        }
      );
    });
  }

  public addToWaitingList(serviceSession: ServiceSessionModel): Observable<{ status: string, shouldPromptRateAppDialog: boolean }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, shouldPromptRateAppDialog: boolean }>('./assets/sample-data/provider/add-to-waiting-list-success.json');
    }

    const body = {}
    body['serviceSessionId'] = serviceSession.id;
    body['date'] = serviceSession.date;
    body['timeBegin'] = serviceSession.timeBegin;

    if (serviceSession.staffs != undefined && serviceSession.staffs.length > 0) {
      body['staffId'] = serviceSession.staffs[0].id;
    }

    return this.http.post<{ status: string, shouldPromptRateAppDialog: boolean }>(SERVER_URL + '/v1/bookings/add_to_waiting_list', body).pipe(
      catchError(HttpService.handleError)
    );
  }


  // Calendar
  public getCalendarMarkedDaysDataSource(serviceSessionId: number, date: string): Observable<{ markedDays: Array<string> }> {
    if (environment.useSampleData) {
      return this.http.get<{ markedDays: Array<string> }>('./assets/sample-data/provider/marked-days.json');
    }

    const params = new HttpParams()
      .set('date', date)
      .set('serviceSessionId', String(serviceSessionId));

    return this.http.get<{ markedDays: Array<string> }>(SERVER_URL + '/v1/bookings/calendar_marked_days', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getCalendarAppointmentsDataSource(serviceSessionId: number, date: string): Observable<CalendarModel> {
    if (environment.useSampleData) {
      return this.http.get<CalendarModel>('./assets/sample-data/provider/calendar.json');
    }

    const params = new HttpParams()
      .set('date', date)
      .set('serviceSessionId', String(serviceSessionId));

    return this.http.get<CalendarModel>(SERVER_URL + '/v1/bookings/calendar_appointments', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getCalendarAppointmentsDataStore(dataSource: Observable<CalendarModel>): DataStore<CalendarModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: CalendarModel = new CalendarModel();
    this.calendarAppointmentsDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.calendarAppointmentsDataStore.load(dataSource);
    return this.calendarAppointmentsDataStore;
  }


  // Schedule
  public getWeekOverviewDataSource(providerId: string): Observable<{ weekOverview: Array<WeekOverviewModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ weekOverview: Array<WeekOverviewModel> }>('./assets/sample-data/provider/week-overview.json');
    }

    return this.http.get<{ weekOverview: Array<WeekOverviewModel> }>(SERVER_URL + '/v1/providers/' + providerId + '/week_overview').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getAppointmentsDataSource(providerId: string): Observable<{ appointments: Array<AppointmentListModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ appointments: Array<AppointmentListModel> }>('./assets/sample-data/provider/appointments.json');
    }

    return this.http.get<ScheduleModel>(SERVER_URL + '/v1/providers/' + providerId + '/appointments').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getWorkshopsDataSource(providerId: string): Observable<{ workshops: Array<ServiceSessionModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ workshops: Array<ServiceSessionModel> }>('./assets/sample-data/provider/workshops.json');
    }

    return this.http.get<{ workshops: Array<ServiceSessionModel> }>(SERVER_URL + '/v1/providers/' + providerId + '/workshops').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getLiveCalendarDataSource(providerId: string, date: string): Observable<{ liveCalendar: Array<ServiceSessionModel> }> {
    if (environment.useSampleData) {
      // return this.http.get<{ liveCalendar: Array<ServiceSessionModel> }>('./assets/sample-data/provider/live-calendar-no-member-tickets.json');
      // return this.http.get<{ liveCalendar: Array<ServiceSessionModel> }>('./assets/sample-data/provider/live-calendar-empty.json');
      return this.http.get<{ liveCalendar: Array<ServiceSessionModel> }>('./assets/sample-data/provider/live-calendar.json');
    }

    return this.http.get<ScheduleModel>(SERVER_URL + '/v1/providers/' + providerId + '/live_calendar/' + date).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getServiceSessionInstancesDataSource(serviceSessionId: number): Observable<ServiceSessionInstancesModel> {
    if (environment.useSampleData) {
      return this.http.get<ServiceSessionInstancesModel>('./assets/sample-data/provider/service-session-instances.json');
    }

    const params = new HttpParams()
      .set('serviceSessionId', String(serviceSessionId));

    return this.http.get<ServiceSessionInstancesModel>(SERVER_URL + '/v1/bookings/service_session_instances', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getServiceSessionInstancesDataStore(dataSource: Observable<ServiceSessionInstancesModel>): DataStore<ServiceSessionInstancesModel> {
    // Use cache if available
    if (!this.serviceSessionInstancesDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: ServiceSessionInstancesModel = new ServiceSessionInstancesModel();
      this.serviceSessionInstancesDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.serviceSessionInstancesDataStore.load(dataSource);
    }
    return this.serviceSessionInstancesDataStore;
  }

  public getScheduleDetailsDataSource(providerId: string, serviceSessionId: number): Observable<ScheduleDetailsModel> {
    if (environment.useSampleData) {
      return this.http.get<ScheduleDetailsModel>('./assets/sample-data/provider/schedule-details.json');
    }

    const params = new HttpParams()
      .set('serviceSessionId', String(serviceSessionId));

    return this.http.get<ScheduleDetailsModel>(SERVER_URL + '/v1/providers/' + providerId + '/schedule/details', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getScheduleDetailsDataStore(dataSource: Observable<ScheduleDetailsModel>): DataStore<ScheduleDetailsModel> {
    // Use cache if available
    if (!this.scheduleDetailsDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: ScheduleDetailsModel = new ScheduleDetailsModel();
      this.scheduleDetailsDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.scheduleDetailsDataStore.load(dataSource);
    }
    return this.scheduleDetailsDataStore;
  }

  public getScheduleDataSource(providerId: string): Observable<ScheduleModel> {
    if (environment.useSampleData) {
      // return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule-empty.json');
      // return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule-appointments-only.json');
      // return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule-workshops-only.json');
      // return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule-classes-only.json');
      // return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule-classes-appointments.json');
      // return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule-classes-workshops.json');
      // return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule-workshops-appointments.json');
      return this.http.get<ScheduleModel>('./assets/sample-data/provider/schedule.json');
    }

    return this.http.get<ScheduleModel>(SERVER_URL + '/v1/providers/' + providerId + '/schedule').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getScheduleDataStore(dataSource: Observable<ScheduleModel>): DataStore<ScheduleModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: ScheduleModel = new ScheduleModel();
    this.scheduleDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.scheduleDataStore.load(dataSource);
    return this.scheduleDataStore;
  }
}
