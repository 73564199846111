import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { DataStore } from '../../shell/data-store';
import { PricingModel } from './pricing.model';
import { TicketPurchaseInformationModel } from './purchase/ticket-purchase-information.model';
import { environment, SERVER_URL } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../http/http.service';
import { PaymentMethodModel } from '../../models/payment-method.model';
import { CouponModel } from '../../models/coupon.model';
import { TicketModel } from '../../models/ticket.model';
import { PersonalInformationModel } from '../../components/personal-information-form/personal-information.model';
import { HealthQuestionnaireModel } from '../../components/health-questionnaire/health-questionnaire.model';
import { ProviderModel } from '../../models/provider.model';
import { MemberHealthQuestionnaireModel } from '../../models/member-health-questionnaire.model';

@Injectable()
export class PricingService {
  private dataStore: DataStore<PricingModel>;

  constructor(private http: HttpClient) { }

  public confirmPurchase(ticket: TicketModel, date: string, provider: ProviderModel, paymentMethod: PaymentMethodModel, stripeCheckoutSuccessToken: string, twintOrderUuid: string, coupon: CouponModel, personalInformation: PersonalInformationModel, healthQuestionnaires: Array<MemberHealthQuestionnaireModel>, rekaOrderId: string): Observable<{ status: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/provider/purchase-confirmation-success.json');
    }

    const body = {}
    body['ticketId'] = ticket.id;
    body['date'] = date;
    body['providerId'] = provider.id;

    if (paymentMethod != undefined) {
      body['paymentMethod'] = paymentMethod.id;
    }
    if (stripeCheckoutSuccessToken != undefined) {
      body['stripeCheckoutSuccessToken'] = stripeCheckoutSuccessToken;
    }
    if (twintOrderUuid != undefined) {
      body['twintOrderUuid'] = twintOrderUuid;
      body['cashRegisterId'] = ticket.name;
    }
    if (coupon != undefined) {
      body['couponId'] = coupon.id;
    }
    if (personalInformation != undefined) {
      body['personalInformation'] = personalInformation;
    }
    if (healthQuestionnaires != undefined) {
      body['healthQuestionnaires'] = healthQuestionnaires;
    }
    if (rekaOrderId != undefined) {
      body['rekaOrderId'] = rekaOrderId;
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/bookings/confirm_booking', body).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getTicketPurchaseInformationDataSource(ticketId: number): Observable<TicketPurchaseInformationModel> {
    if (environment.useSampleData) {
      // return this.http.get<PurchaseInformationModel>('./assets/sample-data/provider/purchase-information-health-questionnaire-complete.json');
      return this.http.get<TicketPurchaseInformationModel>('./assets/sample-data/provider/purchase-information.json');
      // return this.http.get<PurchaseInformationModel>('./assets/sample-data/provider/purchase-information-free.json');
    }

    let params = new HttpParams()
      .set('ticketId', String(ticketId));

    return this.http.get<TicketPurchaseInformationModel>(SERVER_URL + '/v1/bookings/ticket_purchase_information', {params: params}).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getDataSource(providerId: string): Observable<PricingModel> {
    if (environment.useSampleData) {
      return this.http.get<PricingModel>('./assets/sample-data/provider/pricing.json');
    }

    return this.http.get<PricingModel>(SERVER_URL + '/v1/providers/' + providerId + '/pricing').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getDataStore(dataSource: Observable<PricingModel>): DataStore<PricingModel> {
    // Use cache if available
    if (!this.dataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: PricingModel = new PricingModel();
      this.dataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.dataStore.load(dataSource);
    }
    return this.dataStore;
  }
}
