import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MyMembersService } from '../my-members.service';
import { HealthQuestionnaireModel } from '../../../components/health-questionnaire/health-questionnaire.model';
import { HealthQuestionnaireComponent } from '../../../components/health-questionnaire/health-questionnaire.component';

@Component({
  selector: 'edit-health-questionnaire-modal',
  templateUrl: './edit-health-questionnaire.modal.html',
  styleUrls: ['./edit-health-questionnaire.modal.scss'],
})
export class EditHealthQuestionnaireModal implements OnInit {
  @Input() providerId: string;
  @Input() customerId: number;
  @Input() healthQuestionnaire: HealthQuestionnaireModel;

  hasQuestionnaireBeenSavedByBackEnd = false;
  canModalBeDismissed = true;

  // @ts-ignore
  @ViewChild('healthQuestionnaireComponent')
  healthQuestionnaireComponent: HealthQuestionnaireComponent;

  // @ts-ignore
  @ViewChild('infoPopupComponent')
  infoPopupComponent: any;


  // @ts-ignore
  @ViewChild('successPopupComponent')
  successPopupComponent: any;

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private myMembersService: MyMembersService,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss({ hasHealthQuestionnaireChanged: this.hasQuestionnaireBeenSavedByBackEnd });
  }

  async saveHealthQuestionnaire() {
    if (!this.healthQuestionnaireComponent.isValid()) {
      this.infoPopupComponent.show(this.translate.instant('components.health_questionnaire.please_fill_out'));
      return;
    }

    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });

    // Prevent dismissal of modal while saving is in progress, so we know if backend was successful and if the member
    // health questionnaire has been written to the database or not. This information is needed to reload the member
    // details (in my-member-show.page.ts) only if necessary.
    this.canModalBeDismissed = false;

    (await loading).present();
    this.myMembersService.saveHealthQuestionnaire(this.providerId, this.customerId, this.healthQuestionnaireComponent.getAnsweredHealthQuestionnaire()).subscribe({
        async next(data) {
          (await loading).dismiss();
          that.hasQuestionnaireBeenSavedByBackEnd = data.savedChangedQuestionnaire;

          that.successPopupComponent.show(that.translate.instant('app.saved'), '').then(() => {
            that.modalController.dismiss({ customerId: that.customerId, hasHealthQuestionnaireChanged: that.hasQuestionnaireBeenSavedByBackEnd});
          });

          that.canModalBeDismissed = true;
        },
        async error(message) {
          (await loading).dismiss();
          that.canModalBeDismissed = true;
        }
      }
    );
  }

}
