import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { HomeModel } from './home.model';
import { DataStore } from '../shell/data-store';
import { ServiceSessionRatingModel } from '../models/service-session-rating.model';
import { environment, SERVER_URL } from '../../environments/environment';
import { HttpService } from '../http/http.service';
import { catchError } from 'rxjs/operators';
import { ProviderModel } from '../models/provider.model';

@Injectable()
export class HomeService {

  private homeDataStore: DataStore<HomeModel>;
  private adminHomeDataStore: DataStore<HomeModel>;

  constructor(private http: HttpClient) { }

  public getHomeDataSource(): Observable<HomeModel> {
    if (environment.useSampleData) {
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-no-studio.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-single-items.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-no-upcoming-bookings.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-empty.json');
      return this.http.get<HomeModel>('./assets/sample-data/home/home.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-with-rating.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-with-unpaid-twint.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-with-unpaid-credit-card.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-with-unpaid-invoice.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/home-with-unpaid-cash.json');
    }

    return this.http.get<HomeModel>(SERVER_URL + '/v1/home/feed').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getHomeStore(dataSource: Observable<HomeModel>): DataStore<HomeModel> {
    // Use cache if available
    if (!this.homeDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: HomeModel = new HomeModel();
      this.homeDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.homeDataStore.load(dataSource);
    }
    return this.homeDataStore;
  }

  public getAdminHomeDataSource(): Observable<HomeModel> {
    if (environment.useSampleData) {
      return this.http.get<HomeModel>('./assets/sample-data/home/admin-home.json');
      // return this.http.get<HomeModel>('./assets/sample-data/home/admin-home-empty.json');
    }

    return this.http.get<HomeModel>(SERVER_URL + '/v1/home/admin_feed').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getAdminHomeStore(dataSource: Observable<HomeModel>): DataStore<HomeModel> {
    // Use cache if available
    if (!this.adminHomeDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: HomeModel = new HomeModel();
      this.adminHomeDataStore = new DataStore(shellModel);
    }
    // Trigger the loading mechanism (with shell) in the dataStore
    this.adminHomeDataStore.load(dataSource);
    return this.adminHomeDataStore;
  }

  public submitRating(serviceSessionRating: ServiceSessionRatingModel): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{ status: string, authenticationToken: string }>(SERVER_URL + '/v1/home/submit_rating',
      {
        bookingId: serviceSessionRating.booking.id,
        feedback: serviceSessionRating.feedback,
        isCustomerCareChecked: serviceSessionRating.isCustomerCareChecked,
        isEquipmentChecked: serviceSessionRating.isEquipmentChecked,
        isParticipantsChecked: serviceSessionRating.isParticipantsChecked,
        isProfessionalismChecked: serviceSessionRating.isProfessionalismChecked,
        isSchedulingChecked: serviceSessionRating.isSchedulingChecked,
        isSkipped: serviceSessionRating.isSkipped,
        stars: serviceSessionRating.stars,
        disableRatingSystem: serviceSessionRating.disableRatingSystem
      }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public applyProviderColors(provider: ProviderModel) {
    if (provider == undefined || provider.primaryColor == undefined || provider.secondaryColor == undefined || provider.primaryColor === '#' || provider.secondaryColor === '#' ) {
      this.resetProviderColors();
      return;
    }
    document.documentElement.style.setProperty(`--ion-toolbar-background`, 'linear-gradient(90deg, ' + provider.primaryColor + ' 0%, ' + provider.secondaryColor + ' 100%)');
    document.documentElement.style.setProperty(`--ion-color-secondary`, provider.primaryColor);
    document.documentElement.style.setProperty(`--ion-color-secondary-shade`, provider.secondaryColor);
  }

  public resetProviderColors() {
    document.documentElement.style.setProperty(`--ion-toolbar-background`, 'linear-gradient(90deg, rgba(140,3,3,1) 0%, rgba(242,5,5,1) 100%)');
    document.documentElement.style.setProperty(`--ion-color-secondary`, '#BF0404');
    document.documentElement.style.setProperty(`--ion-color-secondary-shade`, '#a80404');
  }

}
