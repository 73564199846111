<div class="warning-container">
  <mbsc-card class="warning-card">
    <mbsc-card-header>
      <mbsc-card-title>
        {{ 'notifications.push_is_deactivated' | translate }} <ion-icon name="notifications-off-outline"></ion-icon>
      </mbsc-card-title>
    </mbsc-card-header>
    <mbsc-card-content>
      {{ 'notifications.allow_push_notifications' | translate }}
    </mbsc-card-content>
    <mbsc-card-footer>
      <ion-button expand="block" color="secondary" type="submit" fill="outline" size="small" (click)="openSettings()">
        <ion-icon name="settings-outline" slot="start"></ion-icon>
        {{ 'app.open_settings' | translate }}
      </ion-button>
    </mbsc-card-footer>
  </mbsc-card>
</div>
