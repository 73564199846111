export class ProviderHealthQuestionnaireModel {
  id: number;
  name: string;
  healthQuestions: Array<HealthQuestionModel>;
}

export class HealthQuestionModel {
  type: QuestionType;
  question: MultilingualFieldModel;
  answers?: Array<MultilingualFieldModel>;
}

export enum QuestionType {
  YesNo = 'yes_no',
  Freetext = 'freetext',
  MultipleChoiceSingleAnswer = 'mc_sa',
  MultipleChoiceMultipleAnswers = 'mc_ma',
}

export class MultilingualFieldModel {
  de: string;
  en: string;
  fr: string;
  it: string;
}

export function translateMultilingualField(field: MultilingualFieldModel, language: string, fallback_language: string) {
  const translation = getTranslation(field, language);

  if (translation) {
    return translation;
  }

  return getTranslation(field, fallback_language);
}

function getTranslation(field: MultilingualFieldModel, language: string) {
  if (language == 'de') {
    return field.de;
  }
  else if (language == 'en') {
    return field.en;
  }
  else if (language == 'fr') {
    return field.fr;
  }
  else if (language == 'it') {
    return field.it;
  }

  return ''
}
