import { ShellModel } from '../../../shell/data-store';
import { CustomerModel } from '../../../models/customer.model';
import { MemberTicketModel } from '../../../models/member-ticket.model';
import { BookingModel } from '../../../models/booking.model';
import { CheckInModel } from '../../../models/check-in.model';
import { VideoModel } from '../../../models/video.model';
import { PointOfSaleTransactionModel } from '../../../models/point-of-sale-transaction.model';
import { FileModel } from '../../../models/file.model';
import { CustomerAccountTransactionModel } from '../../../models/customer-account-transaction.model';

export class MyMembersShowModel extends ShellModel {
  customer = new CustomerModel();
  isHealthQuestionnaireEnabled: boolean;
  isCheckInEnabled: boolean;
  isCustomerBalanceEnabled: boolean;
  isCustomerNotesEnabled: boolean;
  isCustomerFilesEnabled: boolean;
  isCouponsEnabled: boolean;
  isPointOfSaleEnabled: boolean;
  isMemberManagementEnabled: boolean;
  isVideoPortalEnabled: boolean;
  isTimeStopEnabled: boolean;
  isCovidCertificateEnabled: boolean;

  constructor() {
    super();
  }
}

export class MyMembersShowMemberTicketsModel extends ShellModel {
  memberTickets: Array<MemberTicketModel>;

  constructor() {
    super();
  }
}

export class MyMembersShowBookingsModel extends ShellModel {
  bookings: Array<BookingModel>;

  constructor() {
    super();
  }
}

export class MyMembersShowCheckInsModel extends ShellModel {
  checkIns: Array<CheckInModel>;

  constructor() {
    super();
  }
}

export class MyMembersShowVideosModel extends ShellModel {
  videos: Array<VideoModel>;

  constructor() {
    super();
  }
}

export class MyMembersShowFilesModel extends ShellModel {
  files: Array<FileModel>;

  constructor() {
    super();
  }
}

export class MyMembersShowSalesModel extends ShellModel {
  transactions: Array<PointOfSaleTransactionModel>;

  constructor() {
    super();
  }
}

export class MyMembersShowAccountTransactionsModel extends ShellModel {
  transactions: Array<CustomerAccountTransactionModel>;

  constructor() {
    super();
  }
}

export namespace MyMembersShowModel {
  export enum NavItem {
    Overview,
    MemberTickets,
    Bookings,
    CheckIns,
    Videos,
    Sales,
    AccountTransactions,
    Files,
    HealthQuestionnaires
  }
}
