import { ShellModel } from '../../../shell/data-store';
import { ServiceSessionModel } from '../../../models/service-session.model';
import { StaffModel } from '../../../models/staff.model';
import { TicketModel } from '../../../models/ticket.model';
import { ProviderModel } from '../../../models/provider.model';

export class ScheduleDetailsModel extends ShellModel {
  serviceSession: ServiceSessionModel;
  staff: StaffModel;
  tickets: Array<TicketModel>;
  provider: ProviderModel;

  constructor() {
    super();
  }
}
