import { ShellModel } from '../../shell/data-store';
import { MemberTicketModel } from '../../models/member-ticket.model';

export class MyPassesModel extends ShellModel {
  memberTickets: Array<MemberTicketModel> = [];

  constructor() {
    super();
  }
}
