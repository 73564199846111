<mbsc-popup [options]="settings" #popup="mobiscroll">
  <h3>{{ 'rate_us.do_you_like_sportsnow' | translate }}</h3>
  <p *ngIf="platform.is('ios')">{{ 'rate_us.would_you_rate_us_ios' | translate }}</p>
  <p *ngIf="platform.is('android')">{{ 'rate_us.would_you_rate_us_android' | translate }}</p>
  <p>{{ 'rate_us.rating_would_help_us' | translate }}</p>
  <p>
    ⭐⭐⭐⭐⭐️ </p>

  <ion-button expand="block" fill="outline" color="secondary"
              (click)="openAppStoreReview()">{{ 'rate_us.rate_now' | translate }}
  </ion-button>

  <ion-button expand="block" fill="outline" color="medium"
              (click)="close()">{{ 'rate_us.no_thanks' | translate }}</ion-button>
</mbsc-popup>
