import { NewsModel } from './news.model';
import { VideoModel } from './video.model';
import { RatingModel } from './rating.model';
import { StaffModel } from './staff.model';

export class ProviderModel {
  id: string;
  address: string;
  city: string;
  description: string;
  email: string;
  fullAddress: string;
  goUrl: string;
  isCheckInAvailable: boolean;
  isFavorite: boolean;
  hasBackOfficeRole: boolean;
  isParticipantsListsAvailable: boolean;
  isRatingSystemAvailable: boolean;
  isTicketPurchaseDisabled: boolean;
  isAutomaticBookingWaitingListEnabled: boolean;
  isPremium: boolean;
  areHealthQuestionnairesAvailable: boolean;
  logo: string;
  myAccountUrl: string;
  name: string;
  news: Array<NewsModel>;
  phone: string;
  ratings: Array<RatingModel>;
  slogan: string;
  stripeUserId: string;
  team: Array<StaffModel>;
  termsAndConditionsUrl: string;
  url: string;
  videos: Array<VideoModel>;
  zipCode: string;
  primaryColor: string;
  secondaryColor: string;
}
