import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MyMembersService } from '../my-members.service';
import { HealthQuestionnaireModel } from '../../../components/health-questionnaire/health-questionnaire.model';
import { EditHealthQuestionnaireModal } from '../edit-health-questionnaire/edit-health-questionnaire.modal';

@Component({
  selector: 'app-health-questionnaire-modal',
  templateUrl: './health-questionnaire.modal.html',
  styleUrls: ['./health-questionnaire.modal.scss'],
})
export class HealthQuestionnaireModal implements OnInit {
  @Input() providerId: string;
  @Input() customerId: number;
  @Input() bookingId: number;
  @Input() providerHealthQuestionnaireId: number;
  @Input() providerHealthQuestionnaireName: string;
  @Input() memberHealthQuestionnaireId: number;

  healthQuestionnaire: HealthQuestionnaireModel;

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private myMembersService: MyMembersService,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.loadFullHealthQuestionnaire();
  }

  dismissModal() {
    this.modalController.dismiss()
  }

  // Make sure that this method is only callable, if this.healthQuestionnaire and this.healthQuestionnaire.providerHealthQuestionnaire
  // are both defined.
  // Since this component loads the providerHealthQuestionnaire and memberHealthQuestionnaire (if it exists), we do not
  // need to reload it, when we present the edit modal.
  async presentEditHealthQuestionnaireModal() {
    const modal = await this.modalController.create({
      component: EditHealthQuestionnaireModal,
      componentProps: {
        'providerId': this.providerId,
        'customerId': this.customerId,
        'healthQuestionnaire': this.healthQuestionnaire
      }
    });

    modal.onDidDismiss().then(data => {
      if (data.data != undefined) {
        this.modalController.dismiss({ hasHealthQuestionnaireChanged: data.data.hasHealthQuestionnaireChanged });
      }
    });

    await modal.present();
  }

  async loadFullHealthQuestionnaire() {
    const that = this;

    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });

    (await loading).present();

    this.myMembersService.getHealthQuestionnaire(this.providerId, this.customerId, this.providerHealthQuestionnaireId, this.memberHealthQuestionnaireId, this.bookingId).subscribe({
      async next(response) {
        (await loading).dismiss();

        that.healthQuestionnaire = response.healthQuestionnaire;
      },
      async error() {
        (await loading).dismiss();
      }
    });
  }
}
