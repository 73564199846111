<mbsc-popup [options]="settings" #popup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <img src="./assets/logos/twint.png">
    <h1 [hidden]="hasPaymentFailed">Code: {{ token }}</h1>
    <h1 [hidden]="!hasPaymentFailed">{{ 'app.error' | translate }}</h1>
    <p>{{ instructions }}</p>

    <ion-button expand="block" fill="outline" color="secondary"
                *ngIf="!hasPaymentFailed && twintAppConfigurations != undefined && twintAppConfigurations.length === 1 && !isConfirmationInProgress"
                (click)="payWithTwintApp(twintAppConfigurations[0])">{{ 'components.twint_payment.open_twint_app' | translate }}</ion-button>

    <div
      *ngIf="!hasPaymentFailed && twintAppConfigurations != undefined && twintAppConfigurations.length > 1 && !isConfirmationInProgress">
      <ion-button expand="block" fill="outline" color="secondary"
                  *ngFor="let twintAppConfiguration of twintAppConfigurations"
                  (click)="payWithTwintApp(twintAppConfiguration)">{{ twintAppConfiguration.appDisplayName }}</ion-button>
    </div>

    <ion-spinner name="crescent" color="secondary" *ngIf="isConfirmationInProgress"></ion-spinner>

    <ion-button expand="block" fill="outline" color="secondary" *ngIf="hasPaymentFailed && !isConfirmationInProgress"
                (click)="checkout()">{{ 'components.twint_payment.try_again' | translate }}</ion-button>

    <br>

    <ion-button expand="block" fill="outline" color="medium" *ngIf="!hasPaymentFailed && !isConfirmationInProgress"
                (click)="confirmCheckout()">{{ 'booking.confirm_booking' | translate }}</ion-button>

    <ion-button expand="block" fill="outline" color="medium" size="small" *ngIf="!isConfirmationInProgress"
                (click)="cancel()">{{ 'app.abort' | translate }}</ion-button>
  </div>
</mbsc-popup>

<mbsc-popup [options]="qrCodeSettings" #qrCodePopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <img src="./assets/logos/twint.png">
    <h1 [hidden]="hasPaymentFailed">Code: {{ token }}</h1>
    <img [hidden]="hasPaymentFailed" [src]="qrCodeSrc" alt="QRCode" *ngIf="qrCodeSrc"/>
    <h1 [hidden]="!hasPaymentFailed">{{ 'app.error' | translate }}</h1>

    <ion-button expand="block" fill="outline" color="secondary" *ngIf="hasPaymentFailed"
                (click)="checkoutWithQrCode()">{{ 'components.twint_payment.try_again' | translate }}</ion-button>
    <ion-button expand="block" fill="outline" color="medium"
                (click)="cancel()">{{ 'app.abort' | translate }}</ion-button>
  </div>
</mbsc-popup>
