import { ShellModel } from '../../../shell/data-store';
import { CustomerModel } from '../../../models/customer.model';

export class MyMembersIndexModel extends ShellModel {
  customers: Array<CustomerModel> = [
    new CustomerModel(),
    new CustomerModel(),
    new CustomerModel(),
    new CustomerModel(),
    new CustomerModel(),
    new CustomerModel(),
    new CustomerModel()
  ];

  constructor() {
    super();
  }
}
