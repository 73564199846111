<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" [disabled]="!canModalBeDismissed">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ healthQuestionnaire.providerHealthQuestionnaire.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<!--  Popups -->
<app-info-popup #infoPopupComponent></app-info-popup>
<app-success-popup #successPopupComponent></app-success-popup>

<ion-content class="edit-health-questionnaire-modal-content">
  <app-health-questionnaire [healthQuestionnaire]="healthQuestionnaire" #healthQuestionnaireComponent></app-health-questionnaire>
</ion-content>

<ion-footer>
  <ion-row class="form-actions-wrapper">
    <ion-col>
      <ion-button class="submit-btn" expand="block" color="secondary" fill="solid" (click)="saveHealthQuestionnaire()" [disabled]="!healthQuestionnaireComponent.isValid()">{{ 'app.save' | translate }}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
