import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment, STRIPE_PUBLIC_KEY_PROD, STRIPE_PUBLIC_KEY_TEST } from '../../../environments/environment';
import { EventsService } from '../../utils/events.service';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
})

export class StripePaymentComponent implements OnDestroy, AfterViewInit {
  @Input() stripeAccount: string;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter();

  stripe: any;
  elements: any;
  card: any;
  cardHandler = this.onChange.bind(this);
  errorMessage = '';

  // @ts-ignore
  @ViewChild('cardInput')
  cardInput: ElementRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private alertController: AlertController,
    private translate: TranslateService,
    private eventsService: EventsService,
    private loadingController: LoadingController
  ) {
  }

  ngOnDestroy() {
    if (this.card) {
      // Remove event listener here to keep memory clean
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  ngAfterViewInit() {
    const stripePublicKey = environment.production ? STRIPE_PUBLIC_KEY_PROD : STRIPE_PUBLIC_KEY_TEST;

    // @ts-ignore
    this.stripe = Stripe(stripePublicKey, {
      stripeAccount: this.stripeAccount
    });
    this.elements = this.stripe.elements();
    this.initiateCardElement();
  }

  initiateCardElement() {
    // Giving a base style here, but most of the style is in the scss file.
    const cardStyle = {
      base: {
        color: '#1C1C1C',
        fontFamily: 'Rubik, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        fontWeight: 700,
        '::placeholder': {
          color: '#989aa2',
        },
      },
      invalid: {
        color: '#c51d1d',
        iconColor: '#BF0404',
      },
    };
    this.card = this.elements.create('card', { hidePostalCode: true, style: cardStyle });
    this.card.mount(this.cardInput.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange({ error }) {
    if (error) {
      this.errorMessage = error.message;
    }
    else {
      this.errorMessage = '';
    }
    this.changeDetectorRef.detectChanges();
  }

  async confirmPayment(paymentIntentClientSecret: string, cardHolderName: string) {
    const that = this;
    return new Promise<void>(async function (resolve, reject) {
      const loading = that.loadingController.create({
        cssClass: 'custom-loading',
        spinner: 'crescent'
      });
      (await loading).present();
      that.stripe.confirmCardPayment(paymentIntentClientSecret, {
        payment_method: {
          card: that.card,
          billing_details: {
            name: cardHolderName
          }
        }
      }).then(async function (result) {
        if (result.error) {
          // Show error to the customer (e.g. insufficient funds)
          that.presentErrorAlert(result.error.message);
          reject();
        }
        else {
          // The payment has been processed and put on hold to be captured server side.
          if (result.paymentIntent.status === 'succeeded' || result.paymentIntent.status === 'requires_capture') {
            that.eventsService.publish('memberTicketsChanged', {});
            resolve();
          }
          else {
            reject();
          }
        }
        (await loading).dismiss();
      });
    });
  }

  async presentErrorAlert(errorMessage: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant('app.payment_failed'),
      subHeader: this.translate.instant('app.credit_card_error'),
      message: errorMessage,
      buttons: ['OK']
    });

    await alert.present();
  }
}
