import { ShellModel } from '../../shell/data-store';
import { HealthQuestions } from '../../models/health-questions.model';
import { ProviderHealthQuestionnaireModel } from '../../models/provider-health-questionnaire.model';
import { MemberHealthQuestionnaireModel } from '../../models/member-health-questionnaire.model';

export class HealthQuestionnaireModel extends ShellModel {
  providerLanguage: string;
  providerHealthQuestionnaire: ProviderHealthQuestionnaireModel;
  memberHealthQuestionnaire?: MemberHealthQuestionnaireModel;

  constructor() {
    super();
  }
}
