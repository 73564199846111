<div *ngFor="let healthQuestion of providerHealthQuestionnaire.healthQuestions; index as questionIndex" class="health-questionnaire-infos">
  <ion-item-divider>
    <ion-label class="question-label">
      {{translateMultilingualField(healthQuestion.question, currentLanguage, providerLanguage)}}
    </ion-label>
  </ion-item-divider>
  <div *ngIf="healthQuestion.type == QuestionType.YesNo">
    <ion-list>
      <ion-item *ngIf="memberHealthQuestionnaire.answers[questionIndex][0] === 'true'" class="highlight-answer">
        {{ 'app.yes' | translate }}
      </ion-item>
      <ion-item *ngIf="memberHealthQuestionnaire.answers[questionIndex][0] === 'false'">
        {{ 'app.no' | translate }}
      </ion-item>
    </ion-list>
  </div>
  <div *ngIf="healthQuestion.type == QuestionType.Freetext">
    <ion-list>
      <ion-item>
        {{memberHealthQuestionnaire.answers[questionIndex][0]}}
      </ion-item>
    </ion-list>
  </div>
  <div *ngIf="healthQuestion.type == QuestionType.MultipleChoiceSingleAnswer">
    <ion-list>
      <ion-item>
        &middot; {{translateMultipleChoiceQuestion(healthQuestion.answers, memberHealthQuestionnaire.answers[questionIndex][0])}}
      </ion-item>
    </ion-list>
  </div>
  <div *ngIf="healthQuestion.type == QuestionType.MultipleChoiceMultipleAnswers">
    <ion-list>
      <ion-item *ngFor="let answerIndex of memberHealthQuestionnaire.answers[questionIndex]">
        &middot; {{translateMultipleChoiceQuestion(healthQuestion.answers, answerIndex)}}
      </ion-item>
    </ion-list>
  </div>
</div>
