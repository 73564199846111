import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { Platform } from '@ionic/angular';
import { ProfileService } from '../../profile/profile.service';
import { LaunchReview } from '@ionic-native/launch-review/ngx';

@Component({
  selector: 'app-rate-us-popup',
  templateUrl: './rate-us-popup.component.html',
  styleUrls: ['./rate-us-popup.component.scss'],
})
export class RateUsPopupComponent implements OnInit {
  @Input() profileService: ProfileService;

  settings: MbscPopupOptions = {
    display: 'center',
    buttons: []
  };

  resolve: any;

  // @ts-ignore
  @ViewChild('popup')
  popup: any;

  message: string;

  constructor(
    public platform: Platform,
    private launchReview: LaunchReview
  ) { }

  ngOnInit() {}

  show() {
    this.popup.instance.show();
  }

  close() {
    this.popup.instance.hide();
  }

  openAppStoreReview() {
    const that = this;
    if (this.launchReview.isRatingSupported()) {
      /***
       * Note: The rate us prompt on iOS does not show in TestFlight and only a limited amount of times in Production.
       * https://stackoverflow.com/questions/45057452/why-the-skstorereviewcontroller-does-not-let-me-submit-a-review/45061556#45061556
       *
       * Also, testing on Android is very tricky as well. See:
       * https://stackoverflow.com/questions/63286540/play-core-in-app-review-api-not-showing-the-review-activity
       */
      let hasRatingViewShown = false;
      this.launchReview.rating().subscribe((value) => {
          console.log('launchReview.rating() value: ', value);
          if (value === 'shown' || value === 'requested' && this.platform.is('android')) {
            hasRatingViewShown = true;
            that.profileService.acceptRateUs().subscribe();
            that.popup.instance.hide();
          }
        },
        (error) => {
          console.log('launchReview.rating() failed', error);
        }
      );

      // Fallback to open the App Store if the in-app dialog fails to show.
      setTimeout(() => {
        if (!hasRatingViewShown) {
          this.launchReview.launch()
            .then(() => {
              console.log('launchReview.launch() successful');
              that.profileService.acceptRateUs().subscribe();
              that.popup.instance.hide();
            })
            .catch((reason) => {
              console.log('launchReview.launch() failed', reason);
            });
        }
      }, 2000);
    }
    else {
      this.launchReview.launch()
        .then(() => {
          console.log('launchReview.launch() successful');
          that.profileService.acceptRateUs().subscribe();
          that.popup.instance.hide();
        })
        .catch((reason) => {
          console.log('launchReview.launch() failed', reason);
        });
    }
  }
}
