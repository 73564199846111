import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { LoadingController } from '@ionic/angular';
import { ScheduleService } from '../../provider/schedule/schedule.service';
import { ServiceSessionModel } from '../../models/service-session.model';
import { TranslateService } from '@ngx-translate/core';
import { RateUsService } from '../rate-us-home-card/rate-us.service';

@Component({
  selector: 'app-fully-booked-popup',
  templateUrl: './fully-booked-popup.component.html',
  styleUrls: ['./fully-booked-popup.component.scss'],
})
export class FullyBookedPopupComponent implements OnInit {
  serviceSession: ServiceSessionModel;

  // @ts-ignore
  @ViewChild('fullyBookedPopup')
  fullyBookedPopup: any;

  // @ts-ignore
  @ViewChild('successPopupComponent')
  successPopupComponent: any;

  // @ts-ignore
  @ViewChild('infoPopupComponent')
  infoPopupComponent: any;

  popupSettings: MbscPopupOptions = {
    display: 'center',
    closeOnOverlayTap: false,
    onSet: (event, inst) => {
      this.resolve();
    }
  };

  resolve: any;

  constructor(
    private loadingController: LoadingController,
    private scheduleService: ScheduleService,
    private translate: TranslateService,
    private rateUsService: RateUsService
  ) { }

  ngOnInit() {}

  show(serviceSession: ServiceSessionModel) {
    this.serviceSession = serviceSession;
    this.fullyBookedPopup.instance.show();
    const that = this;
    return new Promise(function (resolve) {
      that.resolve = resolve;
    });
  }

  async addToWaitingList() {
    const that = this;
    const loading = that.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();

    that.scheduleService.addToWaitingList(
      that.serviceSession
    ).subscribe({
        async next(response) {
          (await loading).dismiss();

          that.fullyBookedPopup.instance.hide();

          switch (response.status) {
            case 'booking_status_success':
              that.successPopupComponent.show(that.translate.instant('app.alright'), that.translate.instant('booking.added_waiting_list_successful')).then(() => {
                that.resolve();
              });

              // Ask for app review one second after the success popup shows.
              if (response.shouldPromptRateAppDialog) {
                that.rateUsService.showRateAppDialog(1000);
              }
              break;
            case 'bookings_status_free_slot_just_became_available':
              that.infoPopupComponent.show(that.translate.instant('booking.spot_just_available')).then(() => {
                that.resolve();
              });
              break;
          }
        },
        async error(message) {
          (await loading).dismiss();
          that.fullyBookedPopup.instance.hide();
        }
      }
    );
  }
}
