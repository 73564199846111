<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'admin_area.activate_premium' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="vertical-align-center">
    <ion-grid>
      <ion-row>
        <ion-col class="icon-wrapper">
          <ion-icon name="star-outline"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-center">
          <p>{{ 'admin_area.all_from_one_source' | translate }}</p>
          <p>
            <ion-icon name="heart-outline"></ion-icon>
            {{ 'admin_area.add_on_system' | translate }}</p>
          <p>
            <ion-icon name="thumbs-up-outline"></ion-icon>
            {{ 'admin_area.cancel_at_any_time' | translate }}</p>
          <p>
            <ion-icon name="gift-outline"></ion-icon>
            {{ 'admin_area.trial_version' | translate }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>

<ion-footer>
  <ion-row>
    <ion-col>
      <ion-button expand="block" color="secondary" fill="solid"
                  (click)="openMyAccount()">{{ 'app.know_more' | translate }}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>