import { Component, Input, OnInit } from '@angular/core';
import { ServiceSessionModel } from '../../models/service-session.model';

@Component({
  selector: 'app-service-session-status',
  templateUrl: './service-session-status.component.html',
  styleUrls: ['./service-session-status.component.scss'],
})
export class ServiceSessionStatusComponent implements OnInit {
  @Input() serviceSession: ServiceSessionModel
  constructor() { }

  ngOnInit() {}

}
