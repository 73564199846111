import { ShellModel } from '../../../shell/data-store';
import { BookingModel } from '../../../models/booking.model';

export class MemberTicketBookingsModel extends ShellModel {
  bookings: Array<BookingModel>;

  constructor() {
    super();
  }
}
