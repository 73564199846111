import { ShellModel } from '../../shell/data-store';
import { BookingModel } from '../../models/booking.model';

export class MyWaitingListModel extends ShellModel {
  bookings: Array<BookingModel> = [];

  constructor() {
    super();
  }
}
