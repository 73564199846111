import { ShellModel } from '../../shell/data-store';
import { ServiceSessionModel } from '../../models/service-session.model';
import { CustomerModel } from '../../models/customer.model';
import { ProviderModel } from '../../models/provider.model';

export class AppointmentListModel {
  category: string;
  serviceSessions: Array<ServiceSessionModel>;
}

export class WeekOverviewModel {
  weekday: string;
  serviceSessions: Array<ServiceSessionModel>;
}

export class ScheduleModel extends ShellModel {
  providerId: string;
  provider: ProviderModel;
  liveCalendar: Array<ServiceSessionModel>;
  weekOverview: Array<WeekOverviewModel>;
  workshops: Array<ServiceSessionModel>;
  appointments: Array<AppointmentListModel>;
  hasClasses: boolean;
  hasWorkshops: boolean;
  hasAppointments: boolean;
  hasMemberTickets: boolean;
  customer: CustomerModel;
  isCustomer: boolean;

  constructor() {
    super();
  }
}
