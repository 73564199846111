// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: true,
  version: '8.5',
  useSampleData: false,
};

// export const SERVER_URL = 'http://localhost:3000/api/mobile';
// export const SERVER_URL = 'https://04e3-62-167-129-114.ngrok-free.app/api/mobile';
export const SERVER_URL = 'https://www.sportsnow.ch/api/mobile';

export const STRIPE_PUBLIC_KEY_TEST = 'pk_test_quxjF38eP8VqeHfNAKNjoyk2';
export const STRIPE_PUBLIC_KEY_PROD = 'pk_live_a1nKdZPoE9TvtuunA3XBqWTk';

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
