import { ShellModel } from '../../shell/data-store';

export class PersonalInformationModel extends ShellModel {
  language: string;
  gender: string;
  company: string;
  firstName: string;
  lastName: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  phoneMobile: string;
  phonePrivate: string;
  phoneWork: string;
  birthday: string;
  hasSeparateBillingAddress: boolean;
  billingCompany: string;
  billingFirstName: string;
  billingLastName: string;
  billingAddress: string;
  billingZipCode: string;
  billingCity: string;
  billingCountry: string;
  email: string;
  socialSecurityNumber: string;
  insurance: string;

  constructor() {
    super();
  }
}
