import { Injectable, Injector } from '@angular/core';
import { Storage } from '@ionic/storage';
import { EventsService } from '../utils/events.service';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { HomeService } from '../home/home.service';

@Injectable({
  providedIn: 'root'
})

/**
 * This service provides global variables which are initialized with the app component.
 */
export class AuthenticationService {
  static isAuthenticated = false;
  static isFamilyMember = false;
  static authenticationToken = '';
  static memberId: number;

  constructor(
    private storage: Storage,
    private eventsService: EventsService,
    private _injector: Injector,
    private navController: NavController,
    private homeService: HomeService
  ) {}

  // Helper property to resolve the service dependency.
  private get router() { return this._injector.get(Router); }

  init() {
    return new Promise<void>((resolve, reject) => {
      console.log("AuthenticationService.init() called");
      this.storage.get('authenticationToken').then((value) => {
        AuthenticationService.isAuthenticated = (value != undefined);
        AuthenticationService.authenticationToken = (value != undefined) ? value : '';
        this.storage.get('memberId').then((value) => {
          AuthenticationService.memberId = value;
          console.log("AuthenticationService.init() completed");
          resolve();
        });
      });
    });
  }

  login(authenticationToken: string, memberId: number) {
    this.storage.set('authenticationToken', authenticationToken);
    this.storage.set('memberId', memberId);
    AuthenticationService.isAuthenticated = true;
    AuthenticationService.authenticationToken = authenticationToken;
    AuthenticationService.memberId = memberId;
    AuthenticationService.isFamilyMember = false;

    // Set root navigation to Home to prevent going back.
    this.navController.navigateRoot(['/app/home']).then(r => {});
  }

  logout() {
    this.eventsService.publish('logout', {});
    this.storage.remove('authenticationToken');
    this.storage.remove('memberId');
    this.storage.remove('providers');
    this.storage.remove('defaultProvider');
    this.storage.remove('isAdminModeEnabled');
    AuthenticationService.isAuthenticated = false;
    AuthenticationService.authenticationToken = '';
    AuthenticationService.memberId = undefined;
    this.homeService.resetProviderColors();
    this.router.navigate(['/welcome'], {replaceUrl: true});
  }
}
