import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: 'auth/login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'auth/signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule) },
  {
    path: 'auth/forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  { path: 'app', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'my-bookings',
    loadChildren: () => import('./profile/my-bookings/my-bookings.module').then(m => m.MyBookingsPageModule)
  },
  {
    path: 'my-passes',
    loadChildren: () => import('./profile/my-passes/my-passes.module').then(m => m.MyPassesPageModule)
  },
  {
    path: 'my-check-ins',
    loadChildren: () => import('./profile/my-check-ins/my-check-ins.module').then(m => m.MyCheckInsPageModule)
  },
  {
    path: 'my-videos',
    loadChildren: () => import('./profile/my-videos/my-videos.module').then(m => m.MyVideosPageModule)
  },
  {
    path: 'my-files',
    loadChildren: () => import('./profile/my-files/my-files.module').then(m => m.MyFilesPageModule)
  },
  {
    path: 'my-watch-list',
    loadChildren: () => import('./profile/my-watch-list/my-watch-list.module').then(m => m.MyWatchListPageModule)
  },
  {
    path: 'my-waiting-list',
    loadChildren: () => import('./profile/my-waiting-list/my-waiting-list.module').then(m => m.MyWaitingListPageModule)
  },
  {
    path: 'my-family',
    loadChildren: () => import('./profile/my-family/my-family.module').then(m => m.MyFamilyPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./profile/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'personal-information',
    loadChildren: () => import('./profile/personal-information/personal-information.module').then(m => m.PersonalInformationPageModule)
  },
  {
    path: 'find-provider',
    loadChildren: () => import('./providers/find/find-provider.module').then(m => m.FindStudioPageModule)
  },
  {
    path: 'open-participants-lists/:serviceSessionId/:date',
    loadChildren: () => import('./open-participants-lists/open-participants-lists.module').then(m => m.OpenParticipantsListsPageModule)
  },
  {
    path: 'change-email',
    loadChildren: () => import('./profile/change-email/change-email.module').then(m => m.ChangeEmailPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./profile/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'provider',
    children: [
      {
        path: ':providerId',
        loadChildren: () => import('./provider/start/start.module').then(m => m.StartPageModule)
      },
      {
        path: ':providerId/check-in',
        loadChildren: () => import('./check-in/check-in.module').then(m => m.CheckInPageModule)
      },
      {
        path: ':providerId/schedule',
        loadChildren: () => import('./provider/schedule/schedule.module').then(m => m.SchedulePageModule)
      },
      {
        path: ':providerId/pricing',
        loadChildren: () => import('./provider/pricing/pricing.module').then(m => m.PricingPageModule)
      },
      {
        path: ':providerId/news-index',
        loadChildren: () => import('./provider/news/index/news-index.module').then(m => m.NewsIndexPageModule)
      },
      {
        path: ':providerId/news-show/:newsId',
        loadChildren: () => import('./provider/news/show/news-show.module').then(m => m.NewsShowPageModule)
      },
      {
        path: ':providerId/video-player/:videoId',
        loadChildren: () => import('./provider/videos/video-player/video-player.module').then(m => m.VideoPlayerPageModule)
      },
      {
        path: ':providerId/video-overview',
        loadChildren: () => import('./provider/videos/video-overview/video-overview.module').then(m => m.VideoOverviewPageModule)
      },
      {
        path: ':providerId/video-category/:categoryId',
        loadChildren: () => import('./provider/videos/video-category/video-category.module').then(m => m.VideoCategoryPageModule)
      },
      {
        path: ':providerId/create-rating',
        loadChildren: () => import('./provider/create-rating/create-rating.module').then(m => m.CreateRatingPageModule)
      }
    ]
  },
  {
    path: 'admin-area',
    children: [
      {
        path: ':providerId/participants-lists',
        loadChildren: () => import('./admin-area/participants-lists/participants-lists.module').then(m => m.ParticipantsListsModule)
      },
      {
        path: ':providerId/my-appointments/:date',
        loadChildren: () => import('./admin-area/my-appointments/my-appointments.module').then(m => m.MyAppointmentsPageModule)
      },
      {
        path: ':providerId/my-members',
        loadChildren: () => import('./admin-area/my-members/my-members.module').then(m => m.MyMembersModule)
      },
      {
        path: ':providerId/point-of-sale',
        loadChildren: () => import('./admin-area/point-of-sale/point-of-sale.module').then(m => m.PointOfSaleModule)
      },
      {
        path: ':providerId/messages',
        loadChildren: () => import('./admin-area/messages/messages.module').then(m => m.MessagesPageModule)
      },
      {
        path: ':providerId/check-in-terminal',
        loadChildren: () => import('./admin-area/check-in-terminal/check-in-terminal.module').then(m => m.CheckInTerminalPageModule)
      },
      {
        path: ':providerId/assign-badge/:customerId',
        loadChildren: () => import('./admin-area/assign-badge/assign-badge.module').then(m => m.AssignBadgePageModule)
      },
      {
        path: ':providerId/open-invoices/:customerId',
        loadChildren: () => import('./admin-area/my-members/open-invoices/open-invoices.module').then(m => m.OpenInvoicesPageModule)
      }
    ]
  },
  {
    path: 'more',
    loadChildren: () => import('./more/more.module').then(m => m.MorePageModule)
  },
  { path: '**', redirectTo: 'app/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
