import { Component, Input, OnInit } from '@angular/core';
import { MemberTicketModel } from '../../models/member-ticket.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'app-member-ticket-card-content',
  templateUrl: './member-ticket-card-content.component.html',
  styleUrls: ['./member-ticket-card-content.component.scss'],
})
export class MemberTicketCardContentComponent implements OnInit {
  @Input() memberTicket: MemberTicketModel
  @Input() showPaymentInformation = true;

  constructor(
    private translate: TranslateService,
    private toastController: ToastController
  ) { }

  ngOnInit() {}

  async writeToClipboard(value: string) {
    Clipboard.write({
      string: value
    });
    const toast = await this.toastController.create({
      message: this.translate.instant('app.copied_to_clipboard'),
      color: 'dark',
      duration: 2000
    });
    toast.present();
  }
}
