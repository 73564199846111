<!-- stripe-payment.component.html -->
<form class="card-input-form" (ngSubmit)="onFormSubmit.emit()">
  <div id="card-input" #cardInput></div>

  <div class="error-container">
    <div class="error-message" *ngIf="errorMessage !== ''">
      <ion-icon name="information-circle-outline"></ion-icon>
      {{ errorMessage }}
    </div>
  </div>
</form>
