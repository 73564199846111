import { ShortHealthQuestionnaireModel } from './short-health-questionnaire.model';

export class CustomerModel {
  id: number;
  accountBalance: string;
  address: string;
  badgeId: string;
  birthday: string;
  company: string;
  customerSince: string;
  discount: string;
  device: string;
  email: string;
  gender: string;
  hasHealthProblems: boolean;
  shortHealthQuestionnaires: Array<ShortHealthQuestionnaireModel>;
  isDeactivated: boolean;
  isFamilyMember: boolean;
  memberId: number;
  language: string;
  name: string;
  notes: string;
  phoneMobile: string;
  phonePrivate: string;
  phoneWork: string;
  profilePicture: string;
  socialSecurityNumber: string;
  insurance: string;
  covidCertificate: {
    certificateType: string;
    validUntil: string;
  }
}
