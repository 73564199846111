<div class="vertical-align-center">
  <ion-grid>
    <ion-row>
      <ion-col class="icon-wrapper">
        <ion-icon name="cloud-offline-outline"></ion-icon>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <h5>{{ 'app.connection_problem' | translate }}</h5>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button color="secondary" fill="outline" (click)="emitRetry()"
                    size="small">{{ 'app.try_again' | translate }}</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button color="medium" fill="outline" (click)="openSystemStatusPage()"
                    size="small">{{ 'app.system_status' | translate }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
