import { StaffModel } from './staff.model';
import { ProviderModel } from './provider.model';

export class ServiceSessionModel {
  id: number;
  additionalInformation: string;
  bookingId: number;
  bookingInformation: string;
  bookings: number;
  cancellationReason: string;
  capacity: number;
  color: string;
  date: string;
  dateBeginEnd: string;
  datetime: string;
  description: string;
  freeSpots: string;
  hasLivestream: boolean;
  hasMemberTickets: boolean;
  image: string;
  isAlmostFullyBooked: boolean;
  isAlreadyBooked: boolean;
  isAlreadyOnWaitingList: boolean;
  isAlreadyOnWatchList: boolean;
  isBookable: boolean;
  isBookingLimitReached: boolean;
  isCancelled: boolean;
  isClosed: boolean;
  isFreeOfCharge: boolean;
  isFullyBooked: boolean;
  isLivestreamBookingEnabled: boolean;
  isModified: boolean;
  isNotOpenYet: boolean;
  isParticipantsListOpen: boolean;
  isWaitingListDisabled: boolean;
  livestreamBookings: string;
  localizedDateTime: string;
  localizedRecurrenceName: string;
  localizedLessonCount: string;
  localizedResponsibleStaffs: string;
  location: string;
  name: string;
  notes: string;
  provider: ProviderModel;
  recurrence: string;
  staffs: Array<StaffModel>;
  team: string;
  time: string;
  timeBegin: string;
  timeEnd: string;
  weekday: string;
  hasStarted: boolean;
}
