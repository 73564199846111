<mbsc-card collapsible #healthQuestionnaireCard [hidden]="healthQuestionnaires == undefined || healthQuestionnaires.length === 0 || !areAllHealthQuestionnairesUsable() || externalHideCondition">
  <mbsc-card-header>
    <mbsc-card-title>
      <ion-icon name="checkmark-circle-outline"
                *ngIf="healthQuestionnaireComponents != undefined && areAllHealthQuestionnairesValid()"></ion-icon>
      {{ 'app.health_questionnaire' | translate }}</mbsc-card-title>
  </mbsc-card-header>
  <mbsc-card-content>
    <div *ngFor="let healthQuestionnaire of healthQuestionnaires">
      <div class="health-questionnaire-name">{{healthQuestionnaire.providerHealthQuestionnaire.name}}</div>
      <div class="health-questionnaire-component-wrapper">
        <app-health-questionnaire  [healthQuestionnaire]="healthQuestionnaire"
                                   (onComplete)="onHealthQuestionnaireComplete(healthQuestionnaire.providerHealthQuestionnaire.id)"
                                   #healthQuestionnaireComponents></app-health-questionnaire>
      </div>
    </div>
  </mbsc-card-content>
</mbsc-card>
