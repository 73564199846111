<app-success-popup #successPopupComponent></app-success-popup>
<app-warning-popup #warningPopupComponent></app-warning-popup>

<mbsc-popup [options]="popupSettings" #alreadyBookedPopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <img class="popup-icon" src="./assets/custom-icons/popups/success.png">
    <h3>{{ 'schedule.already_booked' | translate }}</h3>
    <p>{{ 'booking.have_fun' | translate }}</p>

    <ion-button expand="block" color="secondary" type="submit" fill="outline" (click)="openMyBookings()">{{ 'profile.my_bookings' | translate }}</ion-button>

    <ng-container *ngIf="serviceSession != undefined && serviceSession.bookingId != undefined">
      <br>
      <ion-button expand="block" color="medium" size="small" fill="outline" (click)="cancelBooking()">{{ 'booking.cancel_booking' | translate }}</ion-button>
    </ng-container>
  </div>
</mbsc-popup>
