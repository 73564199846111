import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment, SERVER_URL } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../http/http.service';
import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';

@Injectable()
export class RekaService {
  constructor(
    private http: HttpClient,
    private iab: InAppBrowser) { }

  public confirmRekaCheckout(invoiceId: number, orderId: string): Observable<{
    status: string
  }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/ok.json');
    }

    const body = {}
    if (invoiceId != undefined) {
      body['invoiceId'] = invoiceId;
    }
    if (orderId != undefined) {
      body['orderId'] = orderId;
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/reka/confirm_checkout', body);
  }

  public createRekaCheckout(ticketId: number, couponId: number, invoiceId: number, amount: number, providerId: string, serviceSessionId: number, bookingDate: string): Observable<{
    status: string,
    orderId: string
  }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, orderId: string }>('./assets/sample-data/reka/checkout.json');
    }

    const body = {
      'redirectUrl': 'http://localhost?psp=sportsnow'
    }
    if (ticketId != undefined) {
      body['ticketId'] = ticketId;
    }
    if (couponId != undefined) {
      body['couponId'] = couponId;
    }
    if (invoiceId != undefined) {
      body['invoiceId'] = invoiceId;
    }
    if (amount != undefined) {
      body['amount'] = amount;
    }
    if (providerId != undefined) {
      body['providerId'] = providerId;
    }
    if (serviceSessionId != undefined) {
      body['serviceSessionId'] = serviceSessionId;
    }
    if (bookingDate != undefined) {
      body['bookingDate'] = bookingDate;
    }

    return new Observable((observer) => {
      this.http
        .post<{ paymentTokenId: string; redirectLocationUrl: string }>(
          SERVER_URL + '/v1/reka/create_checkout',
          body
        )
        .pipe(
          catchError(HttpService.handleError)
        )
        .subscribe({
          next: (data) => {
            const options: string = 'location=yes,clearcache=no';
            const inAppBrowser: InAppBrowserObject = this.iab.create(data.redirectLocationUrl, '_blank', options);

            // Handle URL changes inside the inAppBrowser
            inAppBrowser.on('loadstart').subscribe((event) => {
              console.log('inAppBrowser event', event);
              const url = event.url;
              if (url.includes('status')) {
                const urlParams = new URLSearchParams(url.split('?')[1]);
                const status = urlParams.get('status');
                const orderId = urlParams.get('order_id');
                switch (status) {
                  case 'success':
                    observer.next({ status, orderId });
                    observer.complete();
                    break;
                  case 'cancel':
                    break;
                  default:
                    HttpService.presentErrorAlert();
                }
                inAppBrowser.close();
              }
            });
          },
          error: (message) => {
            HttpService.presentErrorAlert();
            observer.error(message);
          }
        });
    });

  }
}
