import { Component, OnInit } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

@Component({
  selector: 'app-push-notifications-deactivated',
  templateUrl: './push-notifications-deactivated.component.html',
  styleUrls: ['./push-notifications-deactivated.component.scss'],
})
export class PushNotificationsDeactivatedComponent implements OnInit {

  constructor(private diagnostic: Diagnostic) { }

  ngOnInit() {}

  openSettings() {
    this.diagnostic.switchToSettings();
  }
}
