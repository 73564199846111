import { StaffModel } from './staff.model';
import { ProviderModel } from './provider.model';
import { ServiceSessionModel } from './service-session.model';
import { MemberTicketModel } from './member-ticket.model';
import { CustomerModel } from './customer.model';
import { ShortHealthQuestionnaireModel } from './short-health-questionnaire.model';

export class BookingModel {
  id: number;
  address: string;
  availableStaff: Array<StaffModel>;
  calendarEventId: string;
  cancellationDeadline: string;
  createdDateTime: string;
  createdTime: string;
  customer: CustomerModel;
  customerNote: string;
  date: string;
  dateTime: string;
  dateTimeBegin: string;
  dateTimeEnd: string;
  hasHealthProblems: boolean;
  hasOpenPayments: boolean;
  hasShowedUp: boolean;
  shortHealthQuestionnaires: Array<ShortHealthQuestionnaireModel>;
  isCancelled: boolean;
  isIndividualAppointment: boolean;
  isLivestreamBooking: boolean;
  isMissing: boolean;
  isWaitingListBooking: boolean;
  livestreamUrl: string;
  localizedDate: string;
  location: string;
  memberTicket: MemberTicketModel;
  provider: ProviderModel;
  repetition: string;
  serviceSession: ServiceSessionModel;
  staff: StaffModel;
  team: string;
  time: string;
  updatedTime: string;
  additionalInformation: string;
  bookingInformation: string;
  definitiveBookingInformation: string;
}
