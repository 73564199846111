import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment, SERVER_URL } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../http/http.service';
import { TicketModel } from '../../models/ticket.model';
import { CouponModel } from '../../models/coupon.model';
import { MemberTicketModel } from '../../models/member-ticket.model';

@Injectable()
export class StripeService {
  constructor(private http: HttpClient) { }

  public checkout(ticket: TicketModel, coupon: CouponModel, memberTicket: MemberTicketModel, captureMethod: string): Observable<{ paymentIntentClientSecret: string, stripeCheckoutSuccessToken: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ paymentIntentClientSecret: string, stripeCheckoutSuccessToken: string }>('./assets/sample-data/stripe/checkout.json');
      // return this.http.get<{paymentIntentClientSecret: string}>('./assets/sample-data/not-found.json');
    }

    const body = {}
    if (ticket != undefined) {
      body['ticketId'] = ticket.id;
    }
    if (coupon != undefined) {
      body['couponId'] = coupon.id;
    }
    if (memberTicket != undefined) {
      body['invoiceId'] = memberTicket.invoiceId;
    }
    body['captureMethod'] = captureMethod

    return this.http.post<{ paymentIntentClientSecret: string, stripeCheckoutSuccessToken: string }>(SERVER_URL + '/v1/stripe/create_checkout', body).pipe(
      catchError(HttpService.handleError)
    );
  }
}
