<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'my_members.health_questionnaires' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-select-health-questionnaire #selectHealthQuestionnaireComponent [providerId]="providerId"
                                   [customerId]="customerId" [bookingId]="bookingId"
                                   [shortProviderHealthQuestionnaires]="shortHealthQuestionnaires"
                                   [showIcons]="showIcons" [presentEditModalDirectly]="presentEditModalDirectly" (healthQuestionnaireChanged)="healthQuestionnaireHasChanged()"></app-select-health-questionnaire>
</ion-content>

<ion-footer></ion-footer>
