import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { EventsService } from '../utils/events.service';
import { Observable } from 'rxjs';
import { ProviderModel } from '../models/provider.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment, SERVER_URL } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { CustomerModel } from '../models/customer.model';
import { CreateMemberTicketModel } from './my-members/create-member-ticket/create-member-ticket.model';

@Injectable({
  providedIn: 'root'
})

export class AdminAreaService {
  static isAdminModeEnabled = false;
  static defaultProvider: ProviderModel;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    private eventsService: EventsService,
  ) {
    this.eventsService.subscribe('adminModeChanged', (data: any) => {
      AdminAreaService.isAdminModeEnabled = data.isAdminModeEnabled;
    });
  }

  init() {
    return new Promise<void>((resolve) => {
      console.log('AdminAreaService.init() called');
      this.storage.get('isAdminModeEnabled').then((value) => {
        AdminAreaService.isAdminModeEnabled = (value != undefined);
        console.log('AdminAreaService.init() completed');
        resolve();
      });
    });
  }

  // Admin
  public getProviders(): Observable<{ providers: Array<ProviderModel> }> {
    if (environment.useSampleData) {
      // return this.http.get<{ providers: Array<ProviderModel> }>('./assets/sample-data/admin-area/no-providers.json');
      return this.http.get<{ providers: Array<ProviderModel> }>('./assets/sample-data/admin-area/single_provider.json');
      // return this.http.get<{ providers: Array<ProviderModel> }>('./assets/sample-data/admin-area/multiple_providers.json');
    }

    return this.http.get<{ providers: Array<ProviderModel> }>(SERVER_URL + '/v1/admin_area/providers').pipe(
      catchError(HttpService.handleError)
    );
  }

  public lookupBadge(providerId: string, badgeId: number[], qrCode: string): Observable<{ customer: CustomerModel }> {
    if (environment.useSampleData) {
      return this.http.get<{ customer: CustomerModel }>('./assets/sample-data/admin-area/lookup-badge-empty.json');
      // return this.http.get<{ customer: CustomerModel }>('./assets/sample-data/admin-area/lookup-badge.json');
    }

    return this.http.post<{ customer: CustomerModel }>(SERVER_URL + '/v1/admin_area/' + providerId + '/customers/lookup_badge', {badgeId: badgeId, qrCode: qrCode}).pipe(
      catchError(HttpService.handleError)
    );
  }

  public searchCustomer(providerId: string, query: string): Observable<{ customers: Array<CustomerModel> }> {
    if (environment.useSampleData) {
      return this.http.get<{ customers: Array<CustomerModel> }>('./assets/sample-data/admin-area/search-customer.json');
    }

    let params = new HttpParams()
      .set('query', String(query));

    return this.http.get<{ customers: Array<CustomerModel> }>(SERVER_URL + '/v1/admin_area/' + providerId + '/customers/search', {params: params}).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getTicketsAndPaymentMethods(providerId: string): Observable<CreateMemberTicketModel> {
    if (environment.useSampleData) {
      return this.http.get<CreateMemberTicketModel>('./assets/sample-data/admin-area/my-members/tickets_and_payment_methods.json');
    }

    return this.http.get<CreateMemberTicketModel>(SERVER_URL + '/v1/admin_area/' + providerId + '/tickets_and_payment_methods').pipe(
      catchError(HttpService.handleError)
    );
  }
}
