<mbsc-popup [options]="popupSettings" #fullyBookedPopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <img class="popup-icon" src="./assets/custom-icons/popups/error.png" alt="Error">
    <h3>{{ 'booking.fully_booked' | translate }}</h3>
    <p *ngIf="serviceSession && serviceSession.provider && !serviceSession.provider.isAutomaticBookingWaitingListEnabled">{{ 'schedule.ask_waiting_list_notification' | translate }}</p>
    <p *ngIf="serviceSession && serviceSession.provider && serviceSession.provider.isAutomaticBookingWaitingListEnabled && serviceSession.provider.isTicketPurchaseDisabled">{{ 'schedule.ask_waiting_list_booking' | translate }}</p>
    <p *ngIf="serviceSession && serviceSession.provider && serviceSession.provider.isAutomaticBookingWaitingListEnabled && !serviceSession.provider.isTicketPurchaseDisabled">{{ 'schedule.ask_waiting_list_booking_with_pass' | translate }}</p>

    <ion-button expand="block" color="secondary" type="submit" fill="outline" (click)="addToWaitingList()">{{ 'booking.add_to_waiting_list' | translate }}</ion-button>
  </div>
</mbsc-popup>

<app-info-popup #infoPopupComponent></app-info-popup>
<app-success-popup #successPopupComponent></app-success-popup>
