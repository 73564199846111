import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MyMembersService } from '../my-members.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MbscCalendarOptions } from '@mobiscroll/angular';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-add-covid-certificate',
  templateUrl: './add-covid-certificate.modal.html',
  styleUrls: ['./add-covid-certificate.modal.scss'],
})
export class AddCovidCertificateModal implements OnInit {
  @Input() providerId: string;
  @Input() customerId: number;
  covidCertificateForm: FormGroup;
  covidCertificateTypes: Array<{ value: string; name: string }>;

  // @ts-ignore
  @ViewChild('infoPopupComponent')
  infoPopupComponent: any;

  // @ts-ignore
  @ViewChild('successPopupComponent')
  successPopupComponent: any;

  datePickerSettings: MbscCalendarOptions = {
    lang: this.translate.currentLang,
    theme: 'mobiscroll',
    themeVariant: 'light',
    display: 'center',
    inputStyle: 'box',
    min: dayjs().add(1, 'day').toDate()
  };

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private myMembersService: MyMembersService,
    private loadingController: LoadingController
  ) {
    this.covidCertificateTypes = [];
    this.covidCertificateTypes.push({
      value: 'vaccination',
      name: this.translate.instant('my_members.certificate_type_vaccination')
    });
    this.covidCertificateTypes.push({
      value: 'test',
      name: this.translate.instant('my_members.certificate_type_test')
    });
    this.covidCertificateTypes.push({
      value: 'recovery',
      name: this.translate.instant('my_members.certificate_type_recovery')
    });

    this.covidCertificateForm = new FormGroup({
      'certificate_type': new FormControl('', Validators.required),
      'valid_until': new FormControl('', Validators.required)
    });
  }

  ngOnInit() {

  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async addCovidCertificate() {
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();
    this.myMembersService.addCovidCertificate(this.providerId, this.customerId, dayjs(this.covidCertificateForm.get('valid_until').value).format('YYYY-MM-DD') as string, this.covidCertificateForm.get('certificate_type').value).subscribe({
        async next(data) {
          (await loading).dismiss();
          switch (data.status) {
            case 'covid_invalid_2g_plus':
              that.infoPopupComponent.show(that.translate.instant('my_members.covid_certificate_invalid_2g_plus'));
              break;
            case 'covid_type_invalid':
              that.infoPopupComponent.show(that.translate.instant('my_members.covid_certificate_invalid_type'));
              break;
            default:
              that.successPopupComponent.show(that.translate.instant('app.alright'), that.translate.instant('my_members.add_covid_certificate_successful')).then(() => {
                that.modalController.dismiss({ customerId: that.customerId });
              });
          }
        },
        async error(message) {
          (await loading).dismiss();
        }
      }
    );
  }

}
