import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.modal.html',
  styleUrls: ['./disclaimer.modal.scss'],
})
export class DisclaimerModal implements OnInit {
  @Input() disclaimer: string;
  hasAccepted: boolean;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  acceptDisclaimer() {
    this.modalController.dismiss({});
  }
}
