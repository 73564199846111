<mbsc-card-content>
  <mbsc-card-title>{{ memberTicket.name }}</mbsc-card-title>
  <p class="label">{{ 'app.studio' | translate }}</p>
  <p class="value">{{ memberTicket.provider.name }}</p>

  <p class="label">{{ 'my_passes.validity' | translate }}</p>
  <p class="value">{{ memberTicket.validity }}</p>

  <ng-container *ngIf="memberTicket.pauses != undefined && memberTicket.pauses.length > 0">
    <p class="label">{{ 'my_members.pause' | translate }}</p>
    <p class="value" *ngFor="let pause of memberTicket.pauses">{{ pause.pauseFrom }} - {{ pause.pauseUntil}} ({{ pause.description }})</p>
  </ng-container>

  <p class="label">{{ 'my_passes.available_credit' | translate }}</p>
  <p class="value">{{ memberTicket.localizedCredits }}</p>

  <p class="label"
     *ngIf="memberTicket.status != undefined && memberTicket.status !== ''">{{ 'app.status' | translate }}</p>
  <p class="value" *ngIf="memberTicket.status != undefined && memberTicket.status !== ''">{{ memberTicket.status }}</p>

  <p class="label">{{ 'my_passes.purchase_date' | translate }}</p>
  <p class="value">{{ memberTicket.purchaseDate }}</p>

  <p class="label">{{ 'my_passes.invoice_status' | translate }}</p>
  <p class="value"
     [ngClass]="{'red-color': !memberTicket.isPaid && memberTicket.isOverdue}">{{ memberTicket.paymentStatus }}</p>

  <p class="label" *ngIf="!memberTicket.isPaid">{{ 'app.amount' | translate }}</p>
  <p class="value" *ngIf="!memberTicket.isPaid">{{ memberTicket.openInvoiceAmount }}</p>

  <p class="label">{{ 'my_passes.payable_until' | translate }}</p>
  <p class="value">{{ memberTicket.payableUntil }}</p>

  <p class="label">{{ 'my_passes.payment_method' | translate }}</p>
  <p class="value">{{ memberTicket.localizedPaymentMethod }}</p>

  <ng-container *ngIf="showPaymentInformation">
    <p class="label"
       *ngIf="memberTicket.paymentInformation && memberTicket.paymentInformation.length > 0">{{ 'my_passes.payment_information' | translate }}</p>
    <p *ngFor="let paymentInformation of memberTicket.paymentInformation" (click)="writeToClipboard(paymentInformation.value)" [hidden]="paymentInformation.value === ''">{{ paymentInformation.label }}
      :<br>{{ paymentInformation.value }} <ion-icon class="btn-icon" slot="icon-only" name="copy-outline"></ion-icon>
    </p>
  </ng-container>
</mbsc-card-content>
