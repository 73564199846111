import { ShellModel } from '../../shell/data-store';
import { PersonalInformationModel } from '../../components/personal-information-form/personal-information.model';

export class ProfilePersonalInformationModel extends ShellModel {
  personalInformation: PersonalInformationModel;

  constructor() {
    super();
  }
}
