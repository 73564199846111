import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ServiceSessionModel } from '../../models/service-session.model';
import { ProfileService } from '../../profile/profile.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from '../../provider/schedule/booking/booking.service';
import { EventsService } from '../../utils/events.service';

@Component({
  selector: 'app-watch-list-item',
  templateUrl: './watch-list-item.component.html',
  styleUrls: ['./watch-list-item.component.scss'],
})
export class WatchListItemComponent implements OnInit {
  @Input() serviceSession: ServiceSessionModel;
  @Input() isFullWidth = false;
  @Output() reloadWatchList: EventEmitter<any> = new EventEmitter();

  // @ts-ignore
  @ViewChild('successPopupComponent')
  successPopupComponent: any;

  constructor(
    private profileService: ProfileService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private bookingService: BookingService,
    private eventsService: EventsService,
    private translate: TranslateService
    ) { }

  ngOnInit() {}

  presentBookingModal(serviceSession: ServiceSessionModel) {
    const that = this;
    this.bookingService.openBookingModal(serviceSession).then(() => {
      that.reloadWatchList.emit();
    });
  }

  async presentRemoveServiceSessionAlert(serviceSession: ServiceSessionModel) {
    const alert = await this.alertController.create({
      header: this.translate.instant('my_watch_list.remove_from_watch_list'),
      message: this.translate.instant('my_watch_list.confirm_remove_from_watch_list'),
      buttons: [
        {
          text: this.translate.instant('app.abort'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('app.confirm'),
          role: 'destructive',
          cssClass: 'confirm-cancel-button',
          handler: () => {
            this.removeServiceSession(serviceSession);
          }
        }
      ]
    });
    await alert.present();
  }

  async removeServiceSession(serviceSession: ServiceSessionModel) {
    const that = this;
    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });
    (await loading).present();

    this.profileService.removeServiceSessionFromWatchList(serviceSession).subscribe({
      async next() {
        (await loading).dismiss();
        that.successPopupComponent.show(that.translate.instant('app.alright'), that.translate.instant('my_watch_list.remove_from_watch_list_successful')).then(() => {
          that.reloadWatchList.emit();
          that.eventsService.publish('bookingsChanged', {});
        });
      },
      async error() {
        (await loading).dismiss();
      }
    });
  }

}
