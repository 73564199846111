<app-success-popup #successPopupComponent></app-success-popup>

<mbsc-card-content [ngClass]="isFullWidth ? 'full-width-card' : ''">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <div class="mbsc-pull-left">
          <mbsc-card-title>{{serviceSession.name}}</mbsc-card-title>
          <p>{{ serviceSession.provider.name }}</p>
          <p>
            <ion-icon name="calendar-outline"></ion-icon>
            {{serviceSession.datetime}}</p>
          <p>
            <ion-icon name="location-outline"></ion-icon>
            {{serviceSession.location}}</p>
          <p>
            <ion-icon name="body-outline"></ion-icon>
            {{serviceSession.team}}</p>
          <p *ngIf="serviceSession.freeSpots != undefined">
            <ion-icon name="people-outline"></ion-icon>
            {{serviceSession.freeSpots}}</p>
          <p *ngIf="serviceSession.bookingInformation != undefined && serviceSession.bookingInformation !== '' && !serviceSession.isCancelled">
            <ion-icon name="information-outline"></ion-icon>
            {{serviceSession.bookingInformation}}</p>
          <p *ngIf="serviceSession.additionalInformation != undefined && serviceSession.additionalInformation !== '' && !serviceSession.isCancelled">
            <ion-icon name="information-outline"></ion-icon>
            {{serviceSession.additionalInformation}}</p>

          <ion-badge color="warning" *ngIf="serviceSession.isAlmostFullyBooked">{{ 'schedule.almost_booked_out' | translate }}</ion-badge>
          <ion-badge color="secondary" *ngIf="serviceSession.isClosed && !serviceSession.isNotOpenYet  && !serviceSession.isBookingLimitReached && !serviceSession.isAlreadyBooked && !serviceSession.isFullyBooked">{{ 'schedule.booking_closed' | translate }}</ion-badge>
          <ion-badge color="secondary" *ngIf="serviceSession.isBookingLimitReached && !serviceSession.isFullyBooked && !serviceSession.isCancelled">{{ 'schedule.booking_limit_reached' | translate }}</ion-badge>
          <ion-badge color="secondary" *ngIf="serviceSession.isFullyBooked && !serviceSession.isAlreadyOnWaitingList && !serviceSession.isCancelled">{{ 'booking.fully_booked' | translate }}</ion-badge>
          <ion-badge color="secondary" *ngIf="serviceSession.isCancelled">{{ 'schedule.cancelled' | translate }}</ion-badge>
          <ion-badge color="success" *ngIf="serviceSession.isAlreadyBooked">{{ 'schedule.already_booked' | translate }}</ion-badge>

          <p *ngIf="serviceSession.cancellationReason != undefined && serviceSession.cancellationReason !== '' && serviceSession.isCancelled">
            <ion-icon name="information-outline"></ion-icon>
            {{serviceSession.cancellationReason}}</p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</mbsc-card-content>
<mbsc-card-footer>
  <ion-button expand="block" color="secondary" type="submit" fill="outline" size="small"
              (click)="presentBookingModal(serviceSession)" *ngIf="!this.serviceSession.isAlreadyBooked && (this.serviceSession.isFullyBooked || !this.serviceSession.isClosed) && !this.serviceSession.isNotOpenYet && !this.serviceSession.isCancelled">
    <ion-icon name="calendar-outline" slot="start"></ion-icon>
    {{ 'app.book_now' | translate }}
  </ion-button>
  <ion-button expand="block" color="medium" type="submit" fill="outline" size="small" (click)="presentRemoveServiceSessionAlert(serviceSession)">
    <ion-icon name="close-circle-outline" slot="start"></ion-icon>
    {{ 'my_watch_list.remove_from_watch_list' | translate }}
  </ion-button>
</mbsc-card-footer>