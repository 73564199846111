<app-success-popup #successPopupComponent></app-success-popup>
<app-warning-popup #warningPopupComponent></app-warning-popup>

<mbsc-popup [options]="popupSettings" #onTheWaitingListPopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <img class="popup-icon" src="./assets/custom-icons/popups/success.png">
    <h3>{{ 'booking.on_the_waiting_list' | translate }}</h3>
    <p>{{ 'booking.added_waiting_list_successful' | translate }}</p>

    <ion-button expand="block" color="secondary" type="submit" fill="outline" (click)="openMyWaitingList()">{{ 'profile.my_waiting_list' | translate }}</ion-button>

    <ng-container *ngIf="serviceSession != undefined && serviceSession.isBookable && serviceSession.isAlreadyOnWaitingList ">
      <br>
      <ion-button expand="block" color="medium" size="small" fill="outline" (click)="removeFromWaitingList(serviceSession.bookingId)">
        <ion-icon name="close-circle-outline" slot="start"></ion-icon> {{ 'booking.remove_from_waiting_list' | translate }}
      </ion-button>
    </ng-container>
  </div>
</mbsc-popup>
