import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { EventsService } from '../utils/events.service';
import { DataStore, ShellModel } from '../shell/data-store';
import { MbscListview } from '@mobiscroll/angular';
import { ActivatePremiumModal } from '../admin-area/activate-premium/activate-premium.modal';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  static instance: HttpService;

  constructor(
    private alertController: AlertController,
    private authenticationService: AuthenticationService,
    private eventsService: EventsService,
    private loadingController: LoadingController,
    private translate: TranslateService,
    private modalController: ModalController,
    private location: Location
  ) {
    HttpService.instance = this;
  }

  // Dummy init so an instance get created in the constructor.
  init() {}

  static loadMoreData(dataSource: Observable<any>, modelData: Array<any>, dataName: string, listview: MbscListview) {
    return new Promise(async function (resolve) {
      dataSource.subscribe({
        async next(data) {
          for (let i = 0; i < data[dataName].length; ++i) {
            modelData.push(data[dataName][i]);
          }
          if (listview) {
            listview.instance.hideLoading();
          }
          resolve({modelData: modelData, hasMoreData: data[dataName].length > 0})
        },
        async error(error) {
          console.error(error);
          if (listview) {
            listview.instance.hideLoading();
          }
        }
      });
    });
  }

  static reloadModel(model: ShellModel, dataSource: Observable<any>, options?: { refreshEvent?: any, dataStore?: DataStore<any> }) {
    if (options && options.refreshEvent) {
      // Timeout workaround required. Otherwise the ion-refresher only works once on Android.
      setTimeout(() => {
        options.refreshEvent.target.complete();
      }, 100);
    }
    return new Promise(async function (resolve, reject) {
      let dataStore;
      if (options && options.dataStore) {
        dataStore = options.dataStore;
      }
      else {
        dataStore = new DataStore(model);
      }

      dataStore.load(dataSource);
      model.isShell = true;
      const subscription = dataStore.state.subscribe(async (data) => {
        if (!data.isShell) {
          model.isShell = false;
          model.hasError = false;
          resolve(data);
          subscription.unsubscribe();
        }
      }, async (error) => {
        model.isShell = false;
        model.hasError = true;
        reject(error);
        subscription.unsubscribe();
      });
    });
  }

  static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred: ', error.error.message);
      HttpService.presentConnectionProblemAlert();
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);

      switch (error.status) {
        case 0:
          HttpService.presentConnectionProblemAlert();
          break;
        case 401:
          HttpService.presentSessionExpiredAlert();
          HttpService.instance.authenticationService.logout();
          break;
        case 402:
          HttpService.presentActivatePremiumModal();
          break;
        case 400:
        case 404:
          HttpService.presentInvalidInputAlert();
          break;
        case 403:
          // Silent error
          break;
        default:
          HttpService.presentErrorAlert();
      }
    }

    return throwError(error);
  }

  static async presentActivatePremiumModal() {
    const modal = await HttpService.instance.modalController.create({
      component: ActivatePremiumModal
    });

    await modal.present();
  }

  static async presentInvalidInputAlert() {
    const alert = await HttpService.instance.alertController.create({
      header: HttpService.instance.translate.instant('app.invalid_input'),
      message: HttpService.instance.translate.instant('app.invalid_input_description'),
      buttons: ['OK']
    });

    await alert.present();
  }

  static async presentErrorAlert() {
    const alert = await HttpService.instance.alertController.create({
      header: HttpService.instance.translate.instant('app.error'),
      message: HttpService.instance.translate.instant('app.error_occurred'),
      buttons: ['OK']
    });

    await alert.present();
  }

  static async presentSessionExpiredAlert() {
    const alert = await HttpService.instance.alertController.create({
      header: HttpService.instance.translate.instant('app.session_expired'),
      message: HttpService.instance.translate.instant('app.session_expired_description'),
      buttons: ['OK']
    });

    await alert.present();
  }

  static async presentConnectionProblemAlert() {
    const alert = await HttpService.instance.alertController.create({
      header: HttpService.instance.translate.instant('app.connection_problem'),
      message: HttpService.instance.translate.instant('app.connection_problem_instructions'),
      buttons: [{
        text: HttpService.instance.translate.instant('app.system_status'),
        cssClass: 'secondary',
        handler: () => {
          window.open('https://support.sportsnow.ch/de/support/solutions/articles/48001154898-systemstatus', '_system', 'location=yes');
        }
      }, {
        text: HttpService.instance.translate.instant('app.ok'),
        handler: () => {
        }
      }]
    });

    await alert.present();
  }
}
