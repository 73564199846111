<mbsc-popup [options]="popupSettings" #rateUsPopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <h3>😍</h3>
    <p *ngIf="platform.is('ios')">{{ 'rate_us.would_you_rate_us_ios' | translate }}</p>
    <p *ngIf="platform.is('android')">{{ 'rate_us.would_you_rate_us_android' | translate }}</p>
    <p>{{ 'rate_us.rating_would_help_us' | translate }}</p>
    <p>
      ⭐⭐⭐⭐⭐️ </p>

    <ion-button expand="block" fill="outline" color="secondary"
                (click)="openAppStoreReview()">{{ 'rate_us.rate_now' | translate }}
    </ion-button>

    <br>

    <ion-button expand="block" fill="outline" color="medium"
                (click)="stopRateUs()">{{ 'rate_us.no_thanks' | translate }}</ion-button>
  </div>
</mbsc-popup>

<mbsc-popup [options]="popupSettings" #sendFeedbackPopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <h3>📝🤓️</h3>
    <p>{{ 'rate_us.give_us_feedback' | translate }}</p>
    <p>
      <ion-list class="send-feedback-list" lines="full">
        <ion-item class="send-feedback-item">
          <ion-label class="send-feedback-label"
                     position="floating">{{ 'create_rating.feedback' | translate }}</ion-label>
          <ion-textarea auto-grow="false" [(ngModel)]="feedback"></ion-textarea>
        </ion-item>
      </ion-list>
    </p>

    <ion-button expand="block" fill="outline" color="secondary" (click)="sendFeedback()"
                [disabled]="feedback == undefined || feedback.length === 0">{{ 'rate_us.send_feedback' | translate }}
    </ion-button>

    <br>

    <ion-button expand="block" fill="outline" color="medium"
                (click)="stopRateUs()">{{ 'rate_us.no_thanks' | translate }}</ion-button>
  </div>
</mbsc-popup>

<mbsc-card class="rate-us-card">
  <mbsc-card-content>
    <mbsc-card-title>{{ 'rate_us.do_you_like_sportsnow' | translate }}</mbsc-card-title>
    <p>{{salutation}}, {{ 'rate_us.still_like_sportsnow' | translate }}</p>
  </mbsc-card-content>
  <mbsc-card-footer>
    <div class="mbsc-btn-group-justified">
      <mbsc-button class="rate-us-button" (click)="showFeedbackPopup()">
        🙁
        <br>
        <br>
        {{ 'rate_us.could_be_better' | translate }}
      </mbsc-button>
      <mbsc-button class="rate-us-button" (click)="showRateUsPopup()">
        😍
        <br>
        <br>
        {{ 'rate_us.still_loving_it' | translate }}
      </mbsc-button>
    </div>
  </mbsc-card-footer>
</mbsc-card>
