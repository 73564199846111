import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShortHealthQuestionnaireModel } from '../../models/short-health-questionnaire.model';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { HealthQuestionnaireModal } from '../../admin-area/my-members/health-questionnaire/health-questionnaire.modal';
import { MyMembersService } from '../../admin-area/my-members/my-members.service';
import {
  EditHealthQuestionnaireModal
} from '../../admin-area/my-members/edit-health-questionnaire/edit-health-questionnaire.modal';

@Component({
  selector: 'app-select-health-questionnaire',
  templateUrl: './select-health-questionnaire.component.html',
  styleUrls: ['./select-health-questionnaire.component.scss'],
})
export class SelectHealthQuestionnaireComponent implements OnInit {
  @Input() providerId: string;
  @Input() customerId: number;
  @Input() bookingId: number
  @Input() shortProviderHealthQuestionnaires: Array<ShortHealthQuestionnaireModel>;
  @Input() showIcons: boolean = true;
  @Input() presentEditModalDirectly: boolean = false;

  @Output() healthQuestionnaireChanged: EventEmitter<any> = new EventEmitter();

  searchQuery: string;
  filteredShortProviderHealthQuestionnaires: Array<ShortHealthQuestionnaireModel>;

  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private myMembersService: MyMembersService,
    private loadingController: LoadingController,
  ) { }

  ngOnInit() {
    this.filteredShortProviderHealthQuestionnaires = this.shortProviderHealthQuestionnaires;
  }

  // Filters the provider health questionnaires for the search query.
  searchHealthQuestionnaire() {
    if (this.searchQuery == undefined || this.searchQuery === '') {
      this.filteredShortProviderHealthQuestionnaires = this.shortProviderHealthQuestionnaires;
    }
    else {
      this.filteredShortProviderHealthQuestionnaires = [];

      for (let shortQuestionnaire of this.shortProviderHealthQuestionnaires) {
        if (shortQuestionnaire.providerHealthQuestionnaireName.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          this.filteredShortProviderHealthQuestionnaires.push(shortQuestionnaire);
        }
      }
    }
  }

  async presentModal(shortHealthQuestionnaire: ShortHealthQuestionnaireModel) {
    if (this.presentEditModalDirectly) {
      await this.presentEditHealthQuestionnaireModal(shortHealthQuestionnaire);
    }
    else {
      await this.presentHealthQuestionnaireModal(shortHealthQuestionnaire);
    }
  }

  async presentEditHealthQuestionnaireModal(shortHealthQuestionnaire: ShortHealthQuestionnaireModel) {
    const that = this;

    const loading = this.loadingController.create({
      cssClass: 'custom-loading',
      spinner: 'crescent'
    });

    (await loading).present();

    this.myMembersService.getHealthQuestionnaire(this.providerId, this.customerId, shortHealthQuestionnaire.providerHealthQuestionnaireId, shortHealthQuestionnaire.memberHealthQuestionnaireId, this.bookingId).subscribe({
      async next(response) {
        (await loading).dismiss();

        const modal = await that.modalController.create({
          component: EditHealthQuestionnaireModal,
          componentProps: {
            'providerId': that.providerId,
            'customerId': that.customerId,
            'healthQuestionnaire': response.healthQuestionnaire
          }
        });

        modal.onDidDismiss().then(data => {
          if (data.data != undefined && data.data.hasHealthQuestionnaireChanged) {
            that.healthQuestionnaireChanged.emit();
          }
        });

        await modal.present();
      },
      async error() {
        (await loading).dismiss();
      }
    });
  }

  async presentHealthQuestionnaireModal(shortHealthQuestionnaire: ShortHealthQuestionnaireModel) {
    const modal = await this.modalController.create({
      component: HealthQuestionnaireModal,
      componentProps: {
        'providerId': this.providerId,
        'customerId': this.customerId,
        'bookingId': this.bookingId,
        'providerHealthQuestionnaireId': shortHealthQuestionnaire.providerHealthQuestionnaireId,
        'providerHealthQuestionnaireName': shortHealthQuestionnaire.providerHealthQuestionnaireName,
        'memberHealthQuestionnaireId': shortHealthQuestionnaire.memberHealthQuestionnaireId,
      }
    });

    modal.onDidDismiss().then(data => {
      if (data.data != undefined && data.data.hasHealthQuestionnaireChanged) {
        this.healthQuestionnaireChanged.emit();
      }
    });

    await modal.present();
  }

}
