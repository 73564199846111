<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ providerHealthQuestionnaireName }}</ion-title>
  </ion-toolbar>
  <ion-toolbar *ngIf="healthQuestionnaire!= undefined && healthQuestionnaire.memberHealthQuestionnaire != undefined && memberHealthQuestionnaireId != undefined">
    <ion-row>
      <ion-col class="filled-out-on">
        {{ 'my_members.filled_out_on' | translate }}: {{ healthQuestionnaire.memberHealthQuestionnaire.filledOutOn }}
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content class="select-health-questionnaire-content">
  <div *ngIf="memberHealthQuestionnaireId == undefined" class="health-questionnaire-not-filled-out">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-icon name="help-outline"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p>
            {{ 'my_members.health_questionnaire_pending' | translate }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div *ngIf="healthQuestionnaire != undefined && memberHealthQuestionnaireId != undefined">
    <app-health-questionnaire-info [providerHealthQuestionnaire]="healthQuestionnaire.providerHealthQuestionnaire"
                                   [memberHealthQuestionnaire]="healthQuestionnaire.memberHealthQuestionnaire"
                                   [providerLanguage]="healthQuestionnaire.providerLanguage"></app-health-questionnaire-info>
  </div>
</ion-content>

<ion-footer>
  <ion-row class="form-actions-wrapper">
    <ion-col>
      <ion-button class="submit-btn" expand="block" color="secondary" fill="solid" (click)="presentEditHealthQuestionnaireModal()"
                  [disabled]="providerHealthQuestionnaireId == undefined">
        {{ 'my_members.fill_out' | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
