import { Component, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication/authentication.service';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { HttpService } from './http/http.service';
import { environment } from '../environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoginService } from './login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './app.component.scss'
  ]
})

export class AppComponent {
  textDir = 'ltr';

  constructor(
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private storage: Storage,
    private router: Router,
    private zone: NgZone,
    private navController: NavController,
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
    private httpService: HttpService,
    private loginService: LoginService,
    private loadingController: LoadingController
  ) {
    console.log('AppComponent.constructor()');
    this.initDeepLinking();
    this.initLanguage();
    this.initRootNavigation();
    this.httpService.init();
  }

  hideSplashScreen() {
    try {
      SplashScreen.hide();
    }
    catch (err) {
      console.log('This is normal in a browser', err);
    }
  }

  // Docs: https://capacitorjs.com/docs/guides/deep-links
  async initDeepLinking() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(async () => {
        // Url: https://www.sportsnow.ch/go/pilates-biel-gmbh
        // Provider's page slug = /go/pilates-biel-gmbh
        // Class slug = providers/pilates-biel-gmbh/service_sessions/26/bookings/new
        const slug = data.url.split('sportsnow.ch').pop();
        console.log('URL:', data.url);
        console.log('Slug:', slug);

        const slugParts: Array<any> = slug.split('/');
        slugParts.splice(0, 1); // Remove empty first element.
        console.log('Slug parts', slugParts);

        // Only handle web url.
        if (!data.url.toLowerCase().startsWith('http')) {
          console.log('Not a web URL.');
          return;
        }

        if (data.url.includes('confirmation_token')) {
          console.log('Verify confirmation token and log in user if successful.');
          const confirmationUrlParts: Array<any> = slug.split('=');
          if (confirmationUrlParts.length == 2) {
            const confirmationToken = confirmationUrlParts[1];
            const loading = this.loadingController.create({
              cssClass: 'custom-loading',
              spinner: 'crescent'
            });
            (await loading).present();
            this.loginService.confirmEmail(confirmationToken).subscribe(
              async (data) => {
                (await loading).dismiss();
                this.authenticationService.login(data.authenticationToken, data.memberId);
              },
              async () => {
                (await loading).dismiss();
              });
          }
          return;
        }
        else if (data.url.includes('/users/')) {
          // Open forgot password and set new password URL in the browser.
          console.log('Open forgot password and set new password URL in the browser.');
          window.open(data.url, '_system', 'location=yes');
          return;
        }

        if (slugParts.length > 0) {
          // Remove language part.
          if ((slugParts[0].toLowerCase() === 'de' || slugParts[0].toLowerCase() === 'en' || slugParts[0].toLowerCase() === 'fr' || slugParts[0].toLowerCase() === 'it')) {
            // Url: https://www.sportsnow.ch/fr/go/pilates-biel-gmbh
            // Language part: fr
            console.log('Remove language part.');
            slugParts.splice(0, 1);
            console.log('Slug parts: ' + slugParts);
          }

          // Route to provider's page.
          if (slugParts[0] === 'go' || slugParts[0] === 'space') {
            if (slugParts[2] != undefined && (slugParts[2] === 'live_calendar' || slugParts[2] === 'classes' || slugParts[2] === 'workshops' || slugParts[2] === 'appointments') && slugParts[3] != undefined) {
              console.log('Navigate to service sessions details page');
              // Remove the locale param that could be part of the URL: /go/yoganow/live_calendar/75?locale=de
              const serviceSessionId = slugParts[3].split("?")[0];
              this.router.navigate(['/provider/' + slugParts[1] + '/schedule/details/' + serviceSessionId]);
            }
            else {
              console.log('Navigate to provider\'s page');
              const link = '/provider/' + slugParts[1];
              console.log('Route link', link);
              this.router.navigate([link]);
            }
          }
          else if (slugParts[0] === 'providers' && slugParts[2] === 'service_sessions') {
            console.log('Navigate to schedule');
            this.router.navigate(['/provider/' + slugParts[1] + '/schedule/' + slugParts[3] + '/instances']);
          }
          else {
            // Don't navigate anywhere, if no match.
            console.log('Deeplink: No specific route found for the URL. Open the URL in the browser instead.');
            window.open(data.url, '_system', 'location=yes');
          }
        }
        else {
          // Open https://www.sportsnow.ch without specific routes in the browser.
          console.log('Deeplink: Open https://www.sportsnow.ch without specific routes in the browser.');
          window.open(data.url, '_system', 'location=yes');
        }
      });
    });
  }

  initRootNavigation() {
    if (environment.production) {
      if (AuthenticationService.isAuthenticated) {
        this.navController.navigateRoot(['/app/home']).then(r => {
          this.hideSplashScreen();
        });
      }
      else {
        this.navController.navigateRoot(['/welcome']).then(r => {
          this.hideSplashScreen();
        });
      }
    }
    else {
      this.hideSplashScreen();
    }
  }

  initLanguage() {
    console.log('Init language');
    // Load saved language from the Storage with device language as fallback.
    this.storage.get('lang').then((value) => {
      const lang = value;

      if (lang != undefined && lang !== '') {
        this.translate.use(lang);
      }
    });

    const deviceLanguage = navigator.language;
    console.log('Device language:', deviceLanguage);

    if (deviceLanguage.indexOf('de') > -1) {
      this.translate.use('de');
    }
    else if (deviceLanguage.indexOf('fr') > -1) {
      this.translate.use('fr');
    }
    else if (deviceLanguage.indexOf('fr') > -1) {
      this.translate.use('it');
    }
    else {
      this.translate.use('en');
    }

    console.log('Language set:', this.translate.currentLang);
  }
}
