import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ComponentsModule } from './components/components.module';

import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BookingModal } from './provider/schedule/booking/booking.modal';
import { PurchaseModal } from './provider/pricing/purchase/purchase.modal';
import { IonicStorageModule } from '@ionic/storage';
import { Ndef, NFC } from '@ionic-native/nfc/ngx';
import { Media } from '@ionic-native/media/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { MbscCalendarModule, MbscCardModule, MbscFormsModule, MbscPopupModule } from '@mobiscroll/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { HeaderInterceptor } from './http/header.interceptor';
import { AuthenticationService } from './authentication/authentication.service';
import { AdminAreaService } from './admin-area/admin-area.service';
import { DisclaimerModal } from './provider/schedule/disclaimer/disclaimer.modal';
import { Facebook } from '@ionic-native/facebook/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { ActivatePremiumModal } from './admin-area/activate-premium/activate-premium.modal';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { AddCovidCertificateModal } from './admin-area/my-members/add-covid-certificate/add-covid-certificate.modal';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { LoginService } from './login/login.service';
import { RateUsService } from './components/rate-us-home-card/rate-us.service';
import { HomeService } from './home/home.service';
import { HealthQuestionnaireModal } from './admin-area/my-members/health-questionnaire/health-questionnaire.modal';
import {
  SelectHealthQuestionnaireModal
} from './admin-area/my-members/select-health-questionnaire/select-health-questionnaire.modal';
import {
  EditHealthQuestionnairesCardComponent
} from './components/edit-health-questionnaires-card/edit-health-questionnaires-card.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initAuthenticationService(authenticationService: AuthenticationService) {
  return (): Promise<any> => {
    return authenticationService.init();
  }
}

export function initAdminAreaService(adminAreaService: AdminAreaService) {
  return (): Promise<any> => {
    return adminAreaService.init();
  }
}

@NgModule({
  declarations: [AppComponent, BookingModal, PurchaseModal, DisclaimerModal, ActivatePremiumModal, AddCovidCertificateModal, HealthQuestionnaireModal, SelectHealthQuestionnaireModal, EditHealthQuestionnairesCardComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    ComponentsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    MbscPopupModule,
    MbscFormsModule,
    MbscCardModule,
    MbscCalendarModule,
    FormsModule
  ],
  providers: [
    AuthenticationService,
    { provide: APP_INITIALIZER, useFactory: initAuthenticationService, deps: [AuthenticationService], multi: true },
    AdminAreaService,
    { provide: APP_INITIALIZER, useFactory: initAdminAreaService, deps: [AdminAreaService], multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    InAppBrowser,
    NFC,
    Ndef,
    Media,
    Diagnostic,
    Calendar,
    Facebook,
    SignInWithApple,
    FirebaseAnalytics,
    GooglePlus,
    LaunchReview,
    LoginService,
    RateUsService,
    HomeService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
