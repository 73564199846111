import { ShellModel } from '../../../shell/data-store';
import { ServiceSessionModel } from '../../../models/service-session.model';
import { StaffModel } from '../../../models/staff.model';

export class CalendarModel extends ShellModel {
  name: string;
  staff: Array<StaffModel>;
  appointments: Array<ServiceSessionModel>;

  constructor() {
    super();
  }
}
