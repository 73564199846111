import { ShellModel } from '../../shell/data-store';
import { CustomerModel } from '../../models/customer.model';

export class MyFamilyModel extends ShellModel {
  members: Array<CustomerModel>;
  deletedMembers: Array<CustomerModel>;
  userMember: CustomerModel;

  constructor() {
    super();
  }
}
