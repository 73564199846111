import { ShellModel } from '../../shell/data-store';
import { ServiceSessionModel } from '../../models/service-session.model';

export class MyWatchListModel extends ShellModel {
  serviceSessions: Array<ServiceSessionModel>;

  constructor() {
    super();
  }
}
