<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'app.book' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<!-- Popups -->
<app-info-popup #infoPopupComponent></app-info-popup>

<app-fully-booked-popup #fullyBookedPopupComponent></app-fully-booked-popup>

<app-twint-payment [ticketId]="selectedTicket?.id" [couponId]="activatedCoupon?.id" [serviceSessionId]="model.serviceSession.id" [bookingDate]="model.serviceSession.date" #twintPaymentComponent></app-twint-payment>

<mbsc-popup [options]="successPopupSettings" #successPopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <img class="popup-icon" src="./assets/custom-icons/popups/success.png" alt="Success">
    <h3>{{ 'booking.booked' | translate }}</h3>
    <p>{{ 'booking.have_fun' | translate }}</p>

    <ion-button expand="block" color="secondary" size="small" type="submit" fill="outline" (click)="profileService.addBookingToCalendar(confirmedBooking)">{{ 'booking.add_to_calendar' | translate }}</ion-button>

    <p *ngIf="isCalendarSynchronisationActive"><ion-icon name="checkmark-circle-outline" slot="start"></ion-icon> {{ 'booking.calendar_synchronization_activated' | translate }}</p>
  </div>
</mbsc-popup>

<mbsc-popup [options]="limitReachedPopupSettings" #limitReachedPopup="mobiscroll">
  <div class="mbsc-align-center mbsc-padding">
    <img class="popup-icon" src="./assets/custom-icons/popups/error.png">
    <h3>{{ 'booking.limit_reached' | translate }}</h3>
    <p>{{ 'booking.cancel_to_enable_booking' | translate }}</p>

    <ion-button expand="block" color="secondary" type="submit" fill="outline"
               >{{ 'booking.show_my_bookings' | translate }}</ion-button>
  </div>
</mbsc-popup>

<ion-content class="booking-content">
  <!-- Class details -->
  <section class="class-details-section">
    <h5 class="class-title">{{ model.serviceSession.name }}</h5>

    <p class="class-details" *ngIf="model.serviceSession.description != undefined">
      {{!showMoreDescription ? model.serviceSession.description.slice(0, 150) : model.serviceSession.description}}
      <a (click)="toggleShowMoreDescription()" *ngIf="!showMoreDescription">... {{ 'app.more' | translate | lowercase }}</a>
    </p>

    <p class="class-details">
      <ion-icon name="time-outline"></ion-icon>
      {{ model.serviceSession.datetime }}</p>
    <p class="class-details">
      <ion-icon name="location-outline"></ion-icon>
      {{ model.serviceSession.location }}</p>
    <p class="class-details">
      <ion-icon name="body-outline"></ion-icon>
      {{ model.serviceSession.team }}</p>
    <p class="class-details" *ngIf="model.serviceSession.freeSpots">
      <ion-icon name="people-outline"></ion-icon>
      {{ model.serviceSession.freeSpots }}</p>
    <p class="class-details" *ngIf="model.serviceSession.bookingInformation != undefined && model.serviceSession.bookingInformation !== ''">
      <ion-icon name="information-outline"></ion-icon> {{ model.serviceSession.bookingInformation }}</p>
    <p class="class-details" *ngIf="model.serviceSession.additionalInformation != undefined && model.serviceSession.additionalInformation !== ''">
      <ion-icon name="information-outline"></ion-icon> {{ model.serviceSession.additionalInformation }}</p>
  </section>


  <mbsc-accordion>
    <!-- Choose on-site or livestream -->
    <mbsc-card collapsible #onSiteOrLivestreamCard="mobiscroll" [hidden]="!model.serviceSession.isLivestreamBookingEnabled">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="checkmark-circle-outline" *ngIf="isLivestreamBooking != undefined"></ion-icon>
          {{ onSiteOrLivestreamCardTitle }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="onSiteOrLivestreamForm">
          <ion-list class="on-site-or-livestream-list" lines="full">
            <ion-radio-group class="radio-group" formControlName="on_site_or_livestream" required>
              <ion-item class="radio-item" (click)="selectOnSiteOrLivestream(false)" [disabled]="model.serviceSession.isFullyBooked">
                <ion-label class="radio-label">
                  <ion-icon slot="start" name="home-outline"></ion-icon>
                  {{ 'participants_lists.on_site_booking' | translate}}</ion-label>
                <ion-radio slot="start" color="secondary" value="false"></ion-radio>
              </ion-item>
              <ion-item class="radio-item" (click)="selectOnSiteOrLivestream(true)">
                <ion-label class="radio-label">
                  <ion-icon slot="start" name="videocam-outline"></ion-icon>
                  {{ 'participants_lists.livestream_booking' | translate}}</ion-label>
                <ion-radio slot="start" color="secondary" value="true"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Select member ticket / ticket -->
    <mbsc-card collapsible #ticketCard="mobiscroll"
               [hidden]="model.serviceSession.isLivestreamBookingEnabled && isLivestreamBooking == undefined || model.serviceSession.isFreeOfCharge">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="checkmark-circle-outline" *ngIf="selectedMemberTicket || selectedTicket"></ion-icon>
          {{ ticketCardTitle }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="memberTicketForm">
          <ion-list class="member-ticket-list" lines="full">
            <ion-radio-group class="radio-group" formControlName="selected_member_ticket">
              <ion-item class="radio-item" (click)="selectMemberTicket(memberTicket)"
                        *ngFor="let memberTicket of model.memberTickets">
                <ion-label class="radio-label">
                  <p class="member-ticket-title">{{ memberTicket.name }}</p>
                  <p class="member-ticket-validity">{{ memberTicket.validity }}</p>
                  <p class="member-ticket-credits">{{ memberTicket.localizedCredits }}</p>
                  <p class="member-ticket-validity" *ngIf="!isLivestreamBooking && memberTicket.isForLivestreamBookingOnly"><ion-icon name="information-outline"></ion-icon> {{ 'booking.pass_valid_only_for_livestream' | translate }}</p>
                </ion-label>
                <ion-radio slot="start" color="secondary" value="{{ memberTicket.id }}" [disabled]="!isLivestreamBooking && memberTicket.isForLivestreamBookingOnly"></ion-radio>
              </ion-item>
              <ion-item class="radio-item" *ngFor="let memberTicket of model.outOfRangeMemberTickets">
                <ion-label class="radio-label">
                  <p class="member-ticket-title">{{ memberTicket.name }}</p>
                  <p class="member-ticket-validity">{{ memberTicket.validity }}</p>
                  <p class="member-ticket-credits">{{ memberTicket.localizedCredits }}</p>
                  <p class="member-ticket-validity"><ion-icon name="information-outline"></ion-icon> {{ 'booking.date_outside_this_pass' | translate }}</p>
                </ion-label>
                <ion-radio slot="start" color="secondary" value="{{ memberTicket.id }}" disabled="true"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </form>

        <ion-item-divider sticky="true">
          <ion-label>{{ 'booking.buy_new_ticket' | translate }}</ion-label>
        </ion-item-divider>

        <ion-item class="list-item" *ngIf="model.tickets?.length === 0">
          <ion-label class="list-item-label">{{ 'booking.no_passes_available' | translate }}</ion-label>
        </ion-item>

        <form [formGroup]="ticketForm">
          <ion-list class="ticket-list" lines="full">
            <ion-radio-group class="radio-group" formControlName="selected_ticket">
              <ion-item class="radio-item" (click)="selectTicket(ticket)" *ngFor="let ticket of model.tickets" [hidden]="!isLivestreamBooking && ticket.isForLivestreamBookingOnly">
                <ion-label class="radio-label">
                  <p class="ticket-title">{{ ticket.name }}</p>
                  <p class="ticket-description">{{ ticket.description }}</p>
                  <p class="ticket-price">{{ ticket.localizedPrice }}</p>
                </ion-label>
                <ion-radio slot="start" color="secondary" value="{{ ticket.id }}"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Select payment method -->
    <mbsc-card collapsible #paymentMethodCard [hidden]="selectedTicket == undefined || model.serviceSession.isFreeOfCharge && model.serviceSession.isLivestreamBookingEnabled && isLivestreamBooking == undefined || selectedTicket.price === 0">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="checkmark-circle-outline" *ngIf="selectedPaymentMethod"></ion-icon>
          {{ paymentMethodCardTitle }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="paymentMethodsForm">
          <ion-list class="ticket-list" lines="full">
            <ion-radio-group class="radio-group" formControlName="selected_payment_method">
              <div *ngFor="let paymentMethod of model.paymentMethods">
                <ion-item class="radio-item" (click)="selectPaymentMethod(paymentMethod)"
                          [hidden]="isPaymentMethodExcludedFromSelectedTicket(paymentMethod) || paymentMethod?.id === 'invoice_payment_method_customer_credit' && paymentMethod?.accountBalance < selectedTicket?.price">
                  <ion-label class="radio-label">
                    <p class="ticket-title">{{ paymentMethod.name }}</p>
                    <p class="ticket-description">{{ paymentMethod.description }}</p>
                    <p class="payment-note" *ngIf="paymentMethod.cashPaymentNote != undefined && paymentMethod.cashPaymentNote !== ''"><ion-icon name="information-circle-outline"></ion-icon> {{ paymentMethod.cashPaymentNote }}</p>
                    <p class="payment-note" *ngIf="paymentMethod?.id === 'invoice_payment_method_credit_card'"><ion-icon name="information-circle-outline"></ion-icon> {{ 'booking.credit_card_fees_may_apply' | translate }}</p>
                  </ion-label>
                  <ion-radio slot="start" color="secondary" value="{{ paymentMethod.id }}"></ion-radio>
                </ion-item>
              </div>
            </ion-radio-group>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Credit card input -->
    <mbsc-card collapsible #creditCardInputCard [hidden]="selectedPaymentMethod == undefined || selectedPaymentMethod.id !== 'invoice_payment_method_credit_card'" *ngIf="model.provider.stripeUserId != undefined">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="card-outline"></ion-icon> {{ 'app.credit_card_information' | translate }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <app-stripe-payment [stripeAccount]="model.provider.stripeUserId" (onFormSubmit)="onCreditCardInputFormSubmit()" #stripePaymentComponent [hidden]="isConfirmationInProgress"></app-stripe-payment>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Personal information -->
    <mbsc-card collapsible #personalInformationCard
               [hidden]="!this.model.serviceSession.isFreeOfCharge && selectedTicket == undefined && selectedMemberTicket == undefined || this.model.serviceSession.isFreeOfCharge && model.serviceSession.isLivestreamBookingEnabled && isLivestreamBooking == undefined">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="checkmark-circle-outline" *ngIf="isPersonalInformationComplete"></ion-icon>
          {{ personalInformationCardTitle }}</mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <app-personal-information-form [personalInformation]="model.personalInformation"
                                       #personalInformationFormComponent></app-personal-information-form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Health questionnaire -->
    <app-edit-health-questionnaires-card  #editHealthQuestionnairesCardComponent [healthQuestionnaires]="model.healthQuestionnaires" [externalHideCondition]="!this.model.serviceSession.isFreeOfCharge && (selectedTicket == undefined || selectedPaymentMethod == undefined && selectedTicket != undefined && selectedTicket.price > 0 || !isPersonalInformationComplete) || this.model.serviceSession.isFreeOfCharge && model.serviceSession.isLivestreamBookingEnabled && isLivestreamBooking == undefined || this.model.serviceSession.isFreeOfCharge && !isPersonalInformationComplete"></app-edit-health-questionnaires-card>

    <!-- Coupon -->
    <mbsc-card collapsible #couponCard
               [hidden]="!model.isCouponActive || selectedPaymentMethod == undefined || selectedTicket.price === 0">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="help-circle-outline" *ngIf="!activatedCoupon"></ion-icon>
          <ion-icon name="checkmark-circle-outline" *ngIf="activatedCoupon"></ion-icon>
          {{ couponCardTitle }}
        </mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="couponForm" (ngSubmit)="onSubmitCouponForm(couponForm.value)">
          <ion-list class="inputs-list" lines="full">
            <ion-item class="input-item">
              <ion-label color="secondary" position="floating">{{ 'booking.enter_coupon_code' | translate }}</ion-label>
              <ion-input type="text" formControlName="coupon_id" clearInput></ion-input>
            </ion-item>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Book more dates -->
    <mbsc-card collapsible #bookMoreDatesCard
               [hidden]="moreBookableDates == undefined || moreBookableDates.length === 0 || selectedMemberTicket == undefined || model.serviceSession.isFreeOfCharge">
      <mbsc-card-header>
        <mbsc-card-title>
          {{ 'booking.book_more_dates' | translate }}
        </mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <ion-item-divider>
          <ion-label>{{ 'booking.remaining_credits' | translate }}: {{ remainingCredits }}</ion-label>
        </ion-item-divider>
        <form [formGroup]="bookMoreDatesForm">
          <ion-list class="checkbox-list" lines="full">
            <ion-item class="checkbox-item" *ngFor="let serviceSession of moreBookableDates">
              <ion-label class="checkbox-label">
                {{ serviceSession.datetime }}<br>
                <app-service-session-status [serviceSession]="serviceSession"></app-service-session-status>
              </ion-label>
              <ion-checkbox color="secondary" formControlName="{{ serviceSession.date }}" value="{{ serviceSession.date }}"
                            (ionChange)="moreDateChecked($event)"></ion-checkbox>
            </ion-item>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>

    <!-- Message for the team -->
    <mbsc-card collapsible #messageCard
               [hidden]="this.model.serviceSession.recurrence !== 'service_session_recurrence_individual_appointments' || !this.model.serviceSession.isFreeOfCharge && selectedTicket == undefined && selectedMemberTicket == undefined || this.model.serviceSession.isFreeOfCharge && model.serviceSession.isLivestreamBookingEnabled && isLivestreamBooking == undefined">
      <mbsc-card-header>
        <mbsc-card-title>
          <ion-icon name="help-circle-outline"></ion-icon>
          {{ 'booking.message_for_the_team' | translate }}
        </mbsc-card-title>
      </mbsc-card-header>
      <mbsc-card-content>
        <form [formGroup]="messageForm" (ngSubmit)="onSubmitMessageForm()">
          <ion-list class="inputs-list" lines="full">
            <ion-item class="input-item">
              <ion-label color="secondary" position="floating">{{ 'app.optional' | translate }}</ion-label>
              <ion-input type="text" formControlName="message" clearInput></ion-input>
            </ion-item>
          </ion-list>
        </form>
      </mbsc-card-content>
    </mbsc-card>
  </mbsc-accordion>

  <ion-list class="inputs-list" lines="full" [hidden]="selectedTicket == undefined">
    <ion-item>
      <ion-label>{{ 'app.net' | translate }}</ion-label>
      <ion-note slot="end">{{ originallySelectedTicket?.netPrice }}</ion-note>
    </ion-item>
    <ion-item [hidden]="activatedCoupon == undefined">
      <ion-label>{{ 'booking.discount' | translate }}</ion-label>
      <ion-note slot="end">{{ activatedCoupon?.total }}</ion-note>
    </ion-item>
    <ion-item [hidden]="selectedTicket?.vat == undefined">
      <ion-label>{{ 'app.vat' | translate }}</ion-label>
      <ion-note slot="end">{{ selectedTicket?.vat }}</ion-note>
    </ion-item>
    <ion-item [hidden]="selectedTicket?.fees == undefined || selectedPaymentMethod == undefined || selectedPaymentMethod.id !== 'invoice_payment_method_credit_card'">
      <ion-label>{{ 'app.fees' | translate }}</ion-label>
      <ion-note slot="end">{{ selectedTicket?.fees }}</ion-note>
    </ion-item>
    <ion-item [hidden]="selectedPaymentMethod?.id === 'invoice_payment_method_credit_card'">
      <ion-label class="total-label">{{ 'app.total' | translate }}</ion-label>
      <ion-note slot="end" class="total-label">{{ selectedTicket?.total }}</ion-note>
    </ion-item>
    <ion-item [hidden]="selectedPaymentMethod == undefined || selectedPaymentMethod.id !== 'invoice_payment_method_credit_card'">
      <ion-label class="total-label">{{ 'app.total' | translate }}</ion-label>
      <ion-note slot="end" class="total-label">{{ selectedTicket?.totalWithFees }}</ion-note>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-row class="form-actions-wrapper">
    <ion-col>
      <a target="_blank" href="{{model.provider.termsAndConditionsUrl}}" class="terms-and-conditions-link">
        <p class="terms-and-conditions-info">
          <span>{{ 'booking.accept_terms' | translate: {name: model.provider.name} }}</span>
          <ion-icon name="open-outline"></ion-icon>
        </p>
      </a>

      <ion-button class="submit-btn" expand="block" color="secondary" type="submit" fill="solid" (click)="validateBooking()">{{ 'booking.book_now' | translate }}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
