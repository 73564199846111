import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { Router } from '@angular/router';
import { ServiceSessionModel } from '../../models/service-session.model';
import { BookingModel } from '../../models/booking.model';
import { ProfileService } from '../../profile/profile.service';
import { EventsService } from '../../utils/events.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-already-booked-popup',
  templateUrl: './already-booked-popup.component.html',
  styleUrls: ['./already-booked-popup.component.scss'],
})
export class AlreadyBookedPopupComponent implements OnInit {
  // @ts-ignore
  @ViewChild('alreadyBookedPopup')
  alreadyBookedPopup: any;

  // @ts-ignore
  @ViewChild('successPopupComponent')
  successPopupComponent: any;

  // @ts-ignore
  @ViewChild('warningPopupComponent')
  warningPopupComponent: any;

  serviceSession: ServiceSessionModel;

  popupSettings: MbscPopupOptions = {
    display: 'center',
    closeOnOverlayTap: false,
    onSet: (event, inst) => {
      this.resolve();
    }
  };

  resolve: any;

  constructor(
    private router: Router,
    public profileService: ProfileService,
    private eventsService: EventsService,
    private translate: TranslateService) { }

  ngOnInit() {}

  show(serviceSession: ServiceSessionModel) {
    this.serviceSession = serviceSession;
    this.alreadyBookedPopup.instance.show();
    const that = this;
    return new Promise(function (resolve) {
      that.resolve = resolve;
    });
  }

  openMyBookings() {
    this.alreadyBookedPopup.instance.hide();
    this.router.navigate(['/my-bookings']);
  }

  async cancelBooking() {
    this.alreadyBookedPopup.instance.hide();

    const booking = new BookingModel();
    booking.id = this.serviceSession.bookingId;
    const that = this;
    this.profileService.presentCancelBookingAlert(booking).then((status) => {
      switch (status) {
        case 'ok':
          that.successPopupComponent.show(that.translate.instant('app.alright'), that.translate.instant('booking.cancel_booking_successful')).then(() => {
            that.eventsService.publish('bookingsChanged', {});
            that.resolve();
          });
          break;

        case 'deadline_over':
          that.warningPopupComponent.show(that.translate.instant('booking.cancel_not_possible'), that.translate.instant('booking.cancellation_deadline_passed'));
          break;
      }
    });
  }
}
