import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ProfileModel } from './profile.model';
import { DataStore } from '../shell/data-store';
import { MyBookingsModel } from './my-bookings/my-bookings.model';
import { MyPassesModel } from './my-passes/my-passes.model';
import { MyVideosModel } from './my-videos/my-videos.model';
import { MyFilesModel } from './my-files/my-files.model';
import { NotificationsModel } from './notifications/notifications.model';
import { PersonalInformationModel } from '../components/personal-information-form/personal-information.model';
import { ProfilePersonalInformationModel } from './personal-information/personal-information.model';
import { MyCheckInsModel } from './my-check-ins/my-check-ins.model';
import { MyWatchListModel } from './my-watch-list/my-watch-list.model';
import { BookingModel } from '../models/booking.model';
import * as dayjs from 'dayjs';
import { Calendar, CalendarOptions } from '@ionic-native/calendar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { MyWaitingListModel } from './my-waiting-list/my-waiting-list.model';
import { environment, SERVER_URL } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { ServiceSessionModel } from '../models/service-session.model';
import { MyFamilyModel } from './my-family/my-family.model';
import { CustomerModel } from '../models/customer.model';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Storage } from '@ionic/storage';

@Injectable()
export class ProfileService {
  private profileDataStore: DataStore<ProfileModel>;
  private myBookingsDataStore: DataStore<MyBookingsModel>;
  private myWaitingListDataStore: DataStore<MyWaitingListModel>;
  private myPassesDataStore: DataStore<MyPassesModel>;
  private myVideosDataStore: DataStore<MyVideosModel>;
  private myCheckInsDataStore: DataStore<MyCheckInsModel>;
  private myFilesDataStore: DataStore<MyFilesModel>;
  private myWatchListDataStore: DataStore<MyWatchListModel>;
  private notificationsDataStore: DataStore<NotificationsModel>;
  private personalInformationDataStore: DataStore<ProfilePersonalInformationModel>;
  private myFamilyDataStore: DataStore<MyFamilyModel>;

  constructor(
    private http: HttpClient,
    private calendar: Calendar,
    private alertController: AlertController,
    private translate: TranslateService,
    private platform: Platform,
    private loadingController: LoadingController,
    private diagnostic: Diagnostic,
    private storage: Storage
  ) { }

  // My bookings
  public getMyUpcomingBookingsDataSource(page: number): Observable<MyBookingsModel> {
    if (environment.useSampleData) {
      // return this.http.get<MyBookingsModel>('./assets/sample-data/profile/my-bookings-empty.json');
      return this.http.get<MyBookingsModel>('./assets/sample-data/profile/my-upcoming-bookings.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyBookingsModel>(SERVER_URL + '/v1/profile/upcoming_bookings', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyPastBookingsDataSource(page: number): Observable<MyBookingsModel> {
    if (environment.useSampleData) {
      // return this.http.get<MyBookingsModel>('./assets/sample-data/profile/my-bookings-empty.json');
      return this.http.get<MyBookingsModel>('./assets/sample-data/profile/my-past-bookings.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyBookingsModel>(SERVER_URL + '/v1/profile/past_bookings', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyBookingsStore(dataSource: Observable<MyBookingsModel>): DataStore<MyBookingsModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyBookingsModel = new MyBookingsModel();
    this.myBookingsDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.myBookingsDataStore.load(dataSource);
    return this.myBookingsDataStore;
  }

  async presentCancelBookingAlert(booking: BookingModel) {
    const that = this;
    return new Promise(async function (resolve, reject) {
      const alert = await that.alertController.create({
        header: that.translate.instant('app.cancel'),
        message: that.translate.instant('booking.confirm_cancel'),
        buttons: [
          {
            text: that.translate.instant('app.no'),
            role: 'cancel'
          },
          {
            text: that.translate.instant('app.yes'),
            handler: async () => {
              const loading = that.loadingController.create({
                cssClass: 'custom-loading',
                spinner: 'crescent'
              });
              (await loading).present();

              that.cancelBooking(booking.id).subscribe({
                async next(response) {
                  (await loading).dismiss();

                  // Automatically delete booking from the calendar.
                  that.storage.get('defaultCalendar').then((calendar) => {
                    if (calendar != undefined) {
                      that.removeBookingFromCalendar(booking);
                    }
                  });
                  resolve(response.status);
                },
                async error(error) {
                  (await loading).dismiss();
                  reject(error);
                }
              });
            }
          }
        ]
      });
      await alert.present();
    });
  }
  async presentRebookToLivestreamOnSiteAlert(booking: BookingModel) {
    const that = this;
    return new Promise(async function (resolve, reject) {
      const alert = await that.alertController.create({
        header: booking.isLivestreamBooking ? that.translate.instant('participants_lists.rebook_to_on_site') : that.translate.instant('participants_lists.rebook_to_livestream'),
        message: booking.isLivestreamBooking ? that.translate.instant('booking.confirm_rebook_to_on_site') : that.translate.instant('booking.confirm_rebook_to_livestream'),
        buttons: [
          {
            text: that.translate.instant('app.no'),
            role: 'cancel'
          },
          {
            text: that.translate.instant('app.yes'),
            handler: async () => {
              const loading = that.loadingController.create({
                cssClass: 'custom-loading',
                spinner: 'crescent'
              });
              (await loading).present();

              that.rebookToLivestreamOnSite(booking.id).subscribe({
                async next(response) {
                  (await loading).dismiss();
                  resolve(response.status);
                },
                async error(error) {
                  (await loading).dismiss();
                  reject(error);
                }
              });
            }
          }
        ]
      });
      await alert.present();
    });
  }

  public async presentRemoveFromWaitingListAlert(bookingId) {
    const that = this;
    return new Promise(async function (resolve, reject) {

      const alert = await that.alertController.create({
        header: that.translate.instant('booking.remove_from_waiting_list'),
        message: that.translate.instant('booking.confirm_remove_from_waiting_list'),
        buttons: [
          {
            text: that.translate.instant('app.no'),
            role: 'cancel'
          },
          {
            text: that.translate.instant('app.yes'),
            handler: async () => {
              const loading = that.loadingController.create({
                cssClass: 'custom-loading',
                spinner: 'crescent'
              });
              (await loading).present();

              that.removeFromWaitingList(bookingId).subscribe({
                async next(response){
                  (await loading).dismiss();
                  resolve(response)
                },
                async error(error) {
                  (await loading).dismiss();
                  reject(error);
                }
              });
            }
          }
        ]
      });
      await alert.present();
    });
  }

  public cancelBooking(bookingId: number): Observable<{ status: string }> {
    if (environment.useSampleData) {
      // return this.http.get<{ status: string }>('./assets/sample-data/profile/cancel-booking-deadline-over.json');
      return this.http.get<{ status: string }>('./assets/sample-data/profile/cancel-booking-successful.json');
    }

    return this.http.delete<{ status: string }>(SERVER_URL + '/v1/profile/bookings/' + bookingId).pipe(
      catchError(HttpService.handleError)
    );
  }
  public rebookToLivestreamOnSite(bookingId: number): Observable<{ status: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/ok.json');
    }

    return this.http.post<{ status: string }>(SERVER_URL + '/v1/profile/bookings/' + bookingId + '/rebook_to_livestream_on_site', {}).pipe(
      catchError(HttpService.handleError)
    );
  }

  public addBookingToCalendar(booking: BookingModel, interactive: boolean = true, calendar = undefined, isAppointment: boolean = false) {
    console.log('addBookingToCalendar', booking);
    // Workaround because requestWritePermission() not always returns on Android.
    if (this.platform.is('android')) {
      this.createBookingEventInCalendar(booking, interactive, calendar, isAppointment);
    }
    else {
      this.calendar.requestWritePermission().then(r => {
        this.createBookingEventInCalendar(booking, interactive, calendar, isAppointment);
      }).catch(async err => {
        console.log(err);
        await this.presentAccessToCalendarAlert();
      });
    }
  }

  // Helper method
  private createBookingEventInCalendar(booking: BookingModel, interactive, calendar, isAppointment) {
    let customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)
    // @ts-ignore
    let dateTimeBegin = dayjs(booking.dateTimeBegin, 'YYYY-MM-DD HH:mm').toDate();
    // @ts-ignore
    let dateTimeEnd = dayjs(booking.dateTimeEnd, 'YYYY-MM-DD HH:mm').toDate();
    let title = booking.serviceSession.name;
    if (isAppointment) {
      title += ": " + booking.customer.name;
    }

    if (interactive) {
      this.calendar.createEventInteractively(title, booking.address, '', dateTimeBegin, dateTimeEnd).then();
    }
    else {
      let calendarOptions = this.calendar.getCalendarOptions();
      calendarOptions.calendarId = calendar.id;
      calendarOptions.calendarName = calendar.name;

      this.calendar.createEventWithOptions(title, booking.address, '', dateTimeBegin, dateTimeEnd, calendarOptions).then(
        (eventId: string) => {
          console.log('event added successfully to calendar', eventId, booking);

          // Store the booking with the calendar event ID, so the event ID can be retrieved and used to remove the calendar entry if the booking gets cancelled.
          booking.calendarEventId = eventId;
          this.storage.set(booking.id.toString(), booking);
        },
        async (err) => {
          console.log(err);
        }
      );
    }
  }

  // Deletes the booking event from the calendar and updates the list of synchronized bookings.
  public removeBookingFromCalendar(booking: BookingModel) {
    console.log('removeBookingFromCalendar', booking);
    this.storage.get(booking.id.toString()).then((calendarBooking) => {
      console.log('calendarBooking loaded from storage', calendarBooking);
      if (calendarBooking != undefined) {
        this.calendar.deleteEventById(calendarBooking.calendarEventId).then(
          (msg) => {
            console.log(msg);
          },
          async (err) => {
            console.log(err);
          }
        );
        this.storage.remove(booking.id.toString());
      }
    }).catch(async err => {
      console.log(err);
    });
  }

  public async presentAccessToCalendarAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('profile.access_to_calendar_required'),
      message: this.translate.instant('profile.grant_access_to_calendar'),
      buttons: [
        {
          text: this.translate.instant('app.abort'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('app.open_settings'),
          role: 'destructive',
          cssClass: 'confirm-cancel-button',
          handler: () => {
            this.diagnostic.switchToSettings();
          }
        }
      ]
    });
    await alert.present();
  }

  // My passes
  public getMyCurrentPassesDataSource(page: number): Observable<MyPassesModel> {
    if (environment.useSampleData) {
      return this.http.get<MyPassesModel>('./assets/sample-data/profile/my-current-passes-empty.json');
      // return this.http.get<MyPassesModel>('./assets/sample-data/profile/my-current-passes.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyPassesModel>(SERVER_URL + '/v1/profile/current_member_tickets', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyPastPassesDataSource(page: number): Observable<MyPassesModel> {
    if (environment.useSampleData) {
      return this.http.get<MyPassesModel>('./assets/sample-data/profile/my-current-passes-empty.json');
      // return this.http.get<MyPassesModel>('./assets/sample-data/profile/my-past-passes.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyPassesModel>(SERVER_URL + '/v1/profile/past_member_tickets', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyPassesStore(dataSource: Observable<MyPassesModel>): DataStore<MyPassesModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyPassesModel = new MyPassesModel();
    this.myPassesDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.myPassesDataStore.load(dataSource);
    return this.myPassesDataStore;
  }

  public cancelMemberTicketRenewal(memberTicketId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/member_tickets/' + memberTicketId + '/cancel_renewal', {}).pipe(
      catchError(HttpService.handleError)
    );
  }


  // My check ins
  public getMyCheckInsDataSource(page: number): Observable<MyCheckInsModel> {
    if (environment.useSampleData) {
      return this.http.get<MyCheckInsModel>('./assets/sample-data/profile/my-check-ins.json');
      // return this.http.get<MyCheckInsModel>('./assets/sample-data/profile/my-check-ins-empty.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyCheckInsModel>(SERVER_URL + '/v1/profile/check_ins', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyCheckInsDataStore(dataSource: Observable<MyCheckInsModel>): DataStore<MyCheckInsModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyCheckInsModel = new MyCheckInsModel();
    this.myCheckInsDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.myCheckInsDataStore.load(dataSource);
    return this.myCheckInsDataStore;
  }


  // My videos
  public getMyVideosDataSource(page: number, searchQuery: string): Observable<MyVideosModel> {
    if (environment.useSampleData) {
      return this.http.get<MyVideosModel>('./assets/sample-data/profile/my-videos.json');
      // return this.http.get<MyVideosModel>('./assets/sample-data/profile/my-videos-empty.json');
    }

    let params = new HttpParams()
      .set('query', String(searchQuery))
      .set('page', String(page));

    return this.http.get<MyVideosModel>(SERVER_URL + '/v1/profile/videos', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyVideosStore(dataSource: Observable<MyVideosModel>): DataStore<MyVideosModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyVideosModel = new MyVideosModel();
    this.myVideosDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.myVideosDataStore.load(dataSource);
    return this.myVideosDataStore;
  }


  // My files
  public getMyFilesDataSource(page: number, searchQuery: string): Observable<MyFilesModel> {
    if (environment.useSampleData) {
      return this.http.get<MyFilesModel>('./assets/sample-data/profile/my-files.json');
      // return this.http.get<MyFilesModel>('./assets/sample-data/profile/my-files-empty.json');
    }

    let params = new HttpParams()
      .set('query', String(searchQuery))
      .set('page', String(page));

    return this.http.get<MyFilesModel>(SERVER_URL + '/v1/profile/files', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyFilesStore(dataSource: Observable<MyFilesModel>): DataStore<MyFilesModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyFilesModel = new MyFilesModel();
    this.myFilesDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.myFilesDataStore.load(dataSource);
    return this.myFilesDataStore;
  }


  // My watch list
  public getMyWatchListDataSource(page: number): Observable<MyWatchListModel> {
    if (environment.useSampleData) {
      return this.http.get<MyWatchListModel>('./assets/sample-data/profile/my-watch-list.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyWatchListModel>(SERVER_URL + '/v1/profile/watch_list', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyWatchListDataStore(dataSource: Observable<MyWatchListModel>): DataStore<MyWatchListModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyWatchListModel = new MyWatchListModel();
    this.myWatchListDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.myWatchListDataStore.load(dataSource);
    return this.myWatchListDataStore;
  }

  public removeServiceSessionFromWatchList(serviceSession: ServiceSessionModel): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }
    else {
      const body = {}
      body['serviceSessionId'] = serviceSession.id;
      body['date'] = serviceSession.date;
      body['timeBegin'] = serviceSession.timeBegin;

      return this.http.post<{}>(SERVER_URL + '/v1/bookings/remove_from_watch_list', body).pipe(
        catchError(HttpService.handleError)
      );
    }
  }


  // My waiting list
  public getMyWaitingListDataSource(page: number): Observable<MyWaitingListModel> {
    if (environment.useSampleData) {
      return this.http.get<MyWaitingListModel>('./assets/sample-data/profile/my-waiting-list.json');
    }

    let params = new HttpParams()
      .set('page', String(page));

    return this.http.get<MyWaitingListModel>(SERVER_URL + '/v1/profile/waiting_list', { params: params }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyWaitingListStore(dataSource: Observable<MyWaitingListModel>): DataStore<MyWaitingListModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: MyWaitingListModel = new MyWaitingListModel();
    this.myWaitingListDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.myWaitingListDataStore.load(dataSource);
    return this.myWaitingListDataStore;
  }

  public removeFromWaitingList(bookingId: number): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{ status: string }>(SERVER_URL + '/v1/profile/waiting_list/' + bookingId).pipe(
      catchError(HttpService.handleError)
    );
  }


  // Notifications
  public getNotificationsDataSource(): Observable<NotificationsModel> {
    if (environment.useSampleData) {
      return this.http.get<NotificationsModel>('./assets/sample-data/profile/notification-settings.json');
    }

    return this.http.get<NotificationsModel>(SERVER_URL + '/v1/profile/notification_settings').pipe(
      catchError(HttpService.handleError)
    );
  }

  public updateNotificationSetting(key, value): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<NotificationsModel>('./assets/sample-data/profile/update-notification-setting.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/update_notification_setting', { key: key, value: value }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getNotificationsStore(dataSource: Observable<NotificationsModel>): DataStore<NotificationsModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: NotificationsModel = new NotificationsModel();
    this.notificationsDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.notificationsDataStore.load(dataSource);
    return this.notificationsDataStore;
  }


  // Personal Information
  public getPersonalInformationDataSource(): Observable<ProfilePersonalInformationModel> {
    if (environment.useSampleData) {
      return this.http.get<ProfilePersonalInformationModel>('./assets/sample-data/profile/personal-information.json');
    }

    return this.http.get<ProfilePersonalInformationModel>(SERVER_URL + '/v1/profile/personal_information').pipe(
      catchError(HttpService.handleError)
    );
  }

  public savePersonalInformation(personalInformation: PersonalInformationModel): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<any>('./assets/sample-data/profile/save-personal-information.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/save_personal_information', { personalInformation: personalInformation }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getPersonalInformationStore(dataSource: Observable<ProfilePersonalInformationModel>): DataStore<ProfilePersonalInformationModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: ProfilePersonalInformationModel = new ProfilePersonalInformationModel();
    this.personalInformationDataStore = new DataStore(shellModel);
    // Trigger the loading mechanism (with shell) in the dataStore
    this.personalInformationDataStore.load(dataSource);
    return this.personalInformationDataStore;
  }


  // Profile
  public getProfileDataSource(): Observable<ProfileModel> {
    if (environment.useSampleData) {
      return this.http.get<ProfileModel>('./assets/sample-data/profile/profile.json');
    }

    return this.http.get<ProfileModel>(SERVER_URL + '/v1/profile').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getProfileStore(dataSource: Observable<ProfileModel>): DataStore<ProfileModel> {
    // Use cache if available
    if (!this.profileDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: ProfileModel = new ProfileModel();
      this.profileDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.profileDataStore.load(dataSource);
    }
    return this.profileDataStore;
  }

  public getMyFamilyDataSource(): Observable<MyFamilyModel> {
    if (environment.useSampleData) {
      return this.http.get<MyFamilyModel>('./assets/sample-data/profile/my-family.json');
    }

    return this.http.get<MyFamilyModel>(SERVER_URL + '/v1/profile/family_members').pipe(
      catchError(HttpService.handleError)
    );
  }

  public getMyFamilyStore(dataSource: Observable<MyFamilyModel>): DataStore<MyFamilyModel> {
    // Use cache if available
    if (!this.myFamilyDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: MyFamilyModel = new MyFamilyModel();
      this.myFamilyDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.myFamilyDataStore.load(dataSource);
    }
    return this.myFamilyDataStore;
  }

  public deleteFamilyMember(member: CustomerModel): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{}>(SERVER_URL + '/v1/profile/family_members/' + member.memberId).pipe(
      catchError(HttpService.handleError)
    );
  }

  public restoreFamilyMember(member: CustomerModel): Observable<{ status: string; message: string; }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, message: string }>('./assets/sample-data/ok.json');
    }

    return this.http.post<{ status: string, message: string }>(SERVER_URL + '/v1/profile/family_members/' + member.memberId + '/restore', {}).pipe(
      catchError(HttpService.handleError)
    );
  }

  public createFamilyMember(gender: string, firstName: string, lastName: string): Observable<{ status: string; message: string; }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, message: string }>('./assets/sample-data/ok.json');
      // return this.http.get<{ status: string, message: string }>('./assets/sample-data/profile/create-family-member-not-ok.json');
    }

    return this.http.post<{ status: string, message: string }>(SERVER_URL + '/v1/profile/family_members', {
      gender: gender,
      firstName: firstName,
      lastName: lastName
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public createFamilyMemberLogin(memberId: number, email: string): Observable<{ status: string; message: string; }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, message: string }>('./assets/sample-data/ok.json');
    }

    return this.http.post<{ status: string, message: string }>(SERVER_URL + '/v1/profile/family_members/' + memberId + '/create_login', {
      memberId: memberId,
      email: email
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public deleteProfilePicture(): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{}>(SERVER_URL + '/v1/profile/delete_profile_picture').pipe(
      catchError(HttpService.handleError)
    );
  }

  public saveProfilePicture(profilePicture: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/save_profile_picture', { profilePicture: profilePicture }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public changeEmail(email: string): Observable<{ status: string, message: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, message: string }>('./assets/sample-data/empty.json');
    }

    return this.http.post<{ status: string, message: string }>(SERVER_URL + '/v1/profile/change_email', { email: email }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public getEmailAddress(): Observable<{ email: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ email: string }>('./assets/sample-data/profile/email-address.json');
    }

    return this.http.get<{ email: string }>(SERVER_URL + '/v1/profile/get_email_address').pipe(
      catchError(HttpService.handleError)
    );
  }

  public changePassword(email: string, currentPassword: string, newPassword: string): Observable<{ status: string, message: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string, message: string }>('./assets/sample-data/profile/change-password-ok.json');
      // return this.http.get<{ status: string, message: string }>('./assets/sample-data/profile/change-password-invalid-password.json');
    }

    return this.http.post<{ status: string, message: string }>(SERVER_URL + '/v1/profile/change_password', {
      email: email,
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: newPassword
    }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public changeLanguage(language: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/change_language', { locale: language }).pipe(
      catchError(HttpService.handleError)
    );
  }

  public stopRateUs(): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/stop_rate_us', {});
  }

  public acceptRateUs(): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/accept_rate_us', {});
  }

  public rateUsLater(): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/rate_us_later', {});
  }

  public sendAppFeedback(feedback: string): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.post<{}>(SERVER_URL + '/v1/profile/app_feedback', {
      feedback: feedback,
      platform: this.platform.is('ios') ? 'iOS' : 'Android',
      version: environment.version
    });
  }

  public deleteAccount(): Observable<{}> {
    if (environment.useSampleData) {
      return this.http.get<{}>('./assets/sample-data/empty.json');
    }

    return this.http.delete<{ status: string }>(SERVER_URL + '/v1/profile/delete_account/').pipe(
      catchError(HttpService.handleError)
    );
  }

  public promptForAppReview(): Observable<{ status: string }> {
    if (environment.useSampleData) {
      return this.http.get<{ status: string }>('./assets/sample-data/empty.json');
    }

    return this.http.get<{ status: string }>(SERVER_URL + '/v1/profile/prompt_for_app_review', {}).pipe(
      catchError(HttpService.handleError)
    );
  }
}
