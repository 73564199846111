import { ShellModel } from '../shell/data-store';
import { BookingModel } from '../models/booking.model';
import { ServiceSessionModel } from '../models/service-session.model';
import { NewsModel } from '../models/news.model';
import { MessageModel } from '../models/message.model';
import { MemberTicketModel } from '../models/member-ticket.model';
import { ServiceSessionRatingModel } from '../models/service-session-rating.model';
import { CheckInModel } from '../models/check-in.model';
import { ProviderModel } from '../models/provider.model';

export class HomeModel extends ShellModel {
  memberId: number;
  notificationsCount: number;
  isAdminModeEnabled: boolean;
  isRateUsEnabled: boolean;
  rateUsSalutation: string;
  hasProviders: boolean;
  providers: Array<ProviderModel>;
  unpaidMemberTicket: MemberTicketModel;
  news: Array<NewsModel>;
  messages: Array<MessageModel>;
  upcomingBookings: Array<BookingModel>;
  freeSpots: Array<ServiceSessionModel>;
  nextCourses: Array<ServiceSessionModel>;
  bookAgain: Array<ServiceSessionModel>;
  watchList: Array<ServiceSessionModel>;
  deputyServiceSessions: Array<ServiceSessionModel>;
  notEnoughParticipantsServiceSessions: Array<ServiceSessionModel>;
  bookingActivities: Array<BookingActivity>;
  serviceSessionRating: ServiceSessionRatingModel;
  activeCheckIn: CheckInModel;

  constructor() {
    super();
  }
}

export class BookingActivity {
  date: string;
  bookings: Array<BookingModel>;
}
