import { ShellModel } from '../../shell/data-store';
import { TicketModel } from '../../models/ticket.model';
import { ProviderModel } from '../../models/provider.model';

export class PricingModel extends ShellModel {
  provider: ProviderModel;
  categories: Array<{name: string; tickets: Array<TicketModel>}>

  constructor() {
    super();
  }
}
