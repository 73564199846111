import { ShellModel } from '../../shell/data-store';
import { CheckInModel } from '../../models/check-in.model';

export class MyCheckInsModel extends ShellModel {
  checkIns: Array<CheckInModel> = [];

  constructor() {
    super();
  }
}
