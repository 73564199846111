import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';

@Component({
  selector: 'app-invalid-input-popup',
  templateUrl: './invalid-input-popup.component.html',
  styleUrls: ['./invalid-input-popup.component.scss'],
})
export class InvalidInputPopupComponent implements OnInit {

  settings: MbscPopupOptions = {
    display: 'center'
  };

  // @ts-ignore
  @ViewChild('popup')
  popup: any;

  constructor() { }

  ngOnInit() {}

  show() {
    this.popup.instance.show();
  }

}
