import { Component, OnInit, ViewChild } from '@angular/core';
import { MbscPopupOptions } from '@mobiscroll/angular';
import { Router } from '@angular/router';
import { ServiceSessionModel } from '../../models/service-session.model';
import { ProfileService } from '../../profile/profile.service';
import { EventsService } from '../../utils/events.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from "@ionic/angular";

@Component({
    selector: 'app-on-the-waiting-list-popup',
    templateUrl: './on-the-waiting-list-popup.component.html',
    styleUrls: ['./on-the-waiting-list-popup.component.scss'],
})
export class OnTheWaitingListPopupComponent implements OnInit {
    // @ts-ignore
    @ViewChild('onTheWaitingListPopup')
    onTheWaitingListPopup: any;

    // @ts-ignore
    @ViewChild('successPopupComponent')
    successPopupComponent: any;

    serviceSession: ServiceSessionModel;

    popupSettings: MbscPopupOptions = {
        display: 'center',
        closeOnOverlayTap: false,
        onSet: (event, inst) => {
            this.resolve();
        }
    };

    resolve: any;

    constructor(
        private router: Router,
        private profileService: ProfileService,
        private eventsService: EventsService,
        private translate: TranslateService,
        private loadingController: LoadingController
    ) { }


    ngOnInit() {}

    show(serviceSession: ServiceSessionModel) {
        this.serviceSession = serviceSession;
        this.onTheWaitingListPopup.instance.show();
        const that = this;
        return new Promise(function (resolve) {
            that.resolve = resolve;
        });
    }

    openMyWaitingList() {
        this.onTheWaitingListPopup.instance.hide();
        this.router.navigate(['/my-waiting-list']);
    }

    async removeFromWaitingList(bookingId) {
        this.onTheWaitingListPopup.instance.hide();
        const that = this;
        this.profileService.presentRemoveFromWaitingListAlert(bookingId).then(() => {
            that.successPopupComponent.show(that.translate.instant('app.alright'), that.translate.instant('booking.remove_from_waiting_list_successful')).then(() => {
                that.resolve();
            });
        });
        this.onTheWaitingListPopup.instance.hide();
    }

}
