<ng-container
  *ngIf="(serviceSession.isClosed || serviceSession.isNotOpenYet) && !serviceSession.isBookingLimitReached && serviceSession.isBookable">
  <ion-badge color="secondary">{{ 'schedule.booking_closed' | translate }}</ion-badge>
</ng-container>
<ng-container
  *ngIf="serviceSession.isBookingLimitReached && serviceSession.isBookable && !serviceSession.isClosed && !serviceSession.isNotOpenYet && !serviceSession.isFullyBooked && !serviceSession.isCancelled && !serviceSession.isAlreadyBooked && !serviceSession.isAlreadyOnWaitingList">
  <ion-badge color="secondary">{{ 'schedule.booking_limit_reached' | translate }}</ion-badge>
</ng-container>
<ng-container *ngIf="!serviceSession.isClosed && !serviceSession.isNotOpenYet && serviceSession.isBookable">
  <ion-badge color="warning"
             *ngIf="serviceSession.isAlmostFullyBooked">{{ 'schedule.almost_booked_out' | translate }}</ion-badge>
  <ion-badge color="secondary"
             *ngIf="serviceSession.isFullyBooked && !serviceSession.isAlreadyOnWaitingList && !serviceSession.isCancelled && !serviceSession.isAlreadyBooked">
    <span *ngIf="serviceSession.isLivestreamBookingEnabled">{{ 'booking.on_site_fully_booked' | translate }}</span>
    <span *ngIf="!serviceSession.isLivestreamBookingEnabled">{{ 'booking.fully_booked' | translate }}</span>
  </ion-badge>
  <ion-badge color="secondary" *ngIf="serviceSession.isCancelled">{{ 'schedule.cancelled' | translate }}</ion-badge>
  <ion-badge color="success"
             *ngIf="serviceSession.isAlreadyBooked">{{ 'schedule.already_booked' | translate }}</ion-badge>
  <ion-badge color="tertiary"
             *ngIf="serviceSession.isAlreadyOnWaitingList">{{ 'booking.on_the_waiting_list' | translate }}</ion-badge>
</ng-container>
